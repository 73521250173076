<template>
<div>
    <div class="px-4 text-center">
        <h1 class="text-center mt-5 p-1">{{ $t('contactPage.header[0]') }}
            <h2 class="text-center" style="text-decoration: underline solid var(--zerduz-text-color) 10px; text-decoration-skip-ink: none; text-underline-offset: -0.25rem;">{{ $t('contactPage.header[1]') }}</h2>

        </h1>
    </div>
    <div class="container mb-4 mt-5 d-grid col-md-10 col-lg-6 col-sm-8 mx-auto">
        <div class="row contact-form gx-4">
            <div class="col-md-6">
                <form @submit.prevent="sendMessage" class="row g-3">
                    <div class="col-md-12 col-lg-6">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control shadow-sm" v-model="formData.first_name" id="name" :placeholder="$t('contactPage.form.name')" required>
                            <label for="name">{{ $t('contactPage.form.name') }}</label>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control shadow-sm" v-model="formData.last_name" id="surname" :placeholder="$t('contactPage.form.surname')" required>
                            <label for="surname">{{ $t('contactPage.form.surname') }}</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-floating mb-3">
                            <input type="email" class="form-control shadow-sm" v-model="formData.email" id="email" :placeholder="$t('contactPage.form.email')" required>
                            <label for="email">{{ $t('contactPage.form.email') }}</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-floating">
                            <textarea class="form-control shadow-sm" v-model="formData.message" :placeholder="$t('contactPage.form.message')" id="message" style="height: 100px" required></textarea>
                            <label for="message">{{ $t('contactPage.form.message') }}</label>
                        </div>
                    </div>
                    <div class="col-12 text-center mt-4">
                        <button type="submit" class="btn btn-primary btn-lg rounded-pill shadow-sm">
                            <span class="contact-button-text">{{ $t('contactPage.form.btn') }}</span>
                            <div class="btn-loading">
                                <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                <span class="visually-hidden" role="status">{{ $t('loadingText') }}</span>
                            </div>
                        </button>
                    </div>
                </form>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="card mb-3 p-3 shadow-sm border-0" style="max-width: 540px;">
                    <a href="tel:903406666">
                        <div class="row g-0">
                            <div class="col-md-4 text-center align-items-center d-grid text-zerduz contact-icon-phone">
                                <i class="fa-solid fa-phone"></i>
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <p class="card-text text-body">0850 340 6666</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>

                <div class="card mb-3 p-3 shadow-sm border-0" style="max-width: 540px;">
                    <a href="https://maps.app.goo.gl/fSyKmY3N6XEkNMUm8" target="_blank">
                        <div class="row g-0">
                            <div class="col-md-4 text-center align-items-center d-grid text-zerduz contact-icon-location">
                                <i class="fa-solid fa-location-dot"></i>
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <p class="card-text text-body">Yenibosna Merkez Mahallesi Ladin Sokak No:36/11
                                        Bahçelievler/İstanbul</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>

                <div class="card mb-3 p-3 shadow-sm border-0" style="max-width: 540px;">
                    <a href="mailto:info@zerduz.com">
                        <div class="row g-0">
                            <div class="col-md-4 text-center align-items-center d-grid text-zerduz contact-icon-envelope">
                                <i class="fa-solid fa-envelope"></i>
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <p class="card-text text-body">info@zerduz.com</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>

            </div>
        </div>
    </div>

    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12043.234802038784!2d28.8168346!3d41.0075601!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa5c97854a3d3%3A0xd7f6e1e56c3ab6c9!2sZerduZ!5e0!3m2!1str!2str!4v1705916764797!5m2!1str!2str" width="100%" class="shadow-lg my-5" height="600" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>
</template>

<script>
import $ from "jquery";
import {
    sendContactForm
} from "@/helpers/api";

export default {
    name: "ContactPage",
    data() {
        return {
            token: window.localStorage.getItem("token"),
            userId: window.localStorage.getItem("userId"),
            formData: {
                first_name: "",
                last_name: "",
                email: "",
                message: ""
            }
        };
    },
    methods: {
        async sendMessage() {
            $('.contact-button-text').fadeOut(async () => {
                $('.btn-loading').fadeIn();
                try {
                    const {
                        success
                    } = await sendContactForm(
                        this.formData.first_name,
                        this.formData.last_name,
                        this.formData.email,
                        this.formData.message
                    );
                    if (success) {
                        $('.btn-loading').fadeOut(function () {
                            $('.contact-button-text').fadeIn();
                        });
                        this.$showToast("Zerduz", this.$t("errors.api.sendContactForm.success"), 5000);
                    } else {
                        $('.btn-loading').fadeOut(function () {
                            $('.contact-button-text').fadeIn();
                        });
                        this.$showToast("Zerduz", this.$t("errors.api.sendContactForm.error"), 5000);
                    }
                } catch (error) {
                    $('.btn-loading').fadeOut(function () {
                        $('.contact-button-text').fadeIn();
                    });
                    this.$showToast("Zerduz", this.$t("errors.api.sendContactForm.error"), 5000);
                }
            });
        }
    }
};
</script>

<style scoped src="../assets/css/contact.css"/>

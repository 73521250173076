<template>
    <div>
        <div class="px-4 my-5 text-center">
            <h1 class="text-center mt-5 p-1" id="page-header-text">{{ $t('accountPages.header') }}</h1>
        </div>

        <div class="container py-3">
            <div class="row">
                <accountPageSidemenu />
                <div class="col-lg-9 col-md-8 col-sm-10" id="addresses-tab">
                    <div class="p-2">
                        <h3 class="fw-normal mt-sm-2 mt-md-0 mt-lg-0 mt-2"><router-link to="/Account/Addresses"
                                class="link-dark link-offset-2 link-underline-opacity-0 link-underline-opacity-100-hover">{{ $t('accountPages.address.add.header[0]') }}</router-link>
                            <i class="fa fa-chevron-right fa-2xs ms-2" style="font-size:14px;"></i> <span accordion-header
                                class="text-zerduz">{{ $t('accountPages.address.add.header[1]') }}</span>
                        </h3>
                    </div>
                    <form @submit.prevent="saveAddress">
                        <div class="row g-2 p-2">
                            <div class="col-lg-4 col-md-12 col-sm-12">
                                <div class="form-floating">
                                    <input type="text" class="form-control shadow-sm" v-model="addressData.address_name" :placeholder="$t('accountPages.address.add.form.addressName')" id="newAddressName">
                                    <label for="newAddressName">{{ $t('accountPages.address.add.form.addressName') }}</label>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">   
                                <div class="form-floating">
                                    <select class="form-select" id="newAddressType" v-model="addressData.type">
                                        <option value="shipping">{{ $t('accountPages.address.add.form.addressType[0]') }}</option>
                                        <option value="billing">{{ $t('accountPages.address.add.form.addressType[1]') }}</option>
                                    </select>
                                    <label for="newAddressType">{{ $t('accountPages.address.add.form.addressType[2]') }}</label>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="form-floating">
                                    <select v-model="addressData.selectedCountry" @change="countryInputChange"
                                        class="form-select shadow-sm" id="newAddressCountry">
                                        <option v-for="country in countries" :key="country.alpha2Code"
                                            :value="country.name.common">{{
                                                country.name.common }}</option>
                                    </select>
                                    <label for="newAddressCountry">{{ $t('accountPages.address.add.form.addressCountry') }}</label>
                                </div>
                            </div>
                        </div>
                     
                        <div class="row g-2 p-2">
                            <div class="col-lg-4 col-md-12 col-sm-12">
                                <div class="form-floating">
                                    <input type="text" class="form-control shadow-sm" v-model="addressData.first_name"
                                        :placeholder="$t('accountPages.address.add.form.name')" id="newAddressName">
                                    <label for="newAddressName">{{ $t('accountPages.address.add.form.name') }}</label>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 col-sm-12">
                                <div class="form-floating">
                                    <input type="text" class="form-control shadow-sm" v-model="addressData.last_name"
                                        :placeholder="$t('accountPages.address.add.form.surname')" id="newAddressSurname">
                                    <label for="newAddressSurname">{{ $t('accountPages.address.add.form.surname') }}</label>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 col-sm-12">
                                <div class="form-floating">
                                    <input type="tel" ref="newAddressPhone" class="form-control shadow-sm" @input="validateNewAddressPhone" @keypress="isNumber($event)" v-model="addressData.phone" id="newAddressPhone" required>
                                    <span v-if="isNewAddressPhoneValid == false" class="mt-2 fw-normal text-danger"><span v-if="newAddressPhoneValidText && newAddressPhoneValidText != ''">{{ newAddressPhoneValidText }}</span></span>
                                </div>
                            </div>
                        </div>
                        <div class="row g-2 p-2">
                            <div class="col-lg-4 col-md-12 col-sm-12">
                                <div class="form-floating">
                                    <input type="text" class="form-control shadow-sm" v-model="addressData.city"
                                        :placeholder="$t('accountPages.address.add.form.city')" id="newAddressCity">
                                    <label for="newAddressCity">{{ $t('accountPages.address.add.form.city') }}</label>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 col-sm-12">
                                <div class="form-floating">
                                    <input type="text" class="form-control shadow-sm" v-model="addressData.state"
                                        :placeholder="$t('accountPages.address.add.form.state')" id="newAddressState">
                                    <label for="newAddressState">{{ $t('accountPages.address.add.form.state') }}</label>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 col-sm-12">
                                <div class="form-floating">
                                    <input type="number" class="form-control shadow-sm" v-model="addressData.zipcode"
                                        :placeholder="$t('accountPages.address.add.form.zipcode')" id="newAddressZipcode">
                                    <label for="newAddressZipcode">{{ $t('accountPages.address.add.form.zipcode') }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row g-2 p-2">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-floating">
                                    <input type="text" class="form-control shadow-sm" v-model="addressData.district"
                                        :placeholder="$t('accountPages.address.add.form.district')" id="newAddressDistrict">
                                    <label for="newAddressDistrict">{{ $t('accountPages.address.add.form.district') }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row g-2 p-2">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-floating">
                                    <textarea class="form-control shadow-sm" v-model="addressData.address1"
                                        :placeholder="$t('accountPages.address.add.form.address')" id="newAddressLine" style="height: 100px"></textarea>
                                    <label for="newAddressLine">{{ $t('accountPages.address.add.form.address') }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row g-2 p-2">
                            <div id="alertbox"></div>
                            <div class="col-lg-6 mx-auto col-sm-12 d-grid">
                                <button type="submit" class="btn btn-primary btn-block shadow-sm">{{ $t('accountPages.address.add.form.saveBtn') }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { addAddress } from '@/helpers/api';
import Countries from "@/helpers/countries";
import { alert } from '@/helpers/funcs';
import accountPageSidemenu from '@/components/accountPageSidemenu';


export default {
    name: "AccountAddressesAdd",
    components: {
        accountPageSidemenu
    },
    data() {
        return {
            CountriesData: Countries,
            token: window.localStorage.getItem('token'),
            userId: window.localStorage.getItem('userId'),
            countries: [],
            addressData: {
                address_name: '',
                type: 'shipping',
                address1: '',
                city: '',
                state: '',
                district: '',
                zipcode: '',
                country_code: 'tur',
                user_id: parseInt(window.localStorage.getItem('userId')),
                first_name: '',
                last_name: '',
                phone: '',
                default: false,
                selectedCountry: 'Turkey',
            },
            phoneInputID: "#newAddressPhone",
            iti: null,
            formattedPhoneNumber: "",
            isNewAddressPhoneValid: null,
            newAddressPhoneValidText: this.$t('errors.validation.phoneValidText[0]'),
        }
    },
    async mounted() {
        var input = document.querySelector(this.phoneInputID);
        this.iti = window.intlTelInput(input, {
            autoPlaceholder: "polite",
            customPlaceholder: function(placeholder) {
                if (placeholder.charAt(0) === '0') {
                return placeholder.slice(1);
                } else {
                return placeholder;
                }
            },
            preferredCountries: ["tr"],
            initialCountry: "auto",
            geoIpLookup: callback => {
                fetch("https://ipapi.co/json")
                .then(res => res.json())
                .then(data => callback(data.country_code))
                .catch(() => callback("tr"));
            },
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.1.5/js/utils.js"
        });

        if (this.CountriesData) {
            this.countries = this.CountriesData;
            this.countries.sort((a, b) => {
                const nameA = a.name.common.toLowerCase();
                const nameB = b.name.common.toLowerCase();
                return nameA.localeCompare(nameB);
            });
        }
    },
    methods: {
        validateNewAddressPhone() {
            if (this.addressData.phone != null && this.addressData.phone != '') {
                if (this.iti.isValidNumber()) {
                this.isNewAddressPhoneValid = true;
                this.newAddressPhoneValidText = "";
                } else {
                const errorCode = this.iti.getValidationError();
                if (errorCode == 1) {
                    this.newAddressPhoneValidText = this.$t('errors.validation.phoneValidText[1]');
                } else if (errorCode == 2) {
                    this.newAddressPhoneValidText = this.$t('errors.validation.phoneValidText[2]');
                } else if (errorCode == 3) {
                    this.newAddressPhoneValidText = this.$t('errors.validation.phoneValidText[3]');
                } else {
                    this.newAddressPhoneValidText = this.$t('errors.validation.phoneValidText[0]');
                }
                this.isNewAddressPhoneValid = false;
                }
            } else {
                this.isNewAddressPhoneValid = null;
                this.newAddressPhoneValidText = "";
            }
        },
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        async saveAddress() {
            if (this.isNewAddressPhoneValid) {
                if (this.addressData.phone.length > 0) {
                    if (this.addressData.phone.charAt(0) === '0') {
                        this.addressData.phone = this.addressData.phone.slice(1);
                    }
                    var selectedCountryData = this.iti.getSelectedCountryData();
                    var countryDialCode = selectedCountryData.dialCode;
                    this.formattedPhoneNumber = `${countryDialCode}${this.addressData.phone}`;
                    this.formattedPhoneNumber = this.formattedPhoneNumber.replace(/\s/g, '');
                }

                const { success, message } = await addAddress(this.token, this.addressData.address_name, this.addressData.type, this.addressData.address1, this.addressData.city, this.addressData.state, this.addressData.district, String(this.addressData.zipcode), this.addressData.country_code, this.addressData.user_id, this.addressData.first_name, this.addressData.last_name, this.formattedPhoneNumber, this.addressData.default, this.addressData.selectedCountry);
                if (success) {
                    alert("#alertbox", message, "success", "mt-2");
                    setTimeout(() => {
                        this.$router.push('/Account/Addresses');
                    }, 5000);
                } else {
                    alert("#alertbox", message, "danger", "mt-2");
                }
            } else {
                this.$refs.newAddressPhone.focus();
                if (this.newAddressPhoneValidText != null && this.newAddressPhoneValidText != '') {
                    alert("#alertbox", this.newAddressPhoneValidText, "danger", "mt-2");
                }
            }
        },
        countryInputChange() {
            var countryIndex = this.countries.findIndex(country => country?.name?.common.toLowerCase() === this.addressData.selectedCountry.toLowerCase());
            if (countryIndex !== -1) {
                var countryCode = this.countries[countryIndex]?.cca3;
                this.addressData.country_code = countryCode.toLowerCase();
            } else {
                this.addressData.country_code = '';
            }
        }
    }
};
</script>

<style scoped src="../assets/css/account.css" />
<template>
<div>
    <div class="px-4 my-5 text-center">
        <h1 class="text-center mt-5 p-1" id="page-header-text">{{ $t('accountPages.header') }}</h1>
    </div>

    <div class="container py-3">
        <div class="row">
            <accountPageSidemenu />
            <div class="col-lg-9 col-md-8 col-sm-12 orders-tab" id="orders-tab">
                <div class="border my-2 rounded-2 shadow border-0">
                    <div class="container text-center">
                        <div class="row p-4 align-items-center">
                            <div class="col-md-12 col-sm-4 col-lg-4">
                                <h5 class="fw-normal align-items-center d-flex">
                                    <router-link to="/Account/orders" class="link-dark link-offset-2 link-underline-opacity-0 link-underline-opacity-100-hover">{{ $t('accountPages.orderDetails.header[0]') }}</router-link>
                                    <i class="fa fa-chevron-right fa-2xs mx-2" style="font-size:14px;"></i><span class="text-zerduz">{{ $t('accountPages.orderDetails.header[1]') }}</span>
                                </h5>
                            </div>
                            <div class="col-md-8 col-sm-8 col-lg-6 ms-auto d-block d-sm-block d-md-block d-lg-block">
                                <div>
                                    <small class="me-3"><span class="fw-normal">{{ $t('accountPages.orderDetails.details.orderID') }}</span> <span class="fw-light text-muted">{{ this.order?.ShopOrders?.payorder_id }}</span></small>
                                    <small><span class="fw-normal">{{ $t('accountPages.orderDetails.details.orderDate') }}</span> <span class="fw-light text-muted">{{ this.order?.ShopOrders?.order_time }}</span></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="card w-100 mx-auto mt-3 rounded-2 shadow border-0">
                        <div v-if="order?.ShopOrders?.status == $t('accountPages.orderDetails.details.orderStatus[5].key') || order?.ShopOrders?.status == $t('accountPages.orderDetails.details.orderStatus[4].key')" class="card-header bg-body d-none d-sm-none d-md-none d-lg-none" style="border-bottom: 0!important;">
                            <span class="text-end text-muted mx-2"><small><button type="button" class="btn btn-light mt-3 mx-auto d-flex btn-sm">{{ $t('accountPages.orderDetails.details.returnBtn') }}</button></small></span>
                        </div>
                        <div class="card-header bg-body d-block d-sm-block d-md-none d-lg-none" style="border-bottom: 0!important;">
                            <span v-if="order?.ShopOrders?.status == $t('accountPages.orderDetails.details.orderStatus[5].key') || order?.ShopOrders?.status == $t('accountPages.orderDetails.details.orderStatus[4].key')" class="text-end text-muted mx-2"><small><button type="button" class="btn btn-light mt-3 mx-auto d-flex btn-sm">{{ $t('accountPages.orderDetails.details.returnBtn') }}</button></small></span>
                            <!-- <div><small>{{ $t('accountPages.orderDetails.details.orderID') }} <strong class="text-muted">Z327866413287</strong></small></div>
                            <div><small>{{ $t('accountPages.orderDetails.details.orderDate') }} <strong class="text-muted">13 Şubat 2024 10:48</strong></small></div> -->
                        </div>
                        <div class="card-body shipment-section">
                            <div class="row justify-content-between align-items-center mt-2">
                                <div class="col hstack align-items-center d-flex">
                                    <span class="order-status-shipped-text" v-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[3].key')">
                                        <i class="fa-solid fa-truck-moving text-info px-2"></i>{{ $t('accountPages.orderDetails.details.orderStatusDetails[1].text1') }}
                                        <!-- <div class="d-none d-sm-none d-md-block d-lg-block"><small class="ms-2" style="font-size:13px;">Siparişiniz 13 Şubat Salı günü kargoya verilmiştir.</small></div> -->
                                    </span>
                                    <span class="order-status-accepted-text" v-else-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[0].key')">
                                        <i class="fa-solid fa-check text-info px-2"></i>{{ $t('accountPages.orderDetails.details.orderStatusDetails[2].text1') }}
                                        <div class="d-none d-sm-none d-md-block d-lg-block"><small class="ms-2" style="font-size:13px;">{{ $t('accountPages.orderDetails.details.orderStatusDetails[2].text2') }}</small></div>
                                    </span>
                                    <span class="order-status-preparing-text" v-else-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[7].key')">
                                        <i class="fa-solid fa-list-check text-info px-2"></i>{{ $t('accountPages.orderDetails.details.orderStatusDetails[3].text1') }}
                                        <div class="d-none d-sm-none d-md-block d-lg-block"><small class="ms-2" style="font-size:13px;">{{ $t('accountPages.orderDetails.details.orderStatusDetails[3].text2') }}</small></div>
                                    </span>
                                    <span class="order-status-delivered-text" v-else-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[4].key')">
                                        <i class="fa-solid fa-truck-ramp-box text-info px-2"></i>{{ $t('accountPages.orderDetails.details.orderStatusDetails[4].text1') }}
                                        <!-- <div class="d-none d-sm-none d-md-block d-lg-block"><small class="ms-2" style="font-size:13px;">Siparişiniz 13 Şubat Salı günü teslim edildi.</small></div> -->
                                    </span>
                                    <span class="order-status-payment-unsuccessfull-text" v-else-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[1].key')">
                                        <i class="fa-solid fa-circle-exclamation text-danger px-2"></i>{{ $t('accountPages.orderDetails.details.orderStatusDetails[5].text1') }}
                                        <div class="d-none d-sm-none d-md-block d-lg-block"><small class="ms-2" style="font-size:13px;">{{ $t('accountPages.orderDetails.details.orderStatusDetails[5].text2[0]') }}<router-link to="/Contact" class="fst-italic text-decoration-none"> {{ $t('accountPages.orderDetails.details.orderStatusDetails[5].text2[1]') }}</router-link> {{ $t('accountPages.orderDetails.details.orderStatusDetails[5].text2[2]') }}</small></div>
                                    </span>
                                    <span class="order-status-cancelled-text" v-else-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[2].key')">
                                        <i class="fa-solid fa-ban text-danger px-2"></i>{{ $t('accountPages.orderDetails.details.orderStatusDetails[6].text1') }}
                                        <!-- <div class="d-none d-sm-none d-md-block d-lg-block"><small class="ms-2" style="font-size:13px;">order.ShopOrders.CancelReason</small></div> -->
                                    </span>
                                    <span class="order-status-completed-text" v-else-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[5].key')">
                                        <i class="fa-solid fa-thumbs-up text-success px-2"></i>{{ $t('accountPages.orderDetails.details.orderStatusDetails[7].text1') }}
                                        <!-- <div class="d-none d-sm-none d-md-block d-lg-block"><small class="ms-2" style="font-size:13px;">Siparişiniz 13 Şubat Salı günü teslim edilmiştir.</small></div> -->
                                    </span>
                                    <span class="order-status-returned-text" v-else-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[6].key')">
                                        <i class="fa-solid fa-reply text-danger px-2"></i>{{ $t('accountPages.orderDetails.details.orderStatusDetails[8].text1') }}
                                        <!-- <div class="d-none d-sm-none d-md-block d-lg-block"><small class="ms-2" style="font-size:13px;">Siparişiniz 13 Şubat Salı günü iade edildi.</small></div>
                                        <div class="mx-1"><span class="badge rounded text-bg-danger fw-normal">İade Kodu: <span class="fw-bold">1234567890</span></span></div> -->
                                    </span>
                                    <div class="pe-2 ps-2 ms-auto" v-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[3].key') && order?.ShopOrders?.kargo_link"><a type="button" :href="order?.ShopOrders?.kargo_link" target="_blank" class="btn btn-outline-primary rounded-0 mx-auto d-flex btn-sm">{{ $t('accountPages.orderDetails.details.orderStatusDetails[9].text1') }}</a></div>
                                    <div class="pe-2 ps-2 ms-auto" v-else-if="(order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[4].key') || order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[5].key')) && order?.ShopOrders?.kargo_link"><a type="button" :href="order?.ShopOrders?.kargo_link" target="_blank" class="btn btn-outline-primary rounded-0 mx-auto d-flex btn-sm">{{ $t('accountPages.orderDetails.details.orderStatusDetails[10].text1') }}</a></div>
                                </div>
                                <div class="mx-1" v-if="(order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[3].key')) && (order?.ShopOrders?.kargo_company || order?.ShopOrders?.kargo_track)"><span class="badge rounded text-bg-light fw-normal"><div class="d-flex align-items-center"><span v-if="order?.ShopOrders?.kargo_company">{{ $t('accountPages.orderDetails.details.orderStatusDetails[11].text1') }} <span class="fw-bold">{{ order?.ShopOrders?.kargo_company }}</span></span><span v-if="order?.ShopOrders?.kargo_company && order?.ShopOrders?.kargo_track">&emsp;<div class="vr"></div>&emsp;</span><span v-if="order?.ShopOrders?.kargo_track">{{ $t('orderTracking.input.cargoInput') }}: <span class="fw-bold">{{ order?.ShopOrders?.kargo_track }}</span></span></div></span></div>
                            </div>
                            
                            <div class="row" v-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[0].key') || order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[3].key') || order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[7].key') || order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[4].key')">
                                <div class="col-10 mx-auto my-5">
                                    <div class="position-relative">
                                        <div class="progress" role="progressbar" aria-label="Progress" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="height: 1px;">
                                            <div v-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[0].key')" class="bg-zerduz" style="width: 0%"></div>
                                            <div v-else-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[7].key')" class="bg-zerduz" style="width: 33%"></div>
                                            <div v-else-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[3].key')" class="bg-zerduz" style="width: 66%"></div>
                                            <div v-else-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[4].key')" class="bg-zerduz" style="width: 100%"></div>
                                            <div v-else style="width: 0%"></div>
                                        </div>
                                        <div id="order-accepted" v-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[0].key')">
                                            <div>
                                                <span class="position-absolute top-0 start-0 translate-middle bg-primary rounded-pill progress-1 box-glow-zerduz" style="width: 1rem; height:1rem;"></span>
                                                <span class="position-absolute top-0 start-0 translate-middle rounded-pill progress-1 mt-4 pt-2 text-center" style="font-size:0.75rem; width:25%;">{{ $t('accountPages.orderDetails.details.lineDetails[0]') }}</span>
                                            </div>
                                            <div>
                                                <span class="position-absolute top-0 start-33 translate-middle border bg-body rounded-pill progress-2" style="width: 1rem; height:1rem;"></span>
                                                <span class="position-absolute top-0 start-33 translate-middle rounded-pill progress-1 mt-4 pt-2 text-center" style="font-size:0.75rem; width:25%;">{{ $t('accountPages.orderDetails.details.lineDetails[1]') }}</span>
                                            </div>
                                            <div>
                                                <span class="position-absolute top-0 start-66 translate-middle border bg-body rounded-pill progress-3" style="width: 1rem; height:1rem;"></span>
                                                <span class="position-absolute top-0 start-66 translate-middle rounded-pill progress-1 mt-4 pt-2 text-center" style="font-size:0.75rem; width:25%;">{{ $t('accountPages.orderDetails.details.lineDetails[2]') }}</span>
                                            </div>
                                            <div>
                                                <span class="position-absolute top-0 start-100 translate-middle border bg-body rounded-pill progress-4" style="width: 1rem; height:1rem;"></span>
                                                <span class="position-absolute top-0 start-100 translate-middle rounded-pill progress-1 mt-4 pt-2 text-center" style="font-size:0.75rem; width:25%;">{{ $t('accountPages.orderDetails.details.lineDetails[3]') }}</span>
                                            </div>
                                        </div>
                                        <div id="order-preparing" v-else-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[7].key')">
                                            <div>
                                                <span class="position-absolute top-0 start-0 translate-middle bg-primary rounded-pill progress-1" style="width: 1rem; height:1rem;"></span>
                                                <span class="position-absolute top-0 start-0 translate-middle rounded-pill progress-1 mt-4 pt-2 text-center" style="font-size:0.75rem; width:25%;">{{ $t('accountPages.orderDetails.details.lineDetails[0]') }}</span>
                                            </div>
                                            <div>
                                                <span class="position-absolute top-0 start-33 translate-middle bg-primary rounded-pill progress-2 box-glow-zerduz" style="width: 1rem; height:1rem;"></span>
                                                <span class="position-absolute top-0 start-33 translate-middle rounded-pill progress-1 mt-4 pt-2 text-center" style="font-size:0.75rem; width:25%;">{{ $t('accountPages.orderDetails.details.lineDetails[1]') }}</span>
                                            </div>
                                            <div>
                                                <span class="position-absolute top-0 start-66 translate-middle border bg-body rounded-pill progress-3" style="width: 1rem; height:1rem;"></span>
                                                <span class="position-absolute top-0 start-66 translate-middle rounded-pill progress-1 mt-4 pt-2 text-center" style="font-size:0.75rem; width:25%;">{{ $t('accountPages.orderDetails.details.lineDetails[2]') }}</span>
                                            </div>
                                            <div>
                                                <span class="position-absolute top-0 start-100 translate-middle border bg-body rounded-pill progress-4" style="width: 1rem; height:1rem;"></span>
                                                <span class="position-absolute top-0 start-100 translate-middle rounded-pill progress-1 mt-4 pt-2 text-center" style="font-size:0.75rem; width:25%;">{{ $t('accountPages.orderDetails.details.lineDetails[3]') }}</span>
                                            </div>
                                        </div>
                                        <div id="order-shipped" v-else-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[3].key')">
                                            <div>
                                                <span class="position-absolute top-0 start-0 translate-middle bg-primary rounded-pill progress-1" style="width: 1rem; height:1rem;"></span>
                                                <span class="position-absolute top-0 start-0 translate-middle rounded-pill progress-1 mt-4 pt-2 text-center" style="font-size:0.75rem; width:25%;">{{ $t('accountPages.orderDetails.details.lineDetails[0]') }}</span>
                                            </div>
                                            <div>
                                                <span class="position-absolute top-0 start-33 translate-middle bg-primary rounded-pill progress-2" style="width: 1rem; height:1rem;"></span>
                                                <span class="position-absolute top-0 start-33 translate-middle rounded-pill progress-1 mt-4 pt-2 text-center" style="font-size:0.75rem; width:25%;">{{ $t('accountPages.orderDetails.details.lineDetails[1]') }}</span>
                                            </div>
                                            <div>
                                                <span class="position-absolute top-0 start-66 translate-middle bg-primary rounded-pill progress-3 box-glow-zerduz" style="width: 1rem; height:1rem;"></span>
                                                <span class="position-absolute top-0 start-66 translate-middle rounded-pill progress-1 mt-4 pt-2 text-center" style="font-size:0.75rem; width:25%;">{{ $t('accountPages.orderDetails.details.lineDetails[2]') }}</span>
                                            </div>
                                            <div>
                                                <span class="position-absolute top-0 start-100 translate-middle border bg-body rounded-pill progress-4" style="width: 1rem; height:1rem;"></span>
                                                <span class="position-absolute top-0 start-100 translate-middle rounded-pill progress-1 mt-4 pt-2 text-center" style="font-size:0.75rem; width:25%;">{{ $t('accountPages.orderDetails.details.lineDetails[3]') }}</span>
                                            </div>
                                        </div>
                                        <div id="order-delivered" v-else-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[4].key')">
                                            <div>
                                                <span class="position-absolute top-0 start-0 translate-middle bg-primary rounded-pill progress-1" style="width: 1rem; height:1rem;"></span>
                                                <span class="position-absolute top-0 start-0 translate-middle rounded-pill progress-1 mt-4 pt-2 text-center" style="font-size:0.75rem; width:25%;">{{ $t('accountPages.orderDetails.details.lineDetails[0]') }}</span>
                                            </div>
                                            <div>
                                                <span class="position-absolute top-0 start-33 translate-middle bg-primary rounded-pill progress-2" style="width: 1rem; height:1rem;"></span>
                                                <span class="position-absolute top-0 start-33 translate-middle rounded-pill progress-1 mt-4 pt-2 text-center" style="font-size:0.75rem; width:25%;">{{ $t('accountPages.orderDetails.details.lineDetails[1]') }}</span>
                                            </div>
                                            <div>
                                                <span class="position-absolute top-0 start-66 translate-middle bg-primary rounded-pill progress-3" style="width: 1rem; height:1rem;"></span>
                                                <span class="position-absolute top-0 start-66 translate-middle rounded-pill progress-1 mt-4 pt-2 text-center" style="font-size:0.75rem; width:25%;">{{ $t('accountPages.orderDetails.details.lineDetails[2]') }}</span>
                                            </div>
                                            <div>
                                                <span class="position-absolute top-0 start-100 translate-middle bg-primary rounded-pill progress-4 box-glow-zerduz" style="width: 1rem; height:1rem;"></span>
                                                <span class="position-absolute top-0 start-100 translate-middle rounded-pill progress-1 mt-4 pt-2 text-center" style="font-size:0.75rem; width:25%;">{{ $t('accountPages.orderDetails.details.lineDetails[3]') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="card w-100 mx-auto mt-3 rounded-2 shadow border-0">
                        <div class="card-body">
                            <div class="row justify-content-between align-items-center d-lg-flex d-md-flex d-sm-none d-none">
                                <div class="col-2 ">
                                    <img src="../assets/img/zerduz-2.png" class="img-fluid ms-4" style="width: 85%!important;" alt="">
                                </div>
                                <div class="col-3 mt-3">
                                    <span class="">{{ $t('productName') }}</span>
                                    <p><small class="text-muted">{{ order?.ShopOrders?.quantity }} {{ $t('accountPages.orderDetails.details.quantity') }}</small></p>
                                </div>
                                <div class="col-3 mt-3 ms-lg-5">
                                    <span class="">
                                        <span v-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[0].key')" class="text-decoration-none order-status-text">
                                        <i class="fa-solid fa-circle-check text-success px-2"></i> {{$t('accountPages.orderDetails.details.orderStatus[0].value')}}
                                            </span>
                                        <span v-else-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[1].key')" class="text-decoration-none order-status-text">
                                            <i class="fa-solid fa-circle-exclamation text-danger px-2"></i> {{$t('accountPages.orderDetails.details.orderStatus[1].value')}}
                                        </span>
                                        <span v-else-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[2].key')" class="text-decoration-none order-status-text">
                                            <i class="fa-solid fa-circle-exclamation text-danger"></i> {{$t('accountPages.orderDetails.details.orderStatus[2].value')}}
                                        </span>
                                        <a v-else-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[3].key')" href="#" class="text-decoration-none order-status-text">
                                            <i class="fa-solid fa-truck-moving text-info px-2"></i> {{$t('accountPages.orderDetails.details.orderStatus[3].value')}}
                                        </a>
                                        <a v-else-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[4].key')" href="#" class="text-decoration-none order-status-text">
                                            <i class="fa-solid fa-check text-success px-2"></i> {{$t('accountPages.orderDetails.details.orderStatus[4].value')}}
                                        </a>
                                        <a v-else-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[5].key')" href="#" class="text-decoration-none order-status-text">
                                            <i class="fa-solid fa-square-check text-success px-2"></i> {{$t('accountPages.orderDetails.details.orderStatus[5].value')}}
                                        </a>
                                        <a v-else-if="order?.ShopOrders?.status === $t('accountPages.orderDetails.details.orderStatus[6].key')" href="#" class="text-decoration-none order-status-text">
                                            <i class="fa-solid fa-reply text-danger px-2"></i> {{$t('accountPages.orderDetails.details.orderStatus[6].value')}}
                                        </a>
                                    </span>
                                    
                                </div>
                                <div class="col-auto text-center mt-3">
                                    <span class="text-zerduz fw-bold">{{ $store.state.price.currency }}{{ formatPrice(String(( (order?.ShopOrders?.total_price - order?.ShopOrders?.kargo_price) / ((((order?.ShopOrders?.total_price - order?.ShopOrders?.kargo_price) / order?.ShopOrders?.quantity) / $store.state.price.price.tax)) ) + cargo.base + (order?.ShopOrders?.quantity * cargo.quantityThreshold)).replace('.', ',')) }}</span>
                                </div>
                            </div>

                            <div class="row align-items-center gap-4 d-lg-none d-md-none d-sm-flex d-flex">
                            <div class="col-12">
                                <img src="../assets/img/zerduz-2.png" class="img-fluid ms-4" style="width: 25%!important;" alt="">
                                <span class="">{{ order?.ShopOrders?.product_name }} <small class="text-muted">({{ order?.ShopOrders?.quantity }} {{ $t('accountPages.orders.orderDetails.quantity') }})</small></span>
                            </div>
                            <div class="col-12 justify-content-between d-flex">
                                <span class="">
                                    <span v-if="order?.ShopOrders?.status === $t('accountPages.orders.orderDetails.orderStatus[0].key')" class="text-decoration-none order-status-text">
                                       <i class="fa-solid fa-circle-check text-success px-2"></i> {{ $t('accountPages.orders.orderDetails.orderStatus[0].value') }}
                                        </span>
                                    <span v-else-if="order?.ShopOrders?.status === $t('accountPages.orders.orderDetails.orderStatus[1].key')" class="text-decoration-none order-status-text">
                                        <i class="fa-solid fa-circle-exclamation text-danger px-2"></i> {{ $t('accountPages.orders.orderDetails.orderStatus[1].value') }}
                                    </span>
                                    <span v-else-if="order?.ShopOrders?.status === $t('accountPages.orders.orderDetails.orderStatus[2].key')" class="text-decoration-none order-status-text">
                                        <i class="fa-solid fa-circle-exclamation text-danger"></i> {{ $t('accountPages.orders.orderDetails.orderStatus[2].value') }}
                                    </span>
                                    <a v-else-if="order?.ShopOrders?.status === $t('accountPages.orders.orderDetails.orderStatus[3].key')" href="#" class="text-decoration-none order-status-text">
                                        <i class="fa-solid fa-truck-moving text-info px-2"></i> {{ $t('accountPages.orders.orderDetails.orderStatus[3].value') }}
                                    </a>
                                    <a v-else-if="order?.ShopOrders?.status === $t('accountPages.orders.orderDetails.orderStatus[4].key')" href="#" class="text-decoration-none order-status-text">
                                        <i class="fa-solid fa-check text-success px-2"></i> {{ $t('accountPages.orders.orderDetails.orderStatus[4].value') }}
                                    </a>
                                    <a v-else-if="order?.ShopOrders?.status === $t('accountPages.orders.orderDetails.orderStatus[5].key')" href="#" class="text-decoration-none order-status-text">
                                        <i class="fa-solid fa-square-check text-success px-2"></i> {{ $t('accountPages.orders.orderDetails.orderStatus[5].value') }}
                                    </a>
                                    <a v-else-if="order?.ShopOrders?.status === $t('accountPages.orders.orderDetails.orderStatus[6].key')" href="#" class="text-decoration-none order-status-text">
                                        <i class="fa-solid fa-reply text-danger px-2"></i> {{ $t('accountPages.orders.orderDetails.orderStatus[6].value') }}
                                    </a>
                                </span>
                                <span class="text-zerduz fw-bold">{{ formatPrice(String(( (order?.ShopOrders?.total_price - order?.ShopOrders?.kargo_price) / ((((order?.ShopOrders?.total_price - order?.ShopOrders?.kargo_price) / order?.ShopOrders?.quantity) / $store.state.price.price.tax)) ) + cargo.base + (order?.ShopOrders?.quantity * cargo.quantityThreshold)).replace('.', ',')) }}$</span>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-6 col-sm-6 col-md-6 col-lg-3 pe-1">
                            <div class="card mx-auto rounded-2 shadow border-0 g-4">
                                <div class="card-body">
                                    <div>
                                        <h6 class="text-zerduz">{{ $t('accountPages.orderDetails.details.billingAddress') }}</h6>
                                        <div class="col mt-4">{{ order?.BillAddress?.firstName }} {{ order.BillAddress?.lastName }}</div>
                                        <div class="col mt-1">{{ order?.BillAddress?.address1 + " " + order.BillAddress?.district }}</div>
                                        <div class="col mt-1">{{ order?.BillAddress?.state + " " + order.BillAddress?.city + " / " + order.BillAddress?.country + " " + order.BillAddress?.zipcode }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 col-lg-3 pe-1">
                            <div class="card mx-auto rounded-2 shadow border-0 g-4">
                                <div class="card-body">
                                    <div>
                                        <h6 class="text-zerduz">{{ $t('accountPages.orderDetails.details.shippingAddress') }}</h6>
                                        <div class="col mt-4">{{ order?.ShipAddress?.firstName }} {{ order?.ShipAddress?.lastName }}</div>
                                        <div class="col mt-1">{{ order?.ShipAddress?.address1 + " " + order?.ShipAddress?.district }}</div>
                                        <div class="col mt-1">{{ order?.ShipAddress?.state + " " + order?.ShipAddress?.city + " / " + order?.ShipAddress?.country + " " + order?.ShipAddress?.zipcode }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 mt-sm-3 mt-md-3 mt-lg-0 mt-3">
                            <div class="card mx-auto rounded-2 shadow border-0 g-4">
                                <div class="card-body">
                                    <div>
                                        <h6 class="d-flex justify-content-between mb-0">
                                            <span class="text-zerduz">{{ $t('accountPages.orderDetails.details.paymentInfo.title') }}</span>
                                            <span>
                                                <span v-if="order?.ShopPayments?.last_four_digits"><img v-if="order?.ShopPayments?.card_association" :src="getPaymentImage()" class="img-fluid" width="32">&nbsp;<small>**** {{ order?.ShopPayments?.last_four_digits}}</small></span>
                                                <small v-if="order?.ShopPayments?.installment" class="d-flex text-end justify-content-end">{{ order?.ShopPayments?.installment === 0 || order?.ShopPayments?.installment === 1 ? $t('accountPages.orderDetails.details.paymentInfo.oneInstallment') : order?.ShopPayments?.installment + $t('accountPages.orderDetails.details.paymentInfo.installment') }}</small>
                                            </span>
                                        </h6>
                                        <ul class="list-group list-group-flush m-0">
                                            <li class="list-group-item d-flex bg-transparent p-0 py-2 justify-content-between align-items-center">
                                                <strong><small class="fw-light" id="quantity-text">{{ order?.ShopOrders?.quantity }}x</small> {{ order?.ShopOrders?.product_name }}</strong>
                                                <span class="text-end d-flex"><span class="badge text-bg-light me-1 rounded-pill fw-normal">{{ formatPrice(String(order?.ShopOrders?.unit_price).replace('.', ',')) }}₺</span> {{ $store.state.price.currency }}{{ formatPrice(String(( (order?.ShopOrders?.unit_price) / ((((order?.ShopOrders?.total_price - order?.ShopOrders?.kargo_price) / order?.ShopOrders?.quantity) / $store.state.price.price.tax)) )).replace('.', ',')) }}</span>
                                            </li>
                                            <li class="list-group-item d-flex bg-transparent p-0 py-2 justify-content-between align-items-center">
                                                {{ $t('accountPages.orderDetails.details.cargo') }}
                                                <span class="text-end"><span class="badge text-bg-light me-1 rounded-pill fw-normal">{{ formatPrice(String(order?.ShopOrders?.kargo_price).replace('.', ',')) }}₺</span>{{ $store.state.price.currency }}{{ formatPrice(String(cargo.base + (order?.ShopOrders?.quantity * cargo.quantityThreshold)).replace('.', ',')) }}</span>
                                            </li>
                                            <li class="list-group-item d-flex bg-transparent p-0 py-2 justify-content-between align-items-center border-bottom-0">
                                                {{ $t('accountPages.orderDetails.details.total') }}
                                                <span class="text-end text-zerduz fw-bold"><span class="badge text-bg-light me-1 rounded-pill fw-normal">{{ formatPrice(String(order?.ShopOrders?.total_price).replace('.', ',')) }}₺</span>{{ $store.state.price.currency }}{{ formatPrice(String(( (order?.ShopOrders?.total_price - order?.ShopOrders?.kargo_price) / ((((order?.ShopOrders?.total_price - order?.ShopOrders?.kargo_price) / order?.ShopOrders?.quantity) / $store.state.price.price.tax)) ) + cargo.base + (order?.ShopOrders?.quantity * cargo.quantityThreshold)).replace('.', ',')) }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    getOrder
} from '@/helpers/api';
import accountPageSidemenu from '@/components/accountPageSidemenu';

export default {
    name: "AccountOrderDetails",
    components: {
        accountPageSidemenu
    },
    data() {
        return {
            token: window.localStorage.getItem('token'),
            userId: window.localStorage.getItem('userId'),
            order: [],
            payorder_id: null,
            cargo: {
                total: 0,
                base: 5,
                quantityThreshold: 0
            }
        };
    },
    methods: {
        formatPrice(price) {
            if (price) {
                const cleanedPrice = price.replace(/[^\d,.-]/g, '');
                const floatValue = parseFloat(cleanedPrice.replace(',', '.'));

                var decimalPart = (floatValue % 1 === 0) ? '00' : (floatValue % 1).toFixed(2).split('.')[1];

                const integerPart = Math.floor(floatValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

                if (decimalPart == '00') {
                    decimalPart = null;
                }
                const result = `${integerPart}${decimalPart ? ',' + decimalPart : ''}`;

                return result;
            } else {
                return 0;
            }
        },
        getPaymentImage() {
            const card_association = this.order?.ShopPayments?.card_association;
            var images = require.context('../assets/img/', false, /\.svg$/)

            if (card_association === 'MASTER_CARD') {
                return images('./' + 'mastercard-logo' + ".svg")
            } else if (card_association === 'VISA') {
                return images('./' + 'visa-logo' + ".svg")
            } else if (card_association === 'AMERICAN_EXPRESS') {
                return images('./' + 'american-express-logo' + ".svg")
            } else if (card_association === 'TROY') {
                return images('./' + 'troy-logo' + ".svg")
            } else {
                return images('./' + 'mastercard-logo' + ".svg")
            }
        },
        toggleIcon(order) {
            order.rotateIcon = !order.rotateIcon;
        },
        toggleOrderDetails(order) {
            order.expanded = !order.expanded;
        },
        async fetchData() {
            try {
                const {
                    success,
                    data
                } = await getOrder(this.token, this.payorder_id);
                if (success) {
                    this.order = data;
                    if (this.order && this.order != null) {
                        this.order.ShopOrders.order_time = this.formatDate(this.order.ShopOrders.order_time);
                        this.order.ShopOrders.product_name = this.$t('productName');
                        delete this.order.ShopOrders.DeletedAt;
                        delete this.order.ShopOrders.UpdatedAt;
                    }
                } else {
                    this.$router.push('Account/Orders');
                }
            } catch (error) {
                this.$router.push('Account/Orders');
            }
        },
        formatDate(sourceDate) {
            const date = new Date(sourceDate);
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: false,
                timeZone: 'Europe/Istanbul'
            };

            return date.toLocaleString(this.$i18n.locale, options);
        },
        parseDate(dateString) {
            const parts = dateString.split(/[-T:.Z]/);

            const year = parseInt(parts[0]);
            const month = parseInt(parts[1]) - 1;
            const day = parseInt(parts[2]);
            const hours = parseInt(parts[3] || 0);
            const minutes = parseInt(parts[4] || 0);
            const seconds = parseInt(parts[5] || 0);
            const milliseconds = parseInt(parts[6] || 0);

            const date = new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds));

            return isNaN(date.getTime()) ? null : date;
        }
    },
    computed: {
        filteredItems() {
            if (this.orders && this.orders.length > 0) {
                const filtered = this.orders.filter(item =>
                    Object.values(item.ShopOrders).some(
                        prop =>
                        prop &&
                        prop.toString().toLowerCase().includes(this.search.toLowerCase())
                    )
                );
                return filtered.sort((a, b) => {
                    const dateA = this.parseDate(a.ShopOrders.CreatedAt);
                    const dateB = this.parseDate(b.ShopOrders.CreatedAt);
                    if (dateA && dateB) {
                        return dateB - dateA;
                    } else if (dateA === null && dateB === null) {
                        return 0;
                    } else if (dateA === null) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
            } else {
                return 0;
            }
        }
    },
    mounted() {
        const payorder_id = this.$route.params.id;
        if (payorder_id != null && payorder_id != "" && payorder_id != 0) {
            this.payorder_id = payorder_id;
            this.fetchData();
        } else {
            this.$router.push('Account/Orders');
        }
    }
};
</script>

<style scoped src="../assets/css/account.css"/>

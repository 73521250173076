<template>
    <div>
        <div class="px-4 my-5 text-center">
            <h1 class="text-center mt-5 p-1" id="page-header-text">{{ $t('accountPages.header') }}</h1>
        </div>

        <div class="container py-3">
            <div class="row">
                <accountPageSidemenu />
                <div class="col-lg-9 col-md-8 col-sm-12" id="account-settings-tab">
                    <div class="border my-2 rounded-2 shadow-sm">
                        <div class="container">
                            <div class="row p-3">
                                <ul class="nav nav-pills nav-fill gap-2 p-1 small bg-primary rounded-5 shadow-sm" id="settingsNav" role="tablist" style="--bs-nav-link-color: var(--bs-white); --bs-nav-pills-link-active-color: var(--bs-primary); --bs-nav-pills-link-active-bg: var(--bs-white);">
                                    <div class="col">
                                        <li class="nav-item" role="presentation">
                                            <router-link to="/Account/Settings" active-class="" class="nav-link text-white text-opacity-50 rounded-5" id="info-tab" type="button">{{ $t('accountPages.settings.menu[0].title') }}</router-link>
                                        </li>
                                    </div>
                                    <div class="col">
                                        <li class="nav-item" role="presentation">
                                            <router-link to="/Account/Settings/Password" active-class="" class="nav-link text-white text-opacity-50 rounded-5" id="password-tab" type="button">{{ $t('accountPages.settings.menu[1].title') }}</router-link>
                                        </li>
                                    </div>
                                    <div class="col">
                                        <li class="nav-item" role="presentation">
                                            <router-link to="/Account/Settings/Company" active-class="" class="nav-link active rounded-5" id="company-tab" type="button">{{ $t('accountPages.settings.menu[2].title') }}</router-link>
                                        </li>
                                    </div>
                                </ul>     

                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-company" role="tabpanel"
                                        aria-labelledby="pills-company-tab" tabindex="0">
                                        <form class="row g-2 my-2" @submit.prevent="updateCompanyInfos">
                                            <div class="col-md-6">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" v-model="accountData.firma"
                                                        id="company-name" :placeholder="$t('accountPages.settings.company.form.companyName')">
                                                    <label for="company-name">{{ $t('accountPages.settings.company.form.companyName') }}</label>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" v-model="accountData.marka"
                                                        id="company-brand" :placeholder="$t('accountPages.settings.company.form.companyBrand')">
                                                    <label for="company-brand">{{ $t('accountPages.settings.company.form.companyBrand') }}</label>
                                                </div>
                                            </div>
                                            <div class="row g-2">
                                                <div class="col-md-12">
                                                    <label for="company-phone" class="mb-1">{{ $t('accountPages.settings.company.form.companyPhone') }}</label>
                                                        <div class="input-group">
                                                            <div class="input-group-text mb-sm-2 mb-md-2 mb-lg-0 mb-2">
                                                                <input class="form-check-input" id="company-subscribe" v-model="accountData.subscribe" type="checkbox" value="false">
                                                                <label for="company-subscribe">&nbsp;{{ $t('accountPages.settings.company.form.companySubscribe') }}</label>
                                                            </div>
                                                            <input type="tel" ref="companyPhoneInput" @input="validateCompanyPhone" @keypress="isNumber($event)" class="form-control rounded-start-0" v-model="accountData.company_phone" id="company-phone">
                                                        </div>
                                                    <p v-if="isCompanyPhoneValid == false" class="fw-normal text-danger"><span v-if="companyPhoneValidText && companyPhoneValidText != ''">{{ companyPhoneValidText }}</span></p>
                                                </div>
                                            </div>
                                            <div class="row g-2 mb-2">
                                                <div class="col-md-12">
                                                    <div class="form-floating">
                                                        <div class="input-group mb-3">
                                                            <label class="input-group-text" for="company-logo">{{ $t('accountPages.settings.company.form.logo') }}</label>
                                                            <label tabindex="0" class="form-control" role="button"
                                                                id="logo-input-label">
                                                                {{ accountData.logo ? '' : this.$t('accountPages.settings.company.form.selectFile') }}
                                                                <input type="file" accept="image/png, image/jpeg, image/gif"
                                                                    id="company-logo" class="d-none"
                                                                    @change="onFileSelected">
                                                                <div v-if="accountData.logo && selectedImage == null" class="text-center p-3">
                                                                    <img :src="imageUrl" class="img-fluid" width="300" height="auto" id="preview" />
                                                                </div>
                                                                <div v-if="selectedImage != null" class="text-center p-3">
                                                                    <img :src="selectedImage" class="img-fluid" width="300" height="auto" id="preview" />
                                                                </div>
                                                            </label>
                                                            <button v-if="accountData.logo" class="btn btn-outline-primary text-zerduz"
                                                                @click="resetFileInput" type="button" id="company-logo"><i
                                                                    class="fa-solid fa-xmark fa-2xl"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div id="alertbox"></div>
                                                <div class="col-12 d-grid gap-2 mb-2">
                                                    <button type="submit"
                                                        class="btn btn-primary btn-block mt-3 mx-auto">{{ $t('accountPages.settings.company.form.btn') }}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCompanyInfo, updateCompany, updateLogo } from "@/helpers/api";
import { alert } from "@/helpers/funcs";
import { parsePhoneNumber } from 'libphonenumber-js';
import accountPageSidemenu from '@/components/accountPageSidemenu';

export default {
    name: "AccountSettingsCompany",
    components: {
        accountPageSidemenu
    },
    data() {
        return {
            token: window.localStorage.getItem('token'),
            userId: window.localStorage.getItem('userId'),
            accountData: {
                firma: '',
                marka: '',
                logo: '',
                subscribe: 0,
                company_phone: '',
            },
            file: '',
            url: 'https://backend.zerduz.com/logos/',
            selectedImage: null,
            phoneInputID: "#company-phone",
            iti: null,
            formattedPhoneNumber: "",
            isCompanyPhoneValid: true,
            companyPhoneValidText: this.$t('errors.validation.phoneValidText[0]'),
        };
    },
    mounted() {
        var input = document.querySelector(this.phoneInputID);
        this.iti = window.intlTelInput(input, {
            autoPlaceholder: "polite",
            customPlaceholder: function(placeholder) {
                if (placeholder.charAt(0) === '0') {
                return placeholder.slice(1);
                } else {
                return placeholder;
                }
            },
            preferredCountries: ["tr"],
            initialCountry: "auto",
            geoIpLookup: callback => {
                fetch("https://ipapi.co/json")
                .then(res => res.json())
                .then(data => callback(data.country_code))
                .catch(() => callback("tr"));
            },
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.1.5/js/utils.js"
        });
    },
    async created() {
        const { success, message, data } = await getCompanyInfo(this.token);
        if (success) {
            this.accountData = data.data;
            const telInput = this.iti;
            try {
                const incomingPhoneNumber = "+" + data.data.company_phone;
                const phoneNumber = parsePhoneNumber(incomingPhoneNumber);
                telInput.setCountry(phoneNumber.country);
                this.accountData.company_phone = phoneNumber.nationalNumber;
                telInput.setNumber(phoneNumber.nationalNumber);
            } catch (e) {
                return;
            }
        } else {
            this.$showToast("Zerduz", message, 5000);
        }
    },
    methods: {
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        validateCompanyPhone() {
            if (this.accountData.company_phone != null && this.accountData.company_phone != '') {
                if (this.iti.isValidNumber()) {
                    this.isCompanyPhoneValid = true;
                    this.companyPhoneValidText = "";
                } else {
                    const errorCode = this.iti.getValidationError();
                    if (errorCode == 1) {
                        this.companyPhoneValidText = this.$t('errors.validation.phoneValidText[1]');
                    } else if (errorCode == 2) {
                        this.companyPhoneValidText = this.$t('errors.validation.phoneValidText[2]');
                    } else if (errorCode == 3) {
                        this.companyPhoneValidText = this.$t('errors.validation.phoneValidText[3]');
                    } else {
                        this.companyPhoneValidText = this.$t('errors.validation.phoneValidText[0]');
                    }
                    this.isCompanyPhoneValid = false;
                }
            } else {
                this.isCompanyPhoneValid = null;
                this.companyPhoneValidText = "";
            }
        },
        onFileSelected(event) {
            this.file = event.target.files[0];
            if (this.file) {
                if (this.file.type.startsWith('image/')) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        this.selectedImage = reader.result;
                        this.accountData.logo = this.file.name;
                    };

                    reader.readAsDataURL(this.file);
                }
            } else {
                alert("#alertbox", this.$t('errors.accountCompanySettings.pleaseSelectImage'), "danger", "mt-2");
            }
            
        },
        async updateCompanyInfos() {
            if ((this.accountData.company_phone != null && this.accountData.company_phone != '' && this.isCompanyPhoneValid) ||  (this.accountData.company_phone == null || this.accountData.company_phone == '')) {
                var selectedCountryData = this.iti.getSelectedCountryData();
                var countryDialCode = selectedCountryData.dialCode;
                this.formattedPhoneNumber = `${countryDialCode}${this.accountData.company_phone}`;
                this.formattedPhoneNumber = this.formattedPhoneNumber.replace(/\s/g, '');
                var subscribe = true;
                if (this.accountData.subscribe == true) {
                    subscribe = true;
                } else {
                    subscribe = false;
                }

                const { success, message } = await updateCompany(this.token, this.accountData.firma, this.accountData.marka, subscribe, this.formattedPhoneNumber);

                const { success: logoSuccess } = await updateLogo(this.token, this.file);

                if (logoSuccess && success) {
                    alert("#alertbox", message, "success", "mt-2");
                } else if (logoSuccess && !success) {
                    alert("#alertbox", logoSuccess, "success", "mt-2");
                } else if (!logoSuccess && success) {
                    alert("#alertbox", message, "success", "mt-2");
                }
            } else {
                this.$refs.companyPhoneInput.focus();
                alert("#alertbox", this.companyPhoneValidText, "danger", "mt-2");
            }
        },
        resetFileInput() {
            this.accountData.logo = '';
            this.selectedImage = null;
        }
    },
    computed: {
        imageUrl() {
            return this.url + this.accountData.logo;
        }
    }
};
</script>

<style scoped src="../assets/css/account.css"/>
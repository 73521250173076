<template>
<div>
    <main class="form-signin w-100 m-auto">
        <div>
            <section class="success-section">
                <i class="fa-solid fa-circle-check fs-1 d-flex justify-content-center mb-4 icon"></i>
                <h1 class="h3 text-center success-header">{{ $t('paymentResultPage.success.title') }}</h1>

                <div class="d-flex justify-content-center">
                    <span class="badge bg-body-secondary text-black-50 fw-normal fs-6 mb-3">{{ $t('paymentResultPage.success.orderID') }}&nbsp;<span class="user-select-all">{{ successData.payorder_id }}</span></span>
                </div>

                <small class="fs-5 d-flex justify-content-center fw-light text-center success-text mt-1">{{ $t('paymentResultPage.success.description') }}</small>

                <div class="mx-auto rounded mt-5">
                    <h4 class="justify-content-between align-items-center">
                        <router-link v-if="successData?.payorder_id" :to="'/Account/Order/' + successData?.payorder_id" class="text-center h5 text-decoration-none">{{ $t('paymentResultPage.success.orderSummary') }}</router-link>
                        <span v-else class="text-center h5">{{ $t('paymentResultPage.success.orderSummary') }}</span>
                    </h4>
                    <ul class="list-group margin-inline-0 mb-3">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <div>
                                <h6 class="my-0"><small class="fw-light" id="quantity-text">{{ successData?.quantity }}x</small> {{ $t('productName') }}</h6>
                            </div>
                            <span class="text-body-secondary justify-content-between align-items-center py-2 text-end d-flex"><span class="badge text-bg-light me-1 rounded-pill fw-normal">{{ formatPrice(String(successData?.unit_price).replace('.', ',')) }}₺</span> {{ $store.state.price.currency }}{{ formatPrice(String(( (successData?.unit_price) / ((((successData?.total_price - successData?.kargo_price) / successData?.quantity) / $store.state.price.price.tax)) )).replace('.', ',')) }}</span>

                        </li>
                        <li class="list-group-item d-flex justify-content-between lh-sm align-items-center">
                            <div>
                                <h6 class="my-0">{{ $t('paymentResultPage.success.shipment') }}</h6>
                            </div>
                            <span class="text-body-secondary"><span class="badge text-bg-light me-1 rounded-pill fw-normal">{{ formatPrice(String(successData?.kargo_price).replace('.', ',')) }}₺</span>{{ $store.state.price.currency }}{{ formatPrice(String(cargo.base + (successData?.quantity * cargo.quantityThreshold)).replace('.', ',')) }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <span>{{ $t('paymentResultPage.success.total') }}</span>
                            <strong class="text-zerduz"><span class="badge text-bg-light me-1 rounded-pill fw-normal">{{ formatPrice(String(successData?.total_price).replace('.', ',')) }}₺</span>{{ $store.state.price.currency }}{{ formatPrice(String(( (successData?.total_price - successData?.kargo_price) / ((((successData?.total_price - successData?.kargo_price) / successData?.quantity) / $store.state.price.price.tax)) ) + cargo.base + (successData?.quantity * cargo.quantityThreshold)).replace('.', ',')) }}</strong>
                        </li>
                    </ul>
                </div>
            </section>
            <section class="error-section">
                <i class="fa-solid fa-circle-exclamation fs-1 d-flex justify-content-center mb-4 icon error-icon"></i>
                <h1 class="h3 mb-3 text-center">{{ $t('paymentResultPage.error.title') }}</h1>

                <small v-if="$t('paymentResultPage.error.description.first') == 'text'" class="fs-5 d-block justify-content-center text-center error-text">{{ $t('paymentResultPage.error.description.text') }}&nbsp;<router-link to="/Contact" class="fst-italic text-decoration-none">{{ $t('paymentResultPage.error.description.link') }}</router-link>&nbsp;{{ $t('paymentResultPage.error.description.text2') }}</small>
                <small v-else-if="$t('paymentResultPage.error.description.first') == 'link'" class="fs-5 d-block justify-content-center text-center error-text">{{ $t('paymentResultPage.error.description.text') }}&nbsp;{{ $t('paymentResultPage.error.description.text2') }}&nbsp;<router-link to="/Contact" class="fst-italic text-decoration-none">{{ $t('paymentResultPage.error.description.link') }}</router-link></small>
            </section>
        </div>

        <div class="text-center loading-section">
            <div class="spinner-border" style="width: 4rem; height: 4rem;" role="status">
                <span class="visually-hidden">{{ $t('loadingText') }}</span>
            </div>
        </div>
    </main>
</div>
</template>

<script>
import {
    checkoutResult
} from '@/helpers/api';
import $ from 'jquery';

export default {
    name: "PaymentResult",
    data() {
        return {
            token: window.localStorage.getItem('token'),
            userId: window.localStorage.getItem('userId'),
            paymentStatusMessage: '',
            status: 'loading',
            successData: {
                payorder_id: '',
                quantity: null,
                unit_price: null,
                total_price: null,
                kargo_price: null,
            },
            cargo: {
                total: 0,
                base: 5,
                quantityThreshold: 0
            }
        }
    },
    methods: {
        formatPrice(price) {
            if (price) {
                const cleanedPrice = price.replace(/[^\d,.-]/g, '');
                const floatValue = parseFloat(cleanedPrice.replace(',', '.'));

                var decimalPart = (floatValue % 1 === 0) ? '00' : (floatValue % 1).toFixed(2).split('.')[1];

                const integerPart = Math.floor(floatValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                if (decimalPart == '00') {
                    decimalPart = null;
                }
                const result = `${integerPart}${decimalPart ? ',' + decimalPart : ''}`;

                return result;
            } else {
                return 0;
            }
        },
        async fetchData() {
            const id = this.$route.query.id;
            if (id) {
                try {
                    const {
                        response
                    } = await checkoutResult(this.token, id);
                    if (response.status === 200 && response.data.status == "Odendi") {
                        this.successData.payorder_id = response.data.payment.payorder_id;
                        this.successData.quantity = response.data.orders.quantity;
                        this.successData.unit_price = response.data.orders.unit_price;
                        this.successData.total_price = response.data.orders.total_price;
                        
                        if (this.successData.kargo_price) {
                            this.successData.kargo_price = response.data.orders.kargo_price;
                        } else {
                            this.successData.kargo_price = 0;
                        }
                        
                        const cartData = localStorage.getItem('cart');
                        if (cartData) {
                            var parsedCartData = JSON.parse(cartData);
                            var cartItems = parsedCartData;
                            if ((cartItems.reduce((total, currentItem) => total + currentItem.cartQuantity, 0)) > 0) {
                                var headerCartItem = cartItems;
                                headerCartItem[0].cartQuantity = 0;
                                
                                this.$store.dispatch('changeCartItem', {
                                    cart: undefined
                                });
                                window.localStorage.removeItem('cart');
                            }
                        }

                        if (this.status === 'loading') {
                            $('.loading-section').fadeOut(function () {
                                $('.success-section').fadeIn();
                            });
                            this.status = 'success';
                        } else if (this.status === 'error') {
                            $('.error-section').fadeOut(function () {
                                $('.success-section').fadeIn();
                            });
                            this.status = 'success';
                        }
                        this.paymentStatusMessage = 'Payment has been completed.';
                    } else {
                        if (this.status === 'loading') {
                            $('.loading-section').fadeOut(function () {
                                $('.error-section').fadeIn();
                            });
                            this.status = 'error';
                        } else if (this.status === 'success') {
                            $('.success-section').fadeOut(function () {
                                $('.error-section').fadeIn();
                            });
                            this.status = 'error';
                        }
                        this.paymentStatusMessage = 'Payment status: unknown';
                    }
                } catch (error) {
                    if (this.status === 'loading') {
                        $('.loading-section').fadeOut(function () {
                            $('.error-section').fadeIn();
                        });
                        this.status = 'error';
                    } else if (this.status === 'success') {
                        $('.success-section').fadeOut(function () {
                            $('.error-section').fadeIn();
                        });
                        this.status = 'error';
                    }
                    this.paymentStatusMessage = 'Error fetching payment status';
                }
            } else {
                $('.loading-section').fadeOut(function () {
                    $('.error-section').fadeIn();
                });
                this.paymentStatusMessage = 'There is no id.';
            }
        },
    },
    async mounted() {
        this.fetchData();
    }
};
</script>

<style scoped src="../assets/css/payment-result.css"/>

<template>
<div>
    <div class="px-4 my-5 text-center">
        <h1 class="text-center mt-5 p-1" id="page-header-text">{{ $t('accountPages.header') }}</h1>
    </div>

    <div class="container py-3">
        <div class="row">
            <accountPageSidemenu />
            <div class="col-lg-9 col-md-8 col-sm-10" id="addresses-tab">
                <div class="p-2 mt-sm-2 mt-md-0 mt-lg-0 mt-2">
                    <h3 class="fw-normal">{{ $t('accountPages.address.list.header') }}</h3>
                </div>

                <div v-if="allAddresses != [] || allAddresses.length != 0">
                    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 g-4">
                        <div class="col">
                            <router-link to="/Account/Addresses/Add" class="text-decoration-none">
                                <div class="card h-100 py-5 border-dashed">
                                    <div class="card-body d-flex align-items-center justify-content-center">
                                        <h5 class="card-title text-center"><i class="fa fa-plus fa-xl m-3 opacity-25"></i><br>{{ $t('accountPages.address.list.addressElements.new') }}</h5>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div class="col opacity-0 user-select-none" v-if="allAddresses == [] || allAddresses.length == 0">
                            <div class="card h-100 border-0 shadow-sm rounded-2" :class="{ 'active-card': activeCardIndex === 1 }">
                                <div class="card-header bg-body border-0">
                                    Lorem Ipsum
                                </div>
                                <div class="card-body lh-1">
                                    <h6 class="card-title mb-3">Lorem Ipsum</h6>
                                    <p class="card-text">
                                        Lorem ipsum dolor sit amet
                                    </p>
                                    <p class="card-text">
                                        Lorem ipsum dolor sit amet
                                    </p>
                                    <p class="card-text">
                                        Lorem ipsum dolor sit amet
                                    </p>
                                    <p class="card-text">
                                        Lorem ipsum dolor sit amet
                                    </p>
                                    <p class="card-text">
                                        {{ $t('accountPages.address.list.addressElements.phoneNumber') }} 00000
                                    </p>
                                </div>
                                <div class="card-footer d-flex justify-content-between bg-body border-0 my-2">
                                    <small class="text-body-secondary address-delete"><i class="fa fa-trash me-1"></i>{{ $t('accountPages.address.list.addressElements.deleteAddressBtn') }}</small>
                                    <a class="text-decoration-none"><small class="text-body-secondary address-edit">{{ $t('accountPages.address.list.addressElements.editAddressBtn') }}</small></a>
                                </div>
                            </div>
                        </div>
                        <div v-for="address in allAddresses" :key="address.id" class="col">
                            <div class="card h-100 border-0 shadow-sm">
                                <div class="card-header border-0 d-flex justify-content-between">
                                    <span>{{ address.type == "billing" ? this.$t('accountPages.address.list.addressElements.addressType[0]') : $t('accountPages.address.list.addressElements.addressType[1]') }}</span>
                                </div>
                                <div class="card-header bg-body border-0">
                                    {{ address.address_name }}
                                </div>
                                <div class="card-body lh-1">
                                    <h6 class="card-title mb-3">{{ address.first_name }} {{ address.last_name }}</h6>
                                    <p class="card-text">
                                        {{ address.district }}
                                    </p>
                                    <p class="card-text">
                                        {{ address.address1 }}
                                    </p>
                                    <p class="card-text">
                                        {{ address.city }}/{{ address.state }} {{ address.country }} {{ address.zipcode }}
                                    </p>
                                    <p class="card-text">
                                        {{ $t('accountPages.address.list.addressElements.phoneNumber') }} {{ parseNumber(address.phone) }}
                                    </p>
                                </div>
                                <div class="card-footer d-flex justify-content-between bg-body border-0 my-2">
                                    <small class="text-body-secondary address-delete" data-bs-toggle="modal" data-bs-target="#deleteModal" @click="confirmDelete(address.type, address.id)"><i class="fa fa-trash me-1"></i>
                                        {{ $t('accountPages.address.list.addressElements.deleteAddressBtn') }}</small>
                                    <!-- <small class="text-body-secondary address-default">Varsayılan Adres Yap</small> -->
                                    <router-link :to="'/Account/Addresses/Edit/' + address.id" class="text-decoration-none"><small class="text-body-secondary address-edit">{{ $t('accountPages.address.list.addressElements.editAddressBtn') }}</small></router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="deleteAddressData != [] || deleteAddressData.length != 0">
        <div class="modal fade" id="deleteModal" role="dialog" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5 d-flex align-items-center gap-2" id="deleteModalLabel">{{ $t('accountPages.address.list.addressElements.confirmDelete') }} <span class="badge bg-secondary fw-normal">{{ deleteAddressData.address_name }}</span></h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <h6 class="card-title mb-3">{{ deleteAddressData.first_name }} {{ deleteAddressData.last_name }}
                        </h6>
                        <p class="card-text">
                            {{ deleteAddressData.district }}
                        </p>
                        <p class="card-text">
                            {{ deleteAddressData.address1 }}
                        </p>
                        <p class="card-text">
                            {{ deleteAddressData.city }}/{{ deleteAddressData.state }} {{ deleteAddressData.country }} {{ deleteAddressData.zipcode }}
                        </p>
                        <p class="card-text">
                            {{ $t('accountPages.address.list.addressElements.phoneNumber') }} {{ parseNumber(deleteAddressData.phone) }}
                        </p>
                    </div>
                    <div class="modal-footer gap-2">
                        <div class="col-lg-5 mx-auto col-sm-4 col-4 col-md-4 d-grid">
                            <button type="button" class="btn btn-light btn-block" data-bs-dismiss="modal">{{ $t('accountPages.address.list.addressElements.closeBtn') }}</button>
                        </div>
                        <div class="col-lg-5 mx-auto col-sm-4 col-4 col-md-4 d-grid">
                            <button type="button" class="btn btn-danger btn-block" @click="deleteAddress" data-bs-dismiss="modal">{{ $t('accountPages.address.list.addressElements.deleteAddressBtn') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    getAddresses,
    deleteAddress
} from "@/helpers/api";

import {
    parsePhoneNumber
} from 'libphonenumber-js';
import accountPageSidemenu from '@/components/accountPageSidemenu';

export default {
    name: "AccountAddresses",
    components: {
        accountPageSidemenu
    },
    data() {
        return {
            token: window.localStorage.getItem('token'),
            userId: window.localStorage.getItem('userId'),
            addresses: [],
            deleteIndex: null,
            deleteAddressData: []
        };
    },
    methods: {
        parseNumber(number) {
            if (number && (number != null || number != '' || number != 0)) {
                try {
                    var parsedNumber = parsePhoneNumber("+" + number);
                    return "+" + parsedNumber.countryCallingCode + " " + parsedNumber.nationalNumber;
                } catch {
                    return number;
                }
            } else {
                return null;
            }
        },
        async deleteAddress() {
            try {
                const {
                    success,
                    message
                } = await deleteAddress(this.token, this.deleteIndex);
                if (success) {

                    const billingIndex = this.addresses.data.billing_address.findIndex(address => address.id === this.deleteIndex);
                    if (billingIndex !== -1) {
                        this.addresses.data.billing_address.splice(billingIndex, 1);
                    }

                    const shippingIndex = this.addresses.data.shipping_address.findIndex(address => address.id === this.deleteIndex);
                    if (shippingIndex !== -1) {
                        this.addresses.data.shipping_address.splice(shippingIndex, 1);
                    }
                } else {
                    this.$showToast("Zerduz", message, 5000);
                }
            } catch (error) {
                this.$showToast("Zerduz", this.$t("errors.api.deleteAddress.error"), 5000);
            }
        },
        confirmDelete(address_type, index) {
            this.deleteIndex = index;
            address_type = address_type == "billing" ? "billing_address" : "shipping_address";
            const address = this.addresses.data[address_type].find(item => item.id === index);

            if (address) {
                this.deleteAddressData = address;
            }
        },
        async fetchData() {
            try {
                const {
                    success,
                    data,
                    message
                } = await getAddresses(this.token);
                if (success) {
                    this.addresses = data;
                } else {
                    this.$showToast("Zerduz", message, 5000);
                }

            } catch (error) {
                this.$showToast("Zerduz", this.$t("errors.api.getAddresses.error"), 5000);
            }
        },
    },
    computed: {
        allAddresses() {
            var addressArray = [];

            if (typeof this.addresses === "object" && this.addresses != null && this.addresses.length !== 0 && (Object.keys(this.addresses.data.billing_address).length > 0 || Object.keys(this.addresses.data.shipping_address).length > 0)) {

                for (let i = 0; i < this.addresses.data.billing_address.length; i++) {
                    const item = this.addresses.data.billing_address[i];

                    if (item && typeof item === "object" && item !== null) {
                        addressArray = addressArray.concat(item);
                    }
                }

                for (let i = 0; i < this.addresses.data.shipping_address.length; i++) {
                    const item = this.addresses.data.shipping_address[i];

                    if (item && typeof item === "object" && item !== null) {
                        addressArray = addressArray.concat(item);
                    }
                }
            }

            return addressArray;
        }
    },
    mounted() {

        this.fetchData();
    }
};
</script>

<style scoped src="../assets/css/account.css" />

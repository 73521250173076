<template>
<div>
    <div class="px-4 my-5 text-center">
        <h1 class="text-center mt-5 p-1" id="page-header-text">{{ $t('accountPages.header') }}</h1>
    </div>

    <div class="container py-3">
        <div class="row">
            <accountPageSidemenu />
            <div class="col-lg-9 col-md-8 col-sm-12" id="account-settings-tab">
                <div class="border my-2 rounded-2 shadow-sm">
                    <div class="container">
                        <div class="row p-3">
                            <ul class="nav nav-pills nav-fill gap-2 p-1 small bg-primary rounded-5 shadow-sm" id="settingsNav" role="tablist" style="--bs-nav-link-color: var(--bs-white); --bs-nav-pills-link-active-color: var(--bs-primary); --bs-nav-pills-link-active-bg: var(--bs-white);">
                                <div class="col">
                                    <li class="nav-item" role="presentation">
                                        <router-link to="/Account/Zerduz/Currency" active-class="" class="nav-link active rounded-5 fs-6" id="info-tab" type="button">{{ $t('accountPages.zerduzSettings.menu[0].title') }}</router-link>
                                    </li>
                                </div>
                            </ul>
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="pills-account-info" role="tabpanel" aria-labelledby="pills-account-info" tabindex="0">

                                    <div class="table-responsive">
                                        <table class="table table-hover table-borderless mx-auto text-center mt-3">
                                            <thead class="border-bottom">
                                                <tr>
                                                    <th scope="col" class="fw-normal">{{ $t('accountPages.zerduzSettings.currencySettings.fields[0]') }}</th>
                                                    <th scope="col" class="fw-normal">{{ $t('accountPages.zerduzSettings.currencySettings.fields[1]') }}</th>
                                                    <th scope="col" class="fw-normal">{{ $t('accountPages.zerduzSettings.currencySettings.fields[2]') }}</th>
                                                    <th scope="col" class="fw-normal">{{ $t('accountPages.zerduzSettings.currencySettings.fields[3]') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="currency in currencies" :key="currency.id">
                                                    <td class="fw-medium" scope="row">{{ currency.name }}</td>
                                                    <td class="w-25">
                                                        <div class="form-check form-switch d-flex justify-content-center">
                                                            <input class="form-check-input" type="checkbox" v-model="currency.status" role="switch" :id="'zerduzSettings-currency-status-' + currency.id">
                                                        </div>
                                                    </td>
                                                    <td class="w-25">
                                                        <div class="input-group mx-auto">
                                                            <input class="form-control" :disabled="!currency.status" type="text" v-model="currency.buy" :id="'zerduzSettings-currency-buy-' + currency.id" />
                                                        </div>
                                                    </td>
                                                    <td class="w-25">
                                                        <div class="input-group mx-auto">
                                                            <input class="form-control" :disabled="!currency.status" type="text" v-model="currency.sell" :id="'zerduzSettings-currency-sell-' + currency.id" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div id="alertbox"></div>
                                        <div class="col-6 mx-auto d-grid gap-2 mb-3">
                                            <button type="button" @click="saveCurrencies" class="btn btn-primary btn-block mt-1 w-75 mx-auto">{{ $t('accountPages.settings.changePassword.form.btn') }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    getZerduzCurrencySettings,
    updateZerduzCurrencySettings
} from "@/helpers/api";
import {
    alert
} from "@/helpers/funcs";
import accountPageSidemenu from '@/components/accountPageSidemenu';

export default {
    name: "AccountZerduzSettingsCurrency",
    components: {
        accountPageSidemenu
    },
    data() {
        return {
            token: window.localStorage.getItem('token'),
            userId: window.localStorage.getItem('userId'),
            currencies: []
        };
    },
    mounted() {
        this.getCurrencyDatas();
    },
    methods: {
        async saveCurrencies() {
            var currencies = [];

            for (var i = 0; i < this.currencies.length; i++) {
                var data = {
                    is_active: this.currencies[i].status,
                    currency_id: this.currencies[i].id,
                    currency_buy: parseFloat(this.currencies[i].buy),
                    currency_sell: parseFloat(this.currencies[i].sell)
                };
                currencies.push(data);
            }

            const {
                success,
                message
            } = await updateZerduzCurrencySettings(this.token, this.$store.state.zid, currencies);

            if (success) {
                alert("#alertbox", message, "success", "mt-3");
                this.getCurrencyDatas();
            } else {
                alert("#alertbox", message, "danger", "mt-2");
            }
        },
        async getCurrencyDatas() {
            if (this.$store.state.zid) {
                const {
                    success,
                    response
                } = await getZerduzCurrencySettings(this.token, this.$store.state.zid);

                if (success) {
                    if (response.data) {
                        if (response.data.data) {
                            if (response.data.data.user_currency) {
                                this.currencies = [];
                                for (var i = 0; i < response.data.data.user_currency.length; i++) {
                                    var findCurrency = this.$tm('accountPages.zerduzSettings.currencySettings.currencies').find(item => item.id == response.data.data.user_currency[i].currency_id);
                                    if (findCurrency) {
                                        var data = {
                                            id: response.data.data.user_currency[i].currency_id,
                                            name: findCurrency.name,
                                            status: response.data.data.user_currency[i].is_active,
                                            buy: response.data.data.user_currency[i].currency_buy,
                                            sell: response.data.data.user_currency[i].currency_sell
                                        };
                                        this.currencies.push(data);
                                    }
                                }
                            }
                        }
                    }
                }

                if (this.currencies.length == 0 || this.currencies.length != this.$tm('accountPages.zerduzSettings.currencySettings.currencies').length || this.currencies == null || this.currencies == []) {
                    this.currencies = [];
                    for (i = 0; i < this.$tm('accountPages.zerduzSettings.currencySettings.currencies').length; i++) {
                        findCurrency = this.$tm('accountPages.zerduzSettings.currencySettings.currencies')[i];
                        if (findCurrency) {
                            data = {
                                id: findCurrency.id,
                                name: findCurrency.name,
                                status: false,
                                buy: null,
                                sell: null
                            };
                            this.currencies.push(data);
                        }
                    }
                }
            }
        }
    }
};
</script>

<style scoped src="../assets/css/account.css"/>

<template>
  <div>
    <div class="container">
      <div v-if="cartItems && cartItems.length > 0 && hasCartQuantityBiggerThanZero" class="row">
        <div class="col-xl-8">
          <h4 class="justify-content-between align-items-center mb-3 mt-4"><span class="text-primary">{{ $t('cartPage.header[0]') }}</span>
          </h4>

          <div class="card shadow" v-for="(cartItem, index) in cartItems" :key="index">
            <div class="card-body">
              <div class="d-flex align-items-start pb-3">
                <div class="me-4">
                  <img src="../assets/img/zerduz-1.png" alt="" class="avatar-lg rounded">
                </div>
                <div class="flex-grow-1 align-self-center overflow-hidden">
                  <div class="row justify-content-between">
                    <div class="col-md-4">
                      <h5 class="text-truncate font-size-18 mt-3"><a href="#" class="text-dark">Zerduz</a></h5>
                      <h6 class="text-muted fw-normal">{{ $t('cartPage.item.productDescription') }}</h6>
                    </div>
                    <div class="col-md-4">
                      <div class="mt-3">
                        <p class="text-muted mb-2">{{ $t('cartPage.item.quantityLabel') }}</p>
                        <div class="d-inline-flex">
                          <input type="number" class="form-control text-center w-xl w-75" name="quantity" min="1" max="1000"
                            v-model="cartItem.cartQuantity" @input="cartQuantityInputChange">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mt-3">
                        <p class="text-muted mb-2">{{ $t('cartPage.item.totalLabel') }}</p>
                        <h5 class="mb-0 mt-2 fw-bold">
                          <span v-if="isLongText(cartItem)">
                            <del v-if="discount == true" class="text-body-secondary fw-normal">{{ $store.state.price.currency }}{{ formatPrice(String(parseInt(cartItems[0].cartQuantity) * $store.state.price.price.withoutTax).replace('.', ',')) }}</del><br v-if="discount == true">
                            <span class="text-zerduz"> {{ $store.state.price.currency }}{{ formatPrice(String(((parseInt(cartItem.cartQuantity) * $store.state.price.price.withoutTax) * 0.90)).replace('.', ',')) }}</span>
                          </span>
                          <span v-else>
                            <del v-if="discount == true" class="text-body-secondary fw-normal">{{ $store.state.price.currency }}{{
                              formatPrice(String((parseInt(cartItem.cartQuantity) * $store.state.price.price.withoutTax)).replace('.', ','))
                            }}</del>
                            <span class="text-zerduz"> {{ $store.state.price.currency }}{{ formatPrice(String((parseInt(cartItem.cartQuantity) * $store.state.price.price.withoutTax)).replace('.', ',')) }}</span>
                          </span>
                        </h5>
                        <small class="text-secondary text-opacity-75 mt-2 text-kdv">{{ $t('taxsArentIncluded') }}</small>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex-shrink-0 ms-2">
                  <ul class="list-inline mb-0 font-size-16">
                    <li class="list-inline-item">
                      <a @click="updateCartQuantity('empty')" class="text-muted px-1">
                        <i class="fa-solid fa-trash fa-xs cart-remove-btn"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <small class="text-muted">{{ $t('cartPage.item.estimatedDelivery') }}</small>
            </div>
          </div>

        </div>

        <div class="col-xl-4">
          <div class="mt-5 mt-lg-0">
            <h4 class="justify-content-between align-items-center mb-3 mt-4"><span class="text-primary">{{ $t('cartPage.header[1]') }}</span></h4>
            <div class="card shadow">
              <div class="card-body p-4 pt-2">

                <div class="table-responsive">
                  <table class="table table-borderless mb-0">
                    <tbody>
                      <tr>
                        <td>{{ $t('productName') }}</td>
                        <td class="text-end">{{ parseFloat(cartItems[0].cartQuantity) }} {{ $t('cartPage.summary.quantity') }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('cartPage.summary.productTotalLabel') }}</td>
                        <td class="text-end">{{ $store.state.price.currency }}{{ formatPrice(String(parseFloat(cartItems[0].cartQuantity) * $store.state.price.price.withoutTax).replace('.', ',')) }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('cartPage.summary.vatLabel') }}</td>
                        <td class="text-end">{{ $store.state.price.currency }}{{ formatPrice(String(parseFloat(($store.state.price.price.tax) - $store.state.price.price.withoutTax) * cartItems[0].cartQuantity).replace('.', ',')) }}</td>
                      </tr>
                      <!-- <tr>
                        <td>İndirim Kuponu <br><small class="text-zerduz">ÖRNEK</small></td>
                        <td class="text-end">- {{ $store.state.price.currency }}{{ (parseInt(cartItems[0].cartQuantity) * $store.state.price.price.withoutTax) -
                          (parseInt(cartItems[0].cartQuantity) *
                            $store.state.price.price.withoutTax) *
                          0.90 }}</td>
                      </tr> -->
                      <tr class="border-bottom">
                        <td>{{ $t('cartPage.summary.shippingLabel') }}</td>
                        <td class="text-end">{{ $store.state.price.currency }}{{ formatPrice(String(calculateCargo).replace('.', ',')) }}</td>
                      </tr>
                      <tr class="bg-light">
                        <th>{{ $t('cartPage.summary.totalLabel') }}</th>
                        <td class="text-end">
                          <span class="fw-bold text-zerduz">
                            {{ $store.state.price.currency }}{{ formatPrice(String((parseFloat(cartItems[0].cartQuantity) * $store.state.price.price.tax) + this.cargo.total).replace('.', ',')) }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- <div class="card shadow">
              <form>
                <div class="input-group">
                  <input type="text" class="form-control border-0" placeholder="İndirim Kuponu">
                  <button type="submit" class="btn btn-light border-0">Uygula</button>
                </div>
              </form>

            </div> -->
            <div class="d-flex gap-2 justify-content-center"><router-link
                class="btn btn-primary align-items-center btn-full-width btn-lg fw-medium" to="/Checkout"
                type="button">{{ $t('cartPage.summary.checkoutBtn') }}</router-link></div>
          </div>
        </div>
      </div>
      <div v-else class="row w-100 m-auto">
        <div class="text-center cart-empty">
          <h2 class=""><span class="fa-stack fa-1x mb-3"><i class="fa-solid fa-circle fa-stack-2x"
                style="color: #25252520;"></i><i
                class="fa-solid fa-basket-shopping fa-stack-1x fa-inverse d-flex justify-content-center icon"></i></span>
            <span class="">{{ $t('cartPage.emptyCart.title') }}</span>
          </h2>
          <h3><router-link to="/Purchase" class="btn btn-primary btn-lg mt-2 rounded-5 shopping-btn pe-3">{{ $t('cartPage.emptyCart.startShoppingBtn') }}<i class="fa-solid fa-arrow-right ms-2 arrow-icon"></i></router-link></h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import { incrementValue, decrementValue } from '@/helpers/funcs';
import { getCartData, updateCart } from '@/helpers/api';

export default {
  name: "CartView",
  data() {
    return {
      cartItems: [],
      token: window.localStorage.getItem('token'),
      userId: window.localStorage.getItem('userId'),
      cartData: [],
      threshold: 100,
      discount: false,
      prevQuantity: null,
      cargo: {
        total: 0,
        base: 5,
        quantityThreshold: 0
      }
    };
  },
  computed: {
    calculateCargo() {
      this.changeTotalCargoAmount(this.cargo.base + (parseInt(this.cartItems[0].cartQuantity) * this.cargo.quantityThreshold));
      return (this.cargo.base + (parseInt(this.cartItems[0].cartQuantity) * this.cargo.quantityThreshold));
    },
    totalQuantity() {
      if (this.token != null && this.userId != null) {
        if (this.cartData.data && this.cartData.data != []) {
          var cartItem = { productId: 1, cartQuantity: this.cartData.data, };
          return parseInt(cartItem.cartQuantity);
        } else {
          const cartData = localStorage.getItem('cart');
          if (cartData) {
            var parsedCartData = JSON.parse(cartData);
            return parsedCartData.reduce((total, currentItem) => {
              return parseInt(currentItem.cartQuantity);
            }, 0);
          }
        }
      } else {
        const cartData = localStorage.getItem('cart');
        if (cartData) {
          parsedCartData = JSON.parse(cartData);
          return parsedCartData.reduce((total, currentItem) => {
            return parseInt(currentItem.cartQuantity);
          }, 0);
        }
      }
      return false;
    },
    hasCartQuantityBiggerThanZero() {
      return this.cartItems.some(item => item.cartQuantity > 0);
    }
  },
  methods: {
    formatPrice(price) {
      if (price) {
        const cleanedPrice = price.replace(/[^\d,.-]/g, '');
        const floatValue = parseFloat(cleanedPrice.replace(',', '.'));

        var decimalPart = (floatValue % 1 === 0) ? '00' : (floatValue % 1).toFixed(2).split('.')[1];

        const integerPart = Math.floor(floatValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        if (decimalPart == '00') {
          decimalPart = null;
        }
        const result = `${integerPart}${decimalPart ? ',' + decimalPart : ''}`;

        return result;
        } else {
          return 0;
        }
    },
    changeTotalCargoAmount(amount) {
      this.cargo.total = amount;
      return amount;
    },
    generatePayorderID() {
      const newPayorderID = "Z" + Math.floor(Math.random() * 1000000000000).toString();
      return newPayorderID;
    },
    async updateCartQuantity(newQuantity) {
      var operation = true;
      if (newQuantity == "empty") {
        newQuantity = 0;
        operation = false;
      }

      if (this.token != null && this.userId != null) {
        const { payorder_id } = await updateCart(this.token, 1, parseInt(this.cartItems[0].cartQuantity), this.cartItems[0].payorder_id, operation);
        var cartData = window.localStorage.getItem('cart');
        cartData = cartData ? JSON.parse(cartData) : [];
        var currProductId = 1;
        var match = false;
        cartData.forEach(element => {
          if (element.productId == currProductId) {
            element.cartQuantity = String(parseInt(element.cartQuantity) + parseInt(newQuantity));
            if (operation == true) { 
              this.cartItems[0].cartQuantity = String(parseInt(element.cartQuantity) + parseInt(newQuantity));
              var headerCartItem = this.cartItems;
              headerCartItem[0].cartQuantity = 0;
              this.$store.dispatch('changeCartItem', { cart: this.headerCartItem });
            } else {
              this.cartItems = [];
              window.localStorage.removeItem('cart');
              this.$store.dispatch('changeCartItem', { cart: [] });
            }
            
            match = true;
          }
        });
        if (match == false) {
          if (operation == true) {
            var newCartItem = { productId: currProductId, cartQuantity: this.cartItems.reduce((total, currentItem) => total + currentItem.cartQuantity, 0), payorder_id: payorder_id};
            this.cartItems[0].cartQuantity = this.cartItems.reduce((total, currentItem) => total + currentItem.cartQuantity, 0);
            var headerCartItem = this.cartItems;
            headerCartItem[0].cartQuantity = 0;
            this.$store.dispatch('changeCartItem', { cart: this.headerCartItem });
            cartData.push(newCartItem);
            window.localStorage.setItem('cart', JSON.stringify(cartData));
          } else {
            this.cartItems = [];
            window.localStorage.removeItem('cart');
            this.$store.dispatch('changeCartItem', { cart: [] });
          } 
        }

      } else if (this.token == null && this.userId == null) {
        cartData = window.localStorage.getItem('cart');
        cartData = cartData ? JSON.parse(cartData) : [];
        currProductId = 1;
        match = false;
        cartData.forEach(element => {
          if (element.productId == currProductId) {
            element.cartQuantity = String(parseInt(element.cartQuantity) + parseInt(newQuantity));
            if (operation == true) {
              this.cartItems[0].cartQuantity = String(parseInt(element.cartQuantity) + parseInt(newQuantity));
              headerCartItem = this.cartItems;
              headerCartItem[0].cartQuantity = 0;
              this.$store.dispatch('changeCartItem', { cart: this.headerCartItem });
            } else {
              this.cartItems = [];
              window.localStorage.removeItem('cart');
              this.$store.dispatch('changeCartItem', { cart: [] });
            }
            
            match = true;
          }
        });
        if (match == false) {
          if (operation == true) {
            newCartItem = { productId: currProductId, cartQuantity: this.cartItems.reduce((total, currentItem) => total + currentItem.cartQuantity, 0), payorder_id: this.generatePayorderID() };
            this.cartItems[0].cartQuantity = this.cartItems.reduce((total, currentItem) => total + currentItem.cartQuantity, 0);
            headerCartItem = this.cartItems;
            headerCartItem[0].cartQuantity = 0;
            this.$store.dispatch('changeCartItem', { cart: this.headerCartItem });
            cartData.push(newCartItem);
            window.localStorage.setItem('cart', JSON.stringify(cartData));
          } else {
            this.cartItems = [];
            window.localStorage.removeItem('cart');
            this.$store.dispatch('changeCartItem', { cart: [] });
          }
        }
      }
    },
    isLongText(cartItem) {
      var text = null;
      if (this.discount == true) {
        text = `${parseInt(cartItem.cartQuantity) * this.$store.state.price.price.withoutTax} ${(parseInt(cartItem.cartQuantity) * this.$store.state.price.price.withoutTax) * 0.90}`;
      } else {
        text = `${(parseInt(cartItem.cartQuantity) * this.$store.state.price.price.withoutTax) * 0.90}`;
      }
      return text.length > this.threshold;
    },
    async cartQuantityInputChange() {
      var currentQuantityTemp = parseInt(this.cartItems[0].cartQuantity);
      var currentQuantity = parseInt(this.cartItems[0].cartQuantity);
      var previousQuantity = parseInt(this.prevQuantity);
      var operation = true;
      if (currentQuantityTemp > previousQuantity) {
        var index = currentQuantityTemp - previousQuantity;
        currentQuantity = index;
        operation = true;
      } else if (currentQuantityTemp < previousQuantity) {
        index = currentQuantityTemp - previousQuantity;
        currentQuantity = index;
        operation = false;
      } else {
        index = currentQuantityTemp;
        currentQuantity = currentQuantityTemp;
      }

      if (this.token != null && this.userId != null) {
        var payorder_idPayload = JSON.parse(localStorage.getItem('cart'))[0].payorder_id;
        if (parseInt(currentQuantity) < 0) {
          var tempCurrentQuantity = Math.abs(parseInt(currentQuantity));
        } else {
          tempCurrentQuantity = parseInt(currentQuantity);
        }
        if ((previousQuantity - currentQuantityTemp) == previousQuantity) {
          tempCurrentQuantity = 0;
        }
        const { payorder_id } = await updateCart(this.token, 1, parseInt(tempCurrentQuantity), payorder_idPayload, operation);
        var cartData = window.localStorage.getItem('cart');
        cartData = cartData ? JSON.parse(cartData) : [];
        var currProductId = 1;
        var match = false;
        cartData.forEach(element => {
          if (element.productId == currProductId) {
            element.cartQuantity = String(previousQuantity + parseInt(currentQuantity));
            this.cartItems[0].cartQuantity = String(previousQuantity + parseInt(currentQuantity));
            match = true;
          }
        });
        if (match == false) {
          var newCartItem = { productId: currProductId, cartQuantity: String(parseInt(currentQuantityTemp)), payorder_id: payorder_id };
          this.cartItems[0].cartQuantity = String(parseInt(currentQuantityTemp));
          cartData.push(newCartItem);
        }
        if (parseInt(tempCurrentQuantity) == 0) {
          this.$store.dispatch('changeCartItem', { cart: [] });
          window.localStorage.removeItem('cart');
        } else {
          this.$store.dispatch('changeCartItem', { cart: cartData });
          window.localStorage.setItem('cart', JSON.stringify(cartData));
        }
        

      } else if (this.token == null && this.userId == null) {
        cartData = window.localStorage.getItem('cart');
        cartData = cartData ? JSON.parse(cartData) : [];
        currProductId = 1;
        match = false;
        cartData.forEach(element => {
          if (element.productId == currProductId) {
            element.cartQuantity = String(previousQuantity + parseInt(currentQuantity));
            this.cartItems[0].cartQuantity = String(previousQuantity + parseInt(currentQuantity));
            match = true;
          }
        });
        if (match == false) {
          newCartItem = { productId: currProductId, cartQuantity: String(parseInt(currentQuantityTemp)), payorder_id: this.generatePayorderID() };
          this.cartItems[0].cartQuantity = String(parseInt(currentQuantityTemp));
          cartData.push(newCartItem);
        }
        if (cartData) {
          if (cartData.length > 0) {
            if (cartData[0].cartQuantity == 0) {
              this.cartItems = [];
              window.localStorage.removeItem('cart');
              this.$store.dispatch('changeCartItem', { cart: [] });
            } else {
              this.$store.dispatch('changeCartItem', { cart: cartData });
              window.localStorage.setItem('cart', JSON.stringify(cartData));
            }
          } else {
            this.$store.dispatch('changeCartItem', { cart: cartData });
            window.localStorage.setItem('cart', JSON.stringify(cartData));
          }
        }
      }
      this.prevQuantity = currentQuantityTemp;
    }
  },
  async created() {
    if (this.token != null && this.userId != null) {
      const { success, data } = await getCartData(this.token);
      if (success) {
        if (data.data) {
          if (data.data.carts != [] && data.data.carts.length > 0) {
            if (data.data.carts[0].quantity != 0 && data.data.carts[0].payorder_id != "") {
              var sessionCartData = localStorage.getItem('cart');
              if (typeof sessionCartData !== 'undefined') {
                if (sessionCartData != null && ((JSON.parse(sessionCartData)[0]) && (JSON.parse(sessionCartData)[0].cartQuantity))) {
                  var parsed = JSON.parse(sessionCartData)[0];
                  if (parsed.cartQuantity !== data.data.carts[0].quantity) {
                    cartData = [];
                    var newCartItem = { productId: 1, cartQuantity: data.data.carts[0].quantity, payorder_id: data.data.carts[0].payorder_id };
                    cartData.push(newCartItem);
                    this.$store.dispatch('changeCartItem', { cart: cartData });
                    window.localStorage.setItem('cart', JSON.stringify(cartData));
                  }
                } else {
                  var newSessionCartData = [];
                  newCartItem = { productId: 1, cartQuantity: data.data.carts[0].quantity, payorder_id: data.data.carts[0].payorder_id };
                  newSessionCartData.push(newCartItem);
                  this.$store.dispatch('changeCartItem', { cart: newSessionCartData });
                  window.localStorage.setItem('cart', JSON.stringify(newSessionCartData));
                }
              }
              var cartItem = { productId: 1, cartQuantity: data.data.carts[0].quantity, payorder_id: data.data.carts[0].payorder_id };
              this.cartItems.push(cartItem);     
            }
          }
        }
      } else {
        var cartData = localStorage.getItem('cart');
        if (this.cartData) {
          var parsedCartData = JSON.parse(cartData);
          this.cartItems = parsedCartData;
        }
      }
    } else {
      const cartData = localStorage.getItem('cart');
      if (cartData) {
        parsedCartData = JSON.parse(cartData);
        this.cartItems = parsedCartData;
      }
    }
    if (this.cartItems && this.cartItems.length > 0 && this.hasCartQuantityBiggerThanZero) {
      this.prevQuantity = this.cartItems[0].cartQuantity;
    }
  },
  async mounted() {
    $('.input-group').on('click', '#quantity-plus', function (e) {
      incrementValue(e);
    });

    $('.input-group').on('click', '#quantity-minus', function (e) {
      decrementValue(e);
    });
  }
};
</script>

  <style scoped src="../assets/css/cart.css"/>

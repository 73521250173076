<template>
<div class="col-lg-3 col-md-4 col-sm-12 gap-1">
    <div class="mt-2">
        <router-link to="/Account/Orders" class="text-decoration-none">
            <div class="list-group list-group-checkable margin-inline-0 shadow-sm">
                <input class="list-group-item-check pe-none" type="radio" :checked="isChecked(0)" name="listGroupCheckableRadios" id="listGroupCheckableRadios1" value="">
                <label class="list-group-item rounded-3 border-0" for="listGroupCheckableRadios1" style="height:65px;">
                    <i :class="$t('accountPages.sideMenu[0].icon')"></i>
                    {{ $t('accountPages.sideMenu[0].title') }}
                    <!--<span class="d-block small opacity-50 ms-4">With support text underneath t</span>-->
                </label>
            </div>
        </router-link>
    </div>
    <div class="mt-2">
        <router-link to="/Account/Settings" class="text-decoration-none">
            <div class="list-group list-group-checkable margin-inline-0 shadow-sm">
                <input class="list-group-item-check pe-none" type="radio" :checked="isChecked(1)" name="listGroupCheckableRadios" id="listGroupCheckableRadios2" value="">
                <label class="list-group-item rounded-3 border-0" for="listGroupCheckableRadios2" style="height:65px;">
                    <i :class="$t('accountPages.sideMenu[1].icon')"></i>
                    {{ $t('accountPages.sideMenu[1].title') }}
                </label>
            </div>
        </router-link>
    </div>
    <div class="mt-2">
        <router-link to="/Account/Help" class="text-decoration-none">
            <div class="list-group list-group-checkable margin-inline-0 shadow-sm">
                <input class="list-group-item-check pe-none" type="radio" :checked="isChecked(2)" name="listGroupCheckableRadios" id="listGroupCheckableRadios3" value="">
                <label class="list-group-item rounded-3 border-0" for="listGroupCheckableRadios3" style="height:65px;">
                    <i :class="$t('accountPages.sideMenu[2].icon')"></i>
                    {{ $t('accountPages.sideMenu[2].title') }}
                </label>
            </div>
        </router-link>
    </div>
    <div class="mt-2">
        <router-link to="/Account/Addresses" class="text-decoration-none">
            <div class="list-group list-group-checkable margin-inline-0 shadow-sm">
                <input class="list-group-item-check pe-none" type="radio" :checked="isChecked(3)" name="listGroupCheckableRadios" id="listGroupCheckableRadios4" value="">
                <label class="list-group-item rounded-3 border-0" for="listGroupCheckableRadios4" style="height:65px;">
                    <i :class="$t('accountPages.sideMenu[3].icon')"></i>
                    {{ $t('accountPages.sideMenu[3].title') }}
                </label>
            </div>
        </router-link>
    </div>
    <div class="mt-2" v-if="getZid">
        <router-link to="/Account/Zerduz" class="text-decoration-none">
            <div class="list-group list-group-checkable margin-inline-0 shadow-sm">
                <input class="list-group-item-check pe-none" type="radio" :checked="isChecked(4)" name="listGroupCheckableRadios" id="listGroupCheckableRadios5" value="">
                <label class="list-group-item rounded-3 border-0 pt-3 ps-4" for="listGroupCheckableRadios5" style="height:65px;">
                    <img src="../assets/img/logobim.png" width="26" class="ms-1 obim-icon">
                    <span class="ms-3">{{ $t('accountPages.sideMenu[4].title') }}</span>
                </label>
            </div>
        </router-link>
    </div>
</div>
</template>

<script>
export default {
    name: "accountPageSidemenu",
    methods: {
        isChecked(menuItem) {
            if (menuItem == 0 && String(this.$route.path).toLowerCase().includes('/account/order')) {
                return true;
            } else if (menuItem == 1 && String(this.$route.path).toLowerCase().includes('/account/settings')) {
                return true;
            } else if (menuItem == 2 && String(this.$route.path).toLowerCase().includes('/account/help')) {
                return true;
            } else if (menuItem == 3 && String(this.$route.path).toLowerCase().includes('/account/address')) {
                return true;
            } else if (menuItem == 4 && String(this.$route.path).toLowerCase().includes('/account/zerduz')) {
                return true;
            } else {
                return false;
            }
        }
    },
    computed: {
        getZid() {
            if (this.$store.state.zid) {
                return this.$store.state.zid;
            } else {
                return false;
            }
        }
    }
}
</script>

<style scoped>
.list-group-item-check:checked+.list-group-item .obim-icon {
    filter: invert(100%) brightness(100%);
}
.obim-icon {
    filter: none;
}
</style>
<template>
  <div>
    <div class="container g-4 px-md-10">
      <div class="px-4 my-5 text-center">
        <h1 class="text-center mt-5 p-1">Teslimat ve İade</h1>
      </div>
      <h3 class="mt-5 p-1">Teslimat</h3>
      <p>Teslimat
        Sipariş verdiğiniz ürün sipariş tarihinizden itibaren 1-5 iş günü içerisinde kargo firmasına teslim edilmektedir.
        Kargo firması siparişinizi bizden teslim aldıktan sonra en kısa sürede adresinize teslim edecektir.
      </p>
      <p>Siparişlerinizi ve siparişlerinizin durumunu <a href="https://www.zerduz.com/siparis-takip" class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">https://www.zerduz.com/siparis-takip</a> sayfasından takip
        edebilirsiniz.</p>
      <p>Hasarlı gönderilen ürünler size ulaştığı günden itibaren 48 saat içerisinde Kargo firmaları ile karşı ödemeli
        olarak tarafımıza teslim edilmesi gerekmektedir. 48 saat içerisinde gönderilmeyen ürün hasarlı olarak kabul
        edilmeyecektir. </p>
      <h3 class="mt-5 p-1">İade</h3>
      <p>İade edilecek ürün/ürünler ile birlikte faturanızı ve iade nedeninizi belirten açıklamanızı tek bir pakete koyun.
      </p>
      <p>Kargo firmaları ile karşı ödemeli olarak 14 gün içinde tarafımıza gönderin.</p>
      <p>Web sitesinden yaptığınız alışverişlerde iade süresi, kargonuz elinize ulaştığı andan itibaren 14 gündür.</p>
      <p>Ürün satıcı firmaya ulaştıktan sonra iade şartlarına uygunluğu kontrol edilir.</p>
      <p>Eğer ürün iade şartlarına uygun değil ise adresinize geri gönderimi sağlanır ve ücret ödemesi yapılmaz.</p>
      <p>İade edilen ücretin hesabınıza yansıma süresi bankanıza bağlıdır. Kredi kartı ile ödeme yaptıysanız bankalar
        genellikle birkaç gün içerisinde hesabınıza yatırır. Banka kartlarında ise iade ücretinin hesabınıza yansıma
        süresi daha uzun olacaktır.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeslimatveIade",
};
</script>

  <style scoped src="../assets/css/pages.css"/>


<template>
    <div class="border-top mt-5">
        <div class="container">
            <footer class="pt-5 pb-4 m-2 footer">
                <div class="row mb-3">
                    <div class="col-6 col-md-2 mb-3">
                        <!-- <h5>Şirket</h5> -->
                        <ul class="nav flex-column">
                            <li class="nav-item mb-2"><router-link to="/About"
                                    class="nav-link p-0 text-body-secondary">{{ $t('footerPage.menu[0].title') }}</router-link></li>
                            <li class="nav-item mb-2"><router-link to="/Contact"
                                    class="nav-link p-0 text-body-secondary">{{ $t('footerPage.menu[1].title') }}</router-link></li>
                            <li class="nav-item mb-2"><router-link to="/Career" class="nav-link p-0 text-body-secondary">{{ $t('footerPage.menu[2].title') }}</router-link>
                            </li>
                        </ul>
                    </div>

                    <div class="col-6 col-md-2 mb-3">
                        <!-- <h5>Section</h5> -->
                        <ul class="nav flex-column">
                            <li class="nav-item mb-2"><router-link to="/Specs" class="nav-link p-0 text-body-secondary">{{ $t('footerPage.menu[3].title') }}</router-link></li>
                            <li class="nav-item mb-2"><router-link to="/Purchase" class="nav-link p-0 text-body-secondary">{{ $t('footerPage.menu[4].title') }}</router-link></li>
                            <li class="nav-item mb-2"><router-link to="/Apps" class="nav-link p-0 text-body-secondary">{{ $t('footerPage.menu[5].title') }}</router-link></li>
                            <li class="nav-item mb-2"><router-link to="/Account"
                                    class="nav-link p-0 text-body-secondary">{{ $t('footerPage.menu[6].title') }}</router-link></li>
                        </ul>
                    </div>

                    <div class="col-6 col-md-2 mb-3">
                        <!-- <h5>Section</h5> -->
                        <ul class="nav flex-column">
                            <li class="nav-item mb-2"><router-link to="/GizlilikveCerez" class="nav-link p-0 text-body-secondary">{{ $t('footerPage.menu[7].title') }}</router-link></li>
                            <li class="nav-item mb-2"><router-link to="/MesafeliSatisSozlesmesi" class="nav-link p-0 text-body-secondary">{{ $t('footerPage.menu[8].title') }}</router-link></li>
                            <li class="nav-item mb-2"><router-link to="/TeslimatveIade" class="nav-link p-0 text-body-secondary">{{ $t('footerPage.menu[9].title') }}</router-link></li>
                                    <li class="nav-item mb-2"><router-link to="/KisiselVerilerinKorunmasi" class="nav-link p-0 text-body-secondary">{{ $t('footerPage.menu[10].title') }}</router-link></li>
                        </ul>
                    </div>
                    <div class="col-6 col-md-2">
                        <!-- <h5>Section</h5> -->
                        <ul class="nav flex-column">
                            <li class="nav-item mb-2"><router-link to="SSS" class="nav-link p-0 text-body-secondary">{{ $t('footerPage.menu[11].title') }}</router-link></li>
                            <!-- <li class="nav-item mb-2"><router-link to="#" class="nav-link p-0 text-body-secondary">Canlı Destek</router-link>
                            </li> -->
                            <!-- <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-dark-emphasis">Çağrı Merkezi:
                                    0850 000 00</a></li> -->
                        </ul>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <div class="col-sm-12 col-lg-12 d-flex align-items-center justify-content-center mb-4">
                            <div
                                class="icon-square d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                                <i class="fa-solid fa-headset text-zerduz feature-icon" style="font-size: 64px;"></i>
                            </div>
                            <div>
                                <h3 class="text-body-emphasis fw-light">{{ $t('footerPage.helpSection.label') }}</h3>
                                <div class="d-inline-flex">
                                    <h5 class="text-dark fw-normal">{{ $t('footerPage.helpSection.callUs') }}&nbsp;</h5>
                                    <a href="tel:908503406666" class="text-decoration-none text-zerduz"><h5 class="fw-semibold">0850 340 6666</h5></a>
                                </div>
                            </div>
                        </div>

                        <h6 class="mb-3 mt-4">{{ $t('footerPage.secureShopping') }}</h6>
                        <img src="../assets/img/iyzico.png" class="iyzico-footer">
                        
                    </div>
                </div>

                <div class="d-flex flex-column flex-sm-row justify-content-between pt-2 border-top align-items-center gap-2">
                    <small class="text-muted">&copy; Obim Teknoloji ve Dış Ticaret Ltd. Şti. {{ new Date().getFullYear() }}</small>
                    <router-link to="/" class="col-md-3 mx-auto link-body-emphasis text-decoration-none">
                        <img src="../assets/img/text-logo.png" alt="Zerduz" style="width: auto; height: 32px;">
                    </router-link>
                    <ul class="list-unstyled d-flex">
                        <li class="ms-2 mt-3"><a class="link-body-emphasis" target="_blank" href="https://www.instagram.com/zerduztech/"><i class="fa-brands fa-instagram"></i></a>
                        </li>
                        <li class="ms-2 mt-3"><a class="link-body-emphasis" target="_blank" href="https://www.youtube.com/@ZerduzTechnology"><i class="fa-brands fa-youtube"></i></a>
                        </li>
                        <li class="ms-2 mt-3"><a class="link-body-emphasis" target="_blank" href="#"><i class="fa-brands fa-x-twitter"></i></a>
                        </li>
                        <li class="ms-2 mt-3"><a class="link-body-emphasis" target="_blank" href="#"><i class="fa-brands fa-facebook"></i></a>
                        </li>
                        <li class="ms-2 mt-3"><a class="link-body-emphasis" target="_blank" href="#"><i class="fa-brands fa-tiktok"></i></a></li>
                    </ul>
                </div>

                <div class="col-auto d-flex justify-content-lg-end justify-content-center justify-content-md-end align-items-center">
                    <select class="py-2 rounded border-0 cursor-pointer language-selector country" id="countrySelector" v-model="this.$country" @change="saveCountry" aria-label="Zerduz country selector.">
                        <option :value="country.value" v-for="country in $tm('footerPage.countries')" :key="country.value">{{ country.country }}</option>
                    </select>
                    <label class="ms-2" for="countrySelector"><i class="fa-solid fa-earth-americas" style="font-size: 24px;"></i></label>
                </div>
                <!-- <small class="d-flex justify-content-lg-end justify-content-center justify-content-md-end"><span class="language-selector language-tr" :class="{ 'active fw-bold': this.$i18n.locale === 'tr' }" @click="changeLanguage('tr')">TR</span><span class="text-muted user-select-none mx-1">|</span><span class="language-selector language-en" :class="{ 'active fw-bold': this.$i18n.locale === 'en' }" @click="changeLanguage('en')">EN</span><span class="text-muted user-select-none mx-1">|</span><span class="language-selector language-ar" :class="{ 'active fw-bold': this.$i18n.locale === 'ar' }" @click="changeLanguage('ar')">AR</span><span class="text-muted user-select-none mx-1">|</span><span class="language-selector language-fr" :class="{ 'active fw-bold': this.$i18n.locale === 'fr' }" @click="changeLanguage('fr')">FR</span></small> -->
            </footer>
        </div>
    </div>
</template>
<script>

import { getCurrency } from '@/helpers/funcs.js';

export default {
    name: "FooterPage",
    methods: {
        async saveCountry() {
            switch (String(this.$country)) {
                case 'ae':
                    this.changeLanguage("ar");
                break;
                case 'sa':
                    this.changeLanguage("ar");
                break;
                case 'fr':
                    this.changeLanguage("fr");
                break;
                case 'gb':
                    this.changeLanguage("en");
                break;
            }

            if (String(this.$country) == 'select') {
                localStorage.removeItem('country');
            } else {
                localStorage.setItem('country', String(this.$country));
            }

            var price = await this.$getAllPrices();
            this.$store.dispatch('changePrice', { price: price });
            this.$store.dispatch('changeCurrency', { currency: getCurrency(price.currency) });
            this.$store.dispatch('changeCurrencyType', { currencyType: price.currency });

        },
        changeLanguage(language) {
            if (language) {
                this.$i18n.locale = language;
                localStorage.setItem('lang', String(language));
            } else {
                this.$i18n.locale = 'en';
                localStorage.setItem('lang', String(language));
            } 
        },
    },
}
</script>

<style scoped>
.language-selector {
    cursor: pointer;
}
.language-selector:hover {
    color: #000;
}
.language-selector.active {
    color: #000;
}
.country {
    color: rgba(33, 37, 41, 0.75);
    text-align: right;
}
.iyzico-footer {
    height: auto;
    width: 100%;
}
</style>

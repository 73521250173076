<template>
<div>
    <div class="px-4 my-5 text-center">
        <h1 class="text-center mt-5 p-1" id="page-header-text">{{ $t('accountPages.header') }}</h1>
    </div>

    <div class="container py-3">
        <div class="row">
            <accountPageSidemenu />
            <div class="col-lg-9 col-md-8 col-sm-12 orders-tab" id="orders-tab">
                <div class="border my-2 rounded-2 shadow border-0">
                    <div class="container text-center">
                        <div class="row p-3 align-items-end">
                            <div class="col-md-4 col-sm-4 col-lg-2">
                                <h4 class="fw-normal">{{ $t('accountPages.orders.header') }}</h4>
                            </div>
                            <div v-if="orders?.length > 0" class="col-md-8 col-sm-8 col-lg-4 ms-auto">
                                <div class="form-floating search-bar">
                                    <input type="text" class="form-control rounded-2" v-model="search" id="searchInput" :placeholder="$t('accountPages.orders.searchInput')">
                                    <label for="searchInput"><i class="fas fa-search"></i> &nbsp;{{ $t('accountPages.orders.searchInput') }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="filteredItems.length == 0 && (orders?.length == 0 || orders?.length == null)" class="text-center m-5 d-flex justify-content-center ">
                    <div class="row">
                        <h2 class="text-black text-opacity-75">{{ $t('accountPages.orders.emptyCart[0]') }}</h2>
                        <h3>
                            <router-link to="/Purchase" class="btn btn-primary btn-lg mt-2 rounded-5 shopping-btn pe-3 shadow-sm">{{ $t('accountPages.orders.emptyCart[1]') }}<i class="fa-solid fa-arrow-right ms-2 arrow-icon"></i></router-link>
                        </h3>
                    </div>
                </div>

                <div v-else-if="filteredItems.length == 0 && orders?.length > 0" class="text-center m-5 d-flex justify-content-center ">
                    <div class="row">
                        <h2 class="text-black text-opacity-75 text-break">{{ $t('accountPages.orders.searchText').replace('[TERM]', this.search) }}</h2>
                    </div>
                </div>

                <div v-for="order in filteredItems" :key="order.payorder_id" class="card w-100 mx-auto mt-3 rounded-2 shadow border-0">
                    <div class="card-header bg-body" style="border-bottom: 0!important;">
                        <small>{{ $t('accountPages.orders.orderDetails.orderID') }} <strong class="text-muted">{{ order.payorder_id }}</strong></small>
                        <span class="text-end text-muted"><small>{{ order.order_time }}</small></span>
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-between align-items-center d-lg-flex d-md-flex d-sm-none d-none">
                            <div class="col-2 ">
                                <img src="../assets/img/zerduz-2.png" class="img-fluid ms-4" style="width: 75%!important;" alt="">
                            </div>
                            <div class="col-4 col-sm-4 col-md-3 col-lg-3 mt-3">
                                <span class="">{{ $t('productName') }}</span>
                                <p><small class="text-muted">{{ order.quantity }} {{ $t('accountPages.orders.orderDetails.quantity') }}</small></p>
                            </div>
                            <div class="col-3 col-sm-2 col-md-3 col-lg-3 mt-3 ms-lg-5">
                                <span class="">
                                    <span v-if="order.status === $t('accountPages.orders.orderDetails.orderStatus[0].key')" class="text-decoration-none order-status-text">
                                       <i class="fa-solid fa-circle-check text-success px-2"></i> {{ $t('accountPages.orders.orderDetails.orderStatus[0].value') }}
                                        </span>
                                    <span v-else-if="order.status === $t('accountPages.orders.orderDetails.orderStatus[1].key')" class="text-decoration-none order-status-text">
                                        <i class="fa-solid fa-circle-exclamation text-danger px-2"></i> {{ $t('accountPages.orders.orderDetails.orderStatus[1].value') }}
                                    </span>
                                    <span v-else-if="order.status === $t('accountPages.orders.orderDetails.orderStatus[2].key')" class="text-decoration-none order-status-text">
                                        <i class="fa-solid fa-circle-exclamation text-danger"></i> {{ $t('accountPages.orders.orderDetails.orderStatus[2].value') }}
                                    </span>
                                    <a v-else-if="order.status === $t('accountPages.orders.orderDetails.orderStatus[3].key')" href="#" class="text-decoration-none order-status-text">
                                        <i class="fa-solid fa-truck text-info px-2"></i> {{ $t('accountPages.orders.orderDetails.orderStatus[3].value') }}
                                    </a>
                                    <a v-else-if="order.status === $t('accountPages.orders.orderDetails.orderStatus[4].key')" href="#" class="text-decoration-none order-status-text">
                                        <i class="fa-solid fa-check text-success px-2"></i> {{ $t('accountPages.orders.orderDetails.orderStatus[4].value') }}
                                    </a>
                                    <a v-else-if="order.status === $t('accountPages.orders.orderDetails.orderStatus[5].key')" href="#" class="text-decoration-none order-status-text">
                                        <i class="fa-solid fa-square-check text-success px-2"></i> {{ $t('accountPages.orders.orderDetails.orderStatus[5].value') }}
                                    </a>
                                    <a v-else-if="order.status === $t('accountPages.orders.orderDetails.orderStatus[6].key')" href="#" class="text-decoration-none order-status-text">
                                        <i class="fa-solid fa-reply text-danger px-2"></i> {{ $t('accountPages.orders.orderDetails.orderStatus[6].value') }}
                                    </a>
                                </span>
                            </div>
                            <div class="col-3 col-sm-3 col-md-2 col-lg-2 text-center ms-lg-5 mt-3">
                                <span class="text-zerduz fw-bold">{{ formatPrice(String(( (order.total_price - order.kargo_price) / ((((order.total_price - order.kargo_price) / order.quantity) / $store.state.price.price.tax)) ) + cargo.base + (order?.quantity * cargo.quantityThreshold)).replace('.', ',')) }}$</span>
                            </div>
                        </div>

                        <div class="row align-items-center gap-4 d-lg-none d-md-none d-sm-flex d-flex">
                            <div class="col-12">
                                <img src="../assets/img/zerduz-2.png" class="img-fluid ms-4" style="width: 25%!important;" alt="">
                                <span class="">{{ $t('productName') }} <small class="text-muted">({{ order.quantity }} {{ $t('accountPages.orders.orderDetails.quantity') }})</small></span>
                            </div>
                            <div class="col-12 justify-content-between d-flex">
                                <span class="">
                                    <span v-if="order.status === $t('accountPages.orders.orderDetails.orderStatus[0].key')" class="text-decoration-none order-status-text">
                                       <i class="fa-solid fa-circle-check text-success px-2"></i> {{ $t('accountPages.orders.orderDetails.orderStatus[0].value') }}
                                        </span>
                                    <span v-else-if="order.status === $t('accountPages.orders.orderDetails.orderStatus[1].key')" class="text-decoration-none order-status-text">
                                        <i class="fa-solid fa-circle-exclamation text-danger px-2"></i> {{ $t('accountPages.orders.orderDetails.orderStatus[1].value') }}
                                    </span>
                                    <span v-else-if="order.status === $t('accountPages.orders.orderDetails.orderStatus[2].key')" class="text-decoration-none order-status-text">
                                        <i class="fa-solid fa-circle-exclamation text-danger"></i> {{ $t('accountPages.orders.orderDetails.orderStatus[2].value') }}
                                    </span>
                                    <a v-else-if="order.status === $t('accountPages.orders.orderDetails.orderStatus[3].key')" href="#" class="text-decoration-none order-status-text">
                                        <i class="fa-solid fa-truck-moving text-info px-2"></i> {{ $t('accountPages.orders.orderDetails.orderStatus[3].value') }}
                                    </a>
                                    <a v-else-if="order.status === $t('accountPages.orders.orderDetails.orderStatus[4].key')" href="#" class="text-decoration-none order-status-text">
                                        <i class="fa-solid fa-check text-success px-2"></i> {{ $t('accountPages.orders.orderDetails.orderStatus[4].value') }}
                                    </a>
                                    <a v-else-if="order.status === $t('accountPages.orders.orderDetails.orderStatus[5].key')" href="#" class="text-decoration-none order-status-text">
                                        <i class="fa-solid fa-square-check text-success px-2"></i> {{ $t('accountPages.orders.orderDetails.orderStatus[5].value') }}
                                    </a>
                                    <a v-else-if="order.status === $t('accountPages.orders.orderDetails.orderStatus[6].key')" href="#" class="text-decoration-none order-status-text">
                                        <i class="fa-solid fa-reply text-danger px-2"></i> {{ $t('accountPages.orders.orderDetails.orderStatus[6].value') }}
                                    </a>
                                </span>
                                <span class="text-zerduz fw-bold">{{ formatPrice(String(( (order.total_price - order.kargo_price) / ((((order.total_price - order.kargo_price) / order.quantity) / $store.state.price.price.tax)) ) + cargo.base + (order?.quantity * cargo.quantityThreshold)).replace('.', ',')) }}$</span>
                            </div>
                        </div>
                    </div>

                    <div class="card-footer text-body-secondary p-0" style="border-top: 0!important;">
                        <router-link class="btn btn-light w-100 rounded-0 btn-sm" role="button" :to="'/Account/Order/' + order.payorder_id">{{ $t('accountPages.orders.orderDetails.details') }}</router-link>
                    </div>
                </div>
                <nav v-if="(filteredItems != 0 || filteredItems.length != 0) && orders?.length != 0 && orders.length > itemsPerPage" class="d-flex justify-content-center mt-4"> 
                    <ul class="pagination">
                        <li class="page-item"><a class="page-link text-secondary text-opacity-50 border-0" @click="changePage(currentPage - 1)" href="#" :class="currentPage === 0 ? 'disabled' : ''" aria-label="prev"><i class="fa-solid fa-chevron-left fa-2xs"></i></a></li>
                        <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber" @click="changePage(pageNumber - 1)"><a class="page-link border-0" :class="(pageNumber) === (currentPage + 1) ? 'text-zerduz text-glow-zerduz-25' : 'text-secondary text-opacity-50'" href="#">{{ pageNumber }}</a></li>
                        <li class="page-item"><a class="page-link text-secondary text-opacity-50 border-0" @click="changePage(currentPage + 1)" href="#" :class="currentPage === (totalPages - 1) ? 'disabled' : ''" aria-label="next"><i class="fa-solid fa-chevron-right fa-2xs"></i></a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    getOrders
} from '@/helpers/api';
import accountPageSidemenu from '@/components/accountPageSidemenu';

export default {
    name: "AccountOrders",
    components: {
        accountPageSidemenu
    },
    data() {
        return {
            token: window.localStorage.getItem('token'),
            userId: window.localStorage.getItem('userId'),
            search: '',
            orders: [],
            cargo: {
                total: 0,
                base: 5,
                quantityThreshold: 0
            },
            currentPage: 0,
            itemsPerPage: 5,
        };
    },
    methods: {
        changePage(pageNumber) {
            this.currentPage = pageNumber;
        },
        formatPrice(price) {
           if (price) {
                const cleanedPrice = price.replace(/[^\d,.-]/g, '');
                const floatValue = parseFloat(cleanedPrice.replace(',', '.'));

                var decimalPart = (floatValue % 1 === 0) ? '00' : (floatValue % 1).toFixed(2).split('.')[1];

                const integerPart = Math.floor(floatValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                if (decimalPart == '00') {
                    decimalPart = null;
                }
                const result = `${integerPart}${decimalPart ? ',' + decimalPart : ''}`;

                return result;
            } else {
                return 0;
            }
        },
         toggleIcon(order) {
            order.rotateIcon = !order.rotateIcon;
        },
        toggleOrderDetails(order) {
            order.expanded = !order.expanded;
        },
        async fetchData() {
            try {
                const {
                    success,
                    data,
                    message
                } = await getOrders(this.token);
                if (success) {
                    this.orders = data;
                    if (this.orders && this.orders.length > 0) {
                        this.orders.forEach(item => {
                            if (item) {
                                const index = this.orders.indexOf(item);
                                if (index > -1) {
                                    this.orders[index].order_time = this.formatDate(item.order_time);
                                    this.orders[index].product_name = this.$t('productName');
                                    this.orders[index].total_price_dollar = this.formatPrice(String(( (this.orders[index].total_price - this.orders[index].kargo_price) / ((((this.orders[index].total_price - this.orders[index].kargo_price) / this.orders[index].quantity) / this.$store.state.price.price.tax)) ) + this.cargo.base + (this.orders[index].quantity * this.cargo.quantityThreshold)).replace('.', ','));
                                    switch (this.orders[index].status) {
                                        case this.$t('accountPages.orders.orderDetails.orderStatus[0].key'):
                                            this.orders[index].status_text = this.$t('accountPages.orders.orderDetails.orderStatus[0].value')
                                        break;
                                        case this.$t('accountPages.orders.orderDetails.orderStatus[1].key'):
                                            this.orders[index].status_text = this.$t('accountPages.orders.orderDetails.orderStatus[1].value')
                                        break;
                                        case this.$t('accountPages.orders.orderDetails.orderStatus[2].key'):
                                            this.orders[index].status_text = this.$t('accountPages.orders.orderDetails.orderStatus[2].value')
                                        break;
                                        case this.$t('accountPages.orders.orderDetails.orderStatus[3].key'):
                                            this.orders[index].status_text = this.$t('accountPages.orders.orderDetails.orderStatus[3].value')
                                        break;
                                        case this.$t('accountPages.orders.orderDetails.orderStatus[4].key'):
                                            this.orders[index].status_text = this.$t('accountPages.orders.orderDetails.orderStatus[4].value')
                                        break;
                                        case this.$t('accountPages.orders.orderDetails.orderStatus[5].key'):
                                            this.orders[index].status_text = this.$t('accountPages.orders.orderDetails.orderStatus[5].value')
                                        break;
                                        case this.$t('accountPages.orders.orderDetails.orderStatus[6].key'):
                                            this.orders[index].status_text = this.$t('accountPages.orders.orderDetails.orderStatus[6].value')
                                        break;
                                    }
                                    this.orders[index].quantity_text = String(this.orders[index].quantity) + " " + this.$t('accountPages.orders.orderDetails.quantity');
                                    delete this.orders[index].DeletedAt;
                                    delete this.orders[index].UpdatedAt;
                                }
                            }
                        });
                    }
                } else {
                    this.$showToast("Zerduz", message, 5000);
                }
            } catch (error) {
                this.$showToast("Zerduz", this.$t("errors.api.getOrders.error"), 5000);
            }
        },
        formatDate(sourceDate) {
            const date = new Date(sourceDate);
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: false,
                timeZone: 'Europe/Istanbul'
            };

            return date.toLocaleString(this.$i18n.locale, options);
        },
        parseDate(dateString) {
            const parts = dateString.split(/[-T:.Z]/);

            const year = parseInt(parts[0]);
            const month = parseInt(parts[1]) - 1;
            const day = parseInt(parts[2]);
            const hours = parseInt(parts[3] || 0);
            const minutes = parseInt(parts[4] || 0);
            const seconds = parseInt(parts[5] || 0);
            const milliseconds = parseInt(parts[6] || 0);

            const date = new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds));

            return isNaN(date.getTime()) ? null : date;
        }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.orders.length / this.itemsPerPage);
        },
        filteredItems() {
            const startIndex = (this.currentPage) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            if (this.orders && this.orders.length > 0) {
            const filtered = this.orders.filter(item =>
                Object.values(item).some(
                prop =>
                    prop &&
                    prop.toString().toLowerCase().includes(this.search.toLowerCase())
                )
            );

            const sortedFiltered = filtered.sort((a, b) => {
                const dateA = this.parseDate(a.order_time);
                const dateB = this.parseDate(b.order_time);
                if (dateA && dateB) {
                    return dateB - dateA;
                } else if (dateA === null && dateB === null) {
                    return 0;
                } else if (dateA === null) {
                    return 1;
                } else {
                    return -1;
                }
            });

            return sortedFiltered.reverse().slice(startIndex, endIndex);
            } else {
                return [];
            }
        }
    },
    mounted() {
        this.fetchData();
    }
};
</script>

<style scoped src="../assets/css/account.css"/>

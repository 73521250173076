<template>
    <div>
        <div class="px-4 my-5 text-center">
            <h1 class="text-center mt-5 p-1" id="page-header-text">{{ $t('accountPages.header') }}</h1>
        </div>

        <div class="container py-3">
            <div class="row">
                <accountPageSidemenu />
                <div class="col-lg-9 col-md-8 col-sm-10" id="help-tab">
                    <div class="accordion mt-2" id="accordionExample">
                        <div class="accordion-item shadow-sm border-0 border-bottom" v-for="(element, index) in $tm('accountPages.help.helpElements')" :key="index">
                            <h2 class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    :data-bs-target="'#' + element.id" aria-expanded="true" :aria-controls="element.id">
                                    {{ element.title }}
                                </button>
                            </h2>
                            <div :id="element.id" class="accordion-collapse collapse"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body" v-html="element.description">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import accountPageSidemenu from '@/components/accountPageSidemenu';

export default {
    name: "AccountHelp",
    components: {
        accountPageSidemenu
    },
};
</script>

<style scoped src="../assets/css/account.css" />
<template>
  <div>
    <main class="form-signin w-100 m-auto">
      <form @submit.prevent="requestPasswordReset" class="validation" id="reset-password-form" novalidate>
        <i class="fa-solid fa-lock fs-1 d-flex justify-content-center mb-4 icon"></i>
        <h1 class="h3 mb-3 text-center">{{ $t('resetPasswordPage.header') }}</h1>

        <small class="text-muted d-flex justify-content-center text-center">{{ $t('resetPasswordPage.description') }}</small>

        <div class="form-floating my-3">
          <input type="email" class="form-control" id="mail" v-model="formData.mail" :placeholder="$t('resetPasswordPage.form.mail')">
          <label for="mail">{{ $t('resetPasswordPage.form.mail') }}</label>
        </div>

        <button class="w-100 btn btn-dark" type="submit">
          <span class="resetpassword-button-text">{{ $t('resetPasswordPage.form.btn') }}</span>
          <div class="btn-loading">
            <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <span class="visually-hidden" role="status">{{ $t('loadingText') }}</span>
          </div>
        </button>
      </form>
      <section class="success-section">
        <i class="fa-solid fa-envelope-circle-check fs-1 d-flex justify-content-center mb-4 icon"></i>
        <h1 class="h3 mb-3 text-center">{{ $t('resetPasswordPage.success.title') }}</h1>

        <small class="fs-5 d-flex justify-content-center text-center">{{ $t('resetPasswordPage.success.description') }}</small>
      </section>
    </main>
  </div>
</template>


<script>
import $ from "jquery";
import { requestPasswordReset } from "@/helpers/api";
import { alert } from "@/helpers/funcs";

export default {
  name: "ResetPassword",
  data() {
    return {
      formData: {
        mail: '',
      },
    };
  },
  methods: {
    async requestPasswordReset() {
      $('.resetpassword-button-text').fadeOut(async () => {
        $('.btn-loading').fadeIn();
        const { success, message } = await requestPasswordReset(this.formData.mail);
        if (success == false) {
          $('.btn-loading').fadeOut(function () {
            $('.resetpassword-button-text').fadeIn();
          });
          alert("#reset-password-form", message, success ? "success" : "danger", "mt-2");
        } else if (success == true) {
          var form = $('form');
          var successSection = $('.success-section');
          var formHeight = form.outerHeight();
          $('form').fadeOut(function () {
            successSection.css({ height: formHeight });
            $('.success-section').fadeIn();
          });
        }
      });
    },
  },
};
</script>

  <style scoped src="../assets/css/reset-password.css"/>

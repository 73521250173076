<template>
  <div>
    <div class="container g-4 px-md-10">
      <div class="px-4 my-5 text-center">
        <h1 class="text-center mt-5 p-1">OBİM TEKNOLOJİ VE DIŞ TİCARET LİMİTED
          ŞİRKETİ İNTERNET SİTESİ GİZLİLİK ÇEREZ POLİTİKASI</h1>
      </div>
      <p>Obim Teknoloji ve Dış Tic. LTD. ŞTİ. (“<strong>ZERDUZ</strong>” veya “<strong>Şirket</strong>”) tarafından
        işletilen işlettiği <a href="https://www.zerduz.com"
          class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">www.zerduz.com</a>
        internet sitesini ziyaret edenlerin (“<strong>Veri Sahibi</strong>”) gizliliğini korumak Şirketimizin önde gelen
        ilkelerindendir.
        İnternet Sitesi Gizlilik Politikası (“<strong>Politika</strong>”) ile; (1) Veri Sahiplerinin kişisel verilerinin
        işlenmesi, (2)
        Çerez Politikası ve (3) İnternet Sitesi Gizlilik Politikası’nın yürürlüğü hakkında açıklamalar yapılmaktadır.</p>
      <h3>1. VERİ SAHİPLERİNİN KİŞİSEL VERİLERİNİN İŞLENMESİ</h3>
      <p>İnternet sitemizi ziyaret etmeniz sırasında elde edilen kişisel veriler Zerduz tarafından 6698 sayılı Kişisel
        Verilerin Korunması Kanunu (“<strong>Kanun</strong>”) uyarınca aşağıda açıklanan kapsamda işlenebilecektir.
        Kişisel verilerinizin
        işlenmesine ilişkin detaylı bilgilere <a href="https://www.zerduz.com"
          class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">OBİM TEKNOLOJİ VE
          DIŞ TİCARET LİMİTED ŞİRKETİ Kişisel Verilerin Korunması ve
          İşlenmesi Politikası</a>’ndan ulaşabilirsiniz.</p>
      <h4>KİŞİSEL VERİLERİNİZİN İŞLENME AMACI</h4>
      <p>İnternet Sitemizi ziyaret etmeniz dolayısıyla elde edilen kişisel verileriniz, alışveriş yapmanız durumunda
        faturalandırma ve ürün teslim süreçlerinin yürütülmesi, ürün takip et özelliğini kullanmanız durumunda ise ilgili
        ürün hakkında talep ettiğiniz bilgilendirmenin yapılması kapsamında ve bunun yanı sıra aşağıda sıralanan amaçlarla
        Zerduz tarafından KVK Kanunu’nun 5. ve 6. maddelerine uygun olarak işlenebilecektir:
      </p>
      <ul>
        <li>Şirket’in insan kaynakları politikaları ve süreçlerinin planlanması ve icra edilmesi,
        </li>
        <li>Şirket tarafından sunulan ürün ve hizmetlerden ilgili kişileri faydalandırmak için gerekli çalışmaların iş
          birimlerimiz tarafından yapılması ve ilgili iş süreçlerinin yürütülmesi,
        </li>
        <li>Şirket tarafından sunulan ürün ve hizmetlerin ilgili kişilerin beğeni, kullanım alışkanlıkları ve
          ihtiyaçlarına
          göre özelleştirilerek ilgili kişilere önerilmesi ve tanıtılması için gerekli olan aktivitelerin planlanması ve
          icrası,
        </li>
        <li>Şirket tarafından yürütülen ticari faaliyetlerin gerçekleştirilmesi için ilgili iş birimlerimiz tarafından
          gerekli çalışmaların yapılması ve buna bağlı iş süreçlerinin yürütülmesi,
        </li>
        <li>Şirket’in ticari ve iş stratejilerinin planlanması ve icrası,
        </li>
        <li>Şirket’in ve Şirket’le iş ilişkisi içerisinde olan ilgili kişilerin hukuki, teknik ve ticari-iş güvenliğinin
          temini.
        </li>
      </ul>
      <p>Şirketimiz ayrıca; kişisel verilerinizi talep etmeniz halinde üyelik kaydının gerçekleştirilmesi, sizleri
        kampanyalarımızdan bildirilmesini sağlamak amacıyla da işleyebilecektir.</p>
      <h4>KİŞİSEL VERİLERİNİZİN AKTARILDIĞI TARAFLAR VE AKTARIM AMACI</h4>
      <p>İnternet sitemizi ziyaret etmeniz dolayısıyla elde edilen kişisel verileriniz, kişisel verilerinizin işlenme
        amaçları doğrultusunda, iş ortaklarımıza, tedarikçilerimize kanunen yetkili kamu kurumlarına ve özel kişilere KVK
        Kanunu’nun 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları dahilinde aktarılabilecektir.
      </p>
      <h4>KİŞİSEL VERİLERİNİZİN TOPLANMA YÖNTEMİ VE HUKUKİ SEBEBİ</h4>
      <p>Kimliğinizi belirli ya da belirlenebilir kılan her türlü bilgi “kişisel veri”dir. İnternet sitemizi ziyaretiniz
        kapsamında kişisel verileriniz Kanun’da yer alan veri işleme şartlarına uygun olarak İnternet Sitemizi ziyaret
        etmeniz dolayısıyla teknik iletişim dosyaları olan çerezler (cookies) vasıtasıyla toplanmaktadır.</p>
      <h4>VERİ SAHİPLERİNİN HAKLARI</h4>
      <p>Kişisel veri sahibi olarak Kanun’un 11. maddesi uyarınca aşağıdaki haklara sahip olduğunuzu bildiririz:</p>
      <ul>
        <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme,</li>
        <li>Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</li>
        <li>Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
        <li>Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,</li>
        <li>Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu
          kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
        <li>Kanun’a ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren
          sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda
          yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
        <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun
          ortaya çıkması durumunda buna itiraz etme,</li>
        <li>Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın
          giderilmesini talep etme.</li>
      </ul>
      <p>Yukarıda sıralanan haklarınıza yönelik başvurularınızı, <a href="https://www.zerduz.com"
          class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">OBİM TEKNOLOJİ VE
          DIŞ TİCARET LİMİTED ŞİRKETİ Veri Sahibi
          Başvuru Formu</a>’nu doldurarak Şirketimize iletebilirsiniz. Talebinizin niteliğine göre en kısa sürede ve en
        geç otuz
        gün içinde başvurularınız ücretsiz olarak sonuçlandırılacaktır; ancak işlemin ayrıca bir maliyet gerektirmesi
        halinde Kişisel Verileri Koruma Kurulu tarafından belirlenecek tarifeye göre tarafınızdan ücret talep
        edilebilecektir.</p>
      <h4>VERİ SAHİPLEİRNİN KİŞİSEL VERİLERİNİN GÜVENLİĞİNİN SAĞLANMASINA YÖNELİK ALINAN TEDBİRLER</h4>
      <p>Şirketimizin kişisel verilerinin korunmasına ve veri güvenliğinin sağlanmasına verdiği önem doğrultusunda,
        Kanun’un 12. maddesine uygun olarak, Şirketimiz tarafından veri güvenliğinin sağlanması için,</p>
      <ul>
        <li>Kişisel verilerin hukuka aykırı olarak işlenmesini önlemek,</li>
        <li>Kişisel verilere hukuka aykırı olarak erişilmesini önlemek,</li>
        <li>Kişisel verilerin muhafazasını sağlamak,</li>
      </ul>
      <p>amacıyla uygun güvenlik düzeyini temin etmeye yönelik gerekli her türlü teknik ve idari tedbirleri almaktadır.
      </p>
      <h3>2. ÇEREZ POLİTİKASI</h3>
      <p>İnternet sitemizden en verimli şekilde faydalanabilmeniz ve kullanıcı deneyiminizi geliştirebilmek için Çerez
        kullanıyoruz. Çerez kullanılmasını tercih etmezseniz tarayıcınızın ayarlarından Çerezleri silebilir ya da
        engelleyebilirsiniz. Ancak bunun internet sitemizi kullanımınızı etkileyebileceğini hatırlatmak isteriz.
        Tarayıcınızdan Çerez ayarlarınızı değiştirmediğiniz sürece bu sitede çerez kullanımını kabul ettiğinizi
        varsayacağız.</p>
      <h4>ÇEREZ NEDİR VE NEDEN KULLANILMAKTADIR?</h4>
      <p>Çerezler, ziyaret ettiğiniz internet siteleri tarafından tarayıcılar aracılığıyla cihazınıza veya ağ sunucusuna
        depolanan küçük metin dosyalarıdır.
        İnternet Sitemizde çerez kullanılmasının başlıca amaçları aşağıda sıralanmaktadır:
      </p>
      <ul>
        <li>İnternet sitesinin işlevselliğini ve performansını arttırmak yoluyla sizlere sunulan hizmetleri geliştirmek,
        </li>
        <li>İnternet Sitesini iyileştirmek ve İnternet Sitesi üzerinden yeni özellikler sunmak ve sunulan özellikleri
          sizlerin tercihlerine göre kişiselleştirmek;</li>
        <li>İnternet Sitesinin, sizin ve Şirketimizin hukuki ve ticari güvenliğinin teminini sağlamak.
        </li>
      </ul>
      <table class="table table-light table-striped">
        <thead>
          <tr>
            <th scope="col" class="text-center">İNTERNET SİTEMİZDE KULLANILAN ÇEREZ TÜRLERİ</th>
            <th scope="col" class="text-center">İNTERNET SİTEMİZDE KULLANILAN ÇEREZLER</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">Oturum Çerezleri</td>
            <td>Oturum çerezleri ziyaretçilerimizin İnternet Sitesini ziyaretleri süresince kullanılan, tarayıcı
              kapatıldıktan sonra silinen geçici çerezlerdir.</td>
          </tr>
          <tr>
            <td scope="row">(Session Cookies)</td>
            <td>Bu tür çerezlerin kullanılmasının temel amacı ziyaretiniz süresince İnternet Sitesinin düzgün bir biçimde
              çalışmasının teminini sağlamaktır.
              Örneğin; birden fazla sayfadan oluşan çevrimiçi formları doldurmanızın sağlanmaktadır.
            </td>
          </tr>
          <tr>
            <td scope="row">Kalıcı Çerezler</td>
            <td>Kalıcı çerezler İnternet Sitesinin işlevselliğini artırmak, ziyaretçilerimize daha hızlı ve iyi bir hizmet
              sunmak amacıyla kullanılan çerez türleridir.</td>
          </tr>
          <tr>
            <td scope="row">(Persistent Cookies)</td>
            <td>Bu tür çerezler tercihlerinizi hatırlamak için kullanılır ve tarayıcılar vasıtasıyla cihazınızda
              depolanır.</td>
          </tr>
          <tr>
            <td scope="row"></td>
            <td>
              Kalıcı çerezlerin bazı türleri; İnternet Sitesini kullanım amacınız gibi hususlar göz önünde bulundurarak
              sizlere özel öneriler sunulması için kullanılabilmektedir.
            </td>
          </tr>
          <tr>
            <td scope="row"></td>
            <td>Kalıcı çerezler sayesinde İnternet Sitemizi aynı cihazla tekrardan ziyaret etmeniz durumunda, cihazınızda
              İnternet Sitemiz tarafından oluşturulmuş bir çerez olup olmadığı kontrol edilir ve var ise, sizin siteyi
              daha önce ziyaret ettiğiniz anlaşılır ve size iletilecek içerik bu doğrultuda belirlenir ve böylelikle
              sizlere daha iyi bir hizmet sunulur.</td>
          </tr>
          <tr>
            <td scope="row">Teknik Çerezler (Technical Cookies)</td>
            <td>Teknik çerezler ile internet sitesinin çalışmasının sağlanmakta, internet sitesinin çalışmayan sayfaları
              ve alanları tespit edilmektedir.</td>
          </tr>
          <tr>
            <td scope="row">Otantikasyon Çerezleri
              (Authentication Cookies)
            </td>
            <td>Ziyaretçiler, şifrelerini kullanarak internet sitesine giriş yapmaları durumunda, bu tür çerezler ile,
              ziyaretçinin internet sitesinde ziyaret ettiği her bir sayfada site kullanıcısı olduğu belirlenerek,
              kullanıcının her sayfada şifresini yeniden girmesi önlenir.</td>
          </tr>
          <tr>
            <td scope="row">Flash Çerezleri
              (Flash Cookies)
            </td>
            <td>İnternet sitesinde yer alan görüntü veya ses içeriklerini etkinleştirmek için kullanılan çerez türleridir.
            </td>
          </tr>
          <tr>
            <td scope="row">Kişiselleştirme Çerezleri
              (Customization Cookies)
            </td>
            <td>Kullanıcıların tercihlerini farklı internet sitesinin farklı sayfalarını ziyarette de hatırlamak için
              kullanılan çerezlerdir. Örneğin, seçmiş olduğunuz dil tercihinizin hatırlanması.</td>
          </tr>
          <tr>
            <td scope="row">Analitik Çerezler
              (Analytical Cookies)
            </td>
            <td>Analitik çerezler ile internet sitesini ziyaret edenlerin sayıları, internet sitesinde görüntülenen
              sayfaların tespiti, internet sitesi ziyaret saatleri, internet sitesi sayfaları kaydırma hareketleri gibi
              analitik sonuçların üretimini sağlayan çerezlerdir.</td>
          </tr>
        </tbody>
      </table>
      <h4>ÇEREZLERİN KULLANIMI VERİ SAHİPLERİ TARAFINDAN ENGELLENEBİLİR Mİ?</h4>
      <p>Tarayıcınızın ayarlarını değiştirerek çerezlere ilişkin tercihlerinizi kişiselleştirme imkanına sahipsiniz.</p>
      <table class="table table-light table-striped">
        <tbody>
          <tr>
            <td scope="row">Adobe Analytics</td>
            <td><a href="https://www.adobe.com/uk/privacy/opt-out.html"
                class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">https://www.adobe.com/uk/privacy/opt-out.html</a>
            </td>
          </tr>
          <tr>
            <td scope="row">AOL</td>
            <td><a href="https://help.aol.com/articles/restore-security-settings-and-enable-cookie-settings-on-browser"
                class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">https://help.aol.com/articles/restore-security-settings-and-enable-cookie-settings-on-browser</a>
            </td>
          </tr>
          <tr>
            <td scope="row">Google Adwords</td>
            <td><a href="https://support.google.com/ads/answer/2662922?hl=en"
                class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">https://support.google.com/ads/answer/2662922?hl=en</a>
            </td>
          </tr>
          <tr>
            <td scope="row">Google Analytics</td>
            <td><a href="https://tools.google.com/dlpage/gaoptout"
                class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">https://tools.google.com/dlpage/gaoptout</a>
            </td>
          </tr>
          <tr>
            <td scope="row">Google Chrome</td>
            <td><a href="http://www.google.com/support/chrome/bin/answer.py?hl=en&answer=95647"
                class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">http://www.google.com/support/chrome/bin/answer.py?hl=en&answer=95647</a>
            </td>
          </tr>
          <tr>
            <td scope="row">Internet Explorer</td>
            <td><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies</a>
            </td>
          </tr>
          <tr>
            <td scope="row">MozillaFirefox</td>
            <td><a href="http://support.mozilla.com/en-US/kb/Cookies"
                class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">http://support.mozilla.com/en-US/kb/Cookies</a>
            </td>
          </tr>
          <tr>
            <td scope="row">Opera</td>
            <td><a href="http://www.opera.com/browser/tutorials/security/privacy/"
                class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">http://www.opera.com/browser/tutorials/security/privacy/</a>
            </td>
          </tr>
          <tr>
            <td scope="row">Safari</td>
            <td><a href="https://support.apple.com/kb/ph19214?locale=tr_TR"
                class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">https://support.apple.com/kb/ph19214?locale=tr_TR</a>
            </td>
          </tr>
        </tbody>
      </table>
      <h4>ÇEREZLERİ KONTROL ETME VE SİLME</h4>
      <p>Çerezlerin kullanımına ilişkin tercihlerinizi değiştirmek ya da çerezleri engellemek veya silmek için
        tarayıcınızın ayarlarını değiştirmeniz yeterlidir. Birçok tarayıcı çerezleri kontrol edebilmeniz için size
        çerezleri kabul etme veya reddetme, yalnızca belirli türdeki çerezleri kabul etme ya da bir web sitesi cihazınıza
        çerez depolamayı talep ettiğinde tarayıcı tarafından uyarılma seçeneği sunar. Aynı zamanda daha önce tarayıcınıza
        kaydedilmiş çerezlerin silinmesi de mümkündür. Çerezleri kontrol edilmesine veya silinmesine ilişkin işlemler
        kullandığınız tarayıcıya göre değişebilmektedir. Bazı popüler tarayıcıların çerezlere izin verme ya da çerezleri
        engelleme veya silme talimatlarına aşağıdaki linkten ulaşılabilirsiniz.</p>
      <h3>3. İNTERNET SİTESİ GİZLİLİK POLİTİKASI’NIN YÜRÜRLÜĞÜ</h3>
      <p>İnternet Sitesi Gizlilik Politikası 01.05.2023 tarihlidir. Politika’nın tümünün veya belirli maddelerinin
        yenilenmesi durumunda Politika’nın yürürlük tarihi güncellenecektir</p>
      <p>Politika Zerduz internet sitesinde (<a href="https://www.zerduz.com"
          class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">www.zerduz.com</a>)
        yayımlanır ve kişisel veri sahiplerinin talebi üzerine ilgili
        kişilerin erişimine sunulur.</p>
      <p>Kişisel verilerinizin işlenmesine ilişkin detaylı bilgilere <a href="https://www.zerduz.com"
          class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">OBİM TEKNOLOJİ VE
          DIŞ TİCARET LİMİTED ŞİRKETİ Kişisel
          Verilerin Korunması ve İşlenmesi Politikası</a>’ndan ulaşabilirsiniz.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "GizlilikveCerez",
};
</script>

  <style scoped src="../assets/css/pages.css"/>

<template>
  <div>
    <main class="form-signin w-100 m-auto">
      <div v-if="showResetForm">
        <form @submit.prevent="resetPassword" id="new-password-form">
          <i class="fa-solid fa-key fs-1 d-flex justify-content-center mb-4 icon"></i>
          <h1 class="h3 mb-3 text-center">{{ $t('newPasswordPage.header') }}</h1>

          <small class="text-muted d-flex justify-content-center text-center">{{ $t('newPasswordPage.description') }}</small>

          <div class="form-floating my-3">
            <input :type="showPassword ? 'text' : 'password'" class="form-control" @input="validatePassword" v-model="formData.password1" :placeholder="$t('newPasswordPage.form.newPassword')" required />
            <span @click="togglePassword" toggle="#password-field" class="fa fa-fw field-icon toggle-password base-password text-zerduz" :class="{ 'fa-eye': !showPassword, 'fa-eye-slash active': showPassword }"></span>
            <label for="password1">{{ $t('newPasswordPage.form.newPassword') }}</label>
            <p v-if="isPasswordValid == false" class="mt-2 fw-normal text-danger">{{ $t('errors.validation.passwordValidText') }}</p>
          </div>
          <div class="form-floating mb-3">
            <input :type="showPasswordConfirmation ? 'text' : 'password'" class="form-control" @input="validatePasswordConfirmation" v-model="formData.password2" :placeholder="$t('newPasswordPage.form.newPasswordConfirmation')" required />
            <span @click="togglePasswordConfirmation" toggle="#password-field" class="fa fa-fw field-icon toggle-password base-password text-zerduz" :class="{ 'fa-eye': !showPasswordConfirmation, 'fa-eye-slash active': showPasswordConfirmation }"></span>
            <label for="password2">{{ $t('newPasswordPage.form.newPasswordConfirmation') }}</label>
            <p v-if="isConfirmationPasswordValid == false" class="mt-2 fw-normal text-danger">{{ $t('errors.validation.passwordValidText') }}</p>
          </div>
          <div id="alertbox"></div>
          <button class="w-100 btn btn-dark disabled" id="changePasswordBtn" type="submit">
            <span class="button-text">{{ $t('newPasswordPage.form.btn') }}</span>
            <div class="btn-loading">
              <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
              <span class="visually-hidden" role="status">{{ $t('loadingText') }}</span>
            </div>
          </button>
        </form>

        <section class="success-section">
          <i class="fa-solid fa-circle-check fs-1 d-flex justify-content-center mb-4 icon"></i>
          <h1 class="h3 mb-3 text-center">{{ $t('newPasswordPage.success.title') }}</h1>
          
          <small v-if="$t('newPasswordPage.success.description.first') == 'link'" class="fs-5 d-flex justify-content-center text-center text-nowrap"><router-link to="/login" class="d-inline-flex fst-italic text-decoration-none">{{ $t('newPasswordPage.success.description.link') }}</router-link>&nbsp;{{ $t('newPasswordPage.success.description.text') }}&nbsp;</small>
          <small v-if="$t('newPasswordPage.success.description.first') == 'text'" class="fs-5 d-flex justify-content-center text-center">{{ $t('newPasswordPage.success.description.text') }}&nbsp;<router-link to="/login" class="d-inline-flex fst-italic text-decoration-none">{{ $t('newPasswordPage.success.description.link') }}</router-link></small>
        </section>

        <section class="error-section">
          <i class="fa-solid fa-circle-exclamation fs-1 d-flex justify-content-center mb-4 icon error-icon"></i>
          <h1 class="h3 mb-3 text-center">{{ $t('newPasswordPage.error.title') }}</h1>

          <small class="fs-5 d-block justify-content-center text-center">{{ $t('newPasswordPage.error.description[0]') }}&nbsp;<router-link
              to="/Contact" class="d-inline-flex fst-italic text-decoration-none">{{ $t('newPasswordPage.error.description[1]') }}</router-link>{{ $t('newPasswordPage.error.description[2]') }}</small>
        </section>
      </div>
      <div v-else class="text-center">
        <div class="spinner-border" style="width: 4rem; height: 4rem;" role="status">
          <span class="visually-hidden">{{ $t('loadingText') }}</span>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import $ from "jquery";
import { resetPassword, confirmCode } from "@/helpers/api";
import { alert } from "@/helpers/funcs";

export default {
  name: "NewPassword",
  data() {
    return {
      formData: {
        password1: '',
        password2: '',
      },
      code: '',
      showResetForm: false,
      token: window.localStorage.getItem('token'),
      isPasswordValid: null,
      isConfirmationPasswordValid: null,
      showPassword: false,
      showPasswordConfirmation: false,
    };
  },
  async created() {
    const code = this.$route.query.code;
    if (code) {
      const { success, response } = await confirmCode(code);
      this.showResetForm = success;
      if (success == false) {
        this.$router.push('/login');
      } else if (success == true) {
        this.code = response.data.token;
      }
    } else {
      this.$router.push('/login');
    }
  },
  methods: {
    togglePasswordConfirmation() {
      this.showPasswordConfirmation = !this.showPasswordConfirmation;
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    validatePassword() {
      const regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
      this.isPasswordValid = regex.test(this.formData.password1);
      if (this.isPasswordValid && this.isConfirmationPasswordValid) {
        $('#changePasswordBtn').removeClass('disabled');
      } else {
        if (!$('#changePasswordBtn').hasClass('disabled')) {
          $('#changePasswordBtn').addClass('disabled');
        }
      }
    },
    validatePasswordConfirmation() {
      const regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
      this.isConfirmationPasswordValid = regex.test(this.formData.password2);
      if (this.isPasswordValid && this.isConfirmationPasswordValid) {
        $('#changePasswordBtn').removeClass('disabled');
      } else {
        if (!$('#changePasswordBtn').hasClass('disabled')) {
          $('#changePasswordBtn').addClass('disabled');
        }
      }
    },
    async resetPassword() {
      if (this.formData.password1 == this.formData.password2) {
        if (this.isPasswordValid && this.isConfirmationPasswordValid) {
          $('.button-text').fadeOut(async () => {
            $('.btn-loading').fadeIn();
            const { success } = await resetPassword(this.code, this.formData.password1);
            if (success) {
              $('.btn-loading').fadeOut(function () {
                $('.button-text').fadeIn();
              });
              var form = $('form');
              var successSection = $('.success-section');
              var formHeight = form.outerHeight();
              $('form').fadeOut(function () {
                successSection.css({ height: formHeight });
                $('.success-section').fadeIn();
              });
            } else {
              $('.btn-loading').fadeOut(function () {
                $('.button-text').fadeIn();
              });
              form = $('form');
              var errorSection = $('.error-section');
              formHeight = form.outerHeight();
              $('form').fadeOut(function () {
                errorSection.css({ height: formHeight });
                $('.error-section').fadeIn();
              });
            }
          });
        } else {
          alert("#alertbox", this.$t('errors.validation.passwordValidText'), "danger", "mt-2");
        }
      } else {
        $('.btn-loading').fadeOut(function () {
          $('.button-text').fadeIn();
        });
        alert("#alertbox", this.$t('errors.validation.newPasswordsDoesntMatch2'), "danger", "mt-2");
      }
    },
  },
};
</script>

<style scoped src="../assets/css/new-password.css" />

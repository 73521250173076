<template>
    <div>
        <div class="px-4 text-center">
            <h1 class="text-center mt-5 p-1" id="page-header-text">{{ $tm('sellersPage.header') }}</h1>
            <div class="col-lg-6 mx-auto">
                <p class="lead">{{ $tm('sellersPage.description') }}</p>
            </div>
        </div>

        <div class="container mb-4 mt-4 d-grid mx-auto justify-content-center">
            <form class="row row-cols-lg-auto g-3 align-items-center">
                <div class="col-12" id="countrySelect">
                    <select class="form-select form-select-lg mb-3" v-model="selectedCountry"
                        @change="changeCountry">
                        <option :value="option.value" v-for="(option, index) in $tm('sellersPage.options')" :key="index">{{ option.text }}</option>
                    </select>
                </div>

                <div class="col-12" id="citySelect">
                    <select class="form-select form-select-lg mb-3" v-model="selectedCity"
                        @change="changeCity">
                        <option v-for="(city, index) in citiesClone" :key="index" v-bind:value="city.id">{{ city.name }}
                        </option>
                    </select>
                </div>

                <div class="col-12" id="stateSelect">
                    <select class="form-select form-select-lg mb-3" v-model="selectedState"
                        @change="changeState">
                        <option v-for="(state, index) in statesClone" :key="index" v-bind:value="state.id">{{ state.name }}
                        </option>
                    </select>
                </div>
            </form>
        </div>

        <div class="container mb-4 mt-4 mx-auto justify-content-center">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-12 sellers-list mb-3">
                    <div class="card border mb-3 seller-card" @click="selectSeller" v-bind:id="seller.id"
                        v-for="(seller, index) in sellersClone" :key="index">
                        <div class="card-header border-0">{{ this.locations.find(country => country.id ==
                            seller.country).name }}<span class="text-body-tertiary opacity-50 mx-1">
                                — </span><small class="fs-7">{{ this.locations.find(country => country.id ==
                                    seller.country).cities[seller.city - 1].name }}</small></div>
                        <div class="card-body">
                            <h5 class="card-title">{{ seller.company }}</h5>
                            <p class="card-text text-muted lh-1 fs-6">{{ seller.adress }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-md-6 col-sm-12">
                    <div class="text-center border shadow w-100 h-100 map-box">
                        <div class="container d-flex h-100 justify-content-center loading-box">
                            <div class="justify-content-center align-self-center loading-spinner">
                                <div class="spinner-border" style="width: 4rem; height: 4rem;" role="status">
                                    <span class="visually-hidden">{{ $tm('loadingText') }}</span>
                                </div>
                            </div>
                        </div>
                        <div id="mapContainer" ref="mapContainer" class="basemap"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';

import mapboxgl from 'mapbox-gl';

export default {
    name: "SellersList",
    data() {
        return {
            sellers: this.$tm('sellersPage.sellers'),
            sellersClone: [],
            citiesClone: [],
            statesClone: [],
            locations: this.$tm('sellersPage.locations'),
            selectedCountry: '',
            selectedCity: '',
            selectedState: '',
            mapContainer: null,
            mapboxToken: 'pk.eyJ1IjoiYnJ1aDZ4IiwiYSI6ImNsb3k1dHl3eDAxNXYyaXFkaGxycHN5eGgifQ.NZx5VFnn79ArMvpEvKeKhg',
            mapOptions: {
                container: 'mapContainer',
                style: 'mapbox://styles/bruh6x/cloy5xnd8013t01qo6zc6g038',
                attributionControl: false,
                center: [28.976018, 41.01224],
                zoom: 12
            },
            mapSellersClone: [],
            mapSellers: []
        }
    },
    methods: {
        removeSellerFromList(targetId) {
            const indexToRemove = this.sellersClone.findIndex(seller => seller.id == targetId);

            if (indexToRemove !== -1) {
                this.sellersClone.splice(indexToRemove, 1);
            }
        },
        selectSeller(event) {
            var targetId = event.currentTarget.id;
            var target = this.sellersClone.find(seller => seller.id == targetId);
            if ($('.card.active')) {
                $('.card.active').removeClass('active');
            }
            $('#' + targetId).addClass('active');
            this.adjustPositionOfMap(this.mapSellers.filter(value => value.properties.id === target.id));
        },
        changeCountry() {
            if (this.selectedCountry == '') {
                this.mapSellersClone = this.mapSellers;
                this.sellersClone = this.sellers;
                this.updateMapPointData(this.mapSellersClone);

                $('#citySelect').fadeOut();
                $('#stateSelect').fadeOut();
            } else {
                var country = this.locations.find(country => country.id == this.selectedCountry);
                this.sellersClone = this.sellersClone = this.sellersClone.filter(value => value.country === country.id);
                this.mapSellersClone = this.mapSellersClone.filter(value => value.properties.country === country.id);
                if (this.sellersClone == '' || this.mapSellersClone == '') {
                    this.sellersClone = this.sellers.filter(value => value.country == country.id);
                    this.mapSellersClone = this.mapSellers.filter(value => value.properties.country == country.id);
                }

                this.updateMapPointData(this.mapSellersClone);
                var cities = country.cities.map(city => ({ id: city.id, name: city.name }));
                cities.unshift({ id: 0, name: this.$t('sellersPage.city') });
                this.citiesClone = cities;
                this.selectedCity = 0;

                $('#stateSelect').fadeOut();
                $('#citySelect').fadeIn();
            }
        },
        changeCity() {
            if (this.selectedCity == '') {
                var country = this.locations.find(country => country.id == this.selectedCountry);
                this.mapSellersClone = this.mapSellers.filter(value => value.properties.country === country.id);
                this.sellersClone = this.sellers.filter(value => value.country === country.id);
                this.updateMapPointData(this.mapSellersClone);

                $('#stateSelect').fadeOut();
            } else {
                country = this.locations.find(country => country.id == this.selectedCountry);
                var city = country.cities.find(city => city.id == this.selectedCity);

                this.sellersClone = this.sellersClone.filter(value => value.city === city.id);
                this.mapSellersClone = this.mapSellersClone.filter(value => value.properties.city === city.id);
                if (this.sellersClone == '' || this.mapSellersClone == '') {
                    this.sellersClone = this.sellers.filter(value => value.city === city.id);
                    this.mapSellersClone = this.mapSellers.filter(value => value.properties.city === city.id);
                }

                this.updateMapPointData(this.mapSellersClone);
                var states = city.states.map(state => ({ id: state.id, name: state.name }));
                states.unshift({ id: 0, name: this.$t('sellersPage.state') });
                this.statesClone = states;
                this.selectedState = 0;

                $('#stateSelect').fadeIn();
            }
        },
        changeState() {
            if (this.selectedState == '') {
                var country = this.locations.find(country => country.id == this.selectedCountry);
                var city = country.cities.find(city => city.id == this.selectedCity);
                this.sellersClone = this.sellers.filter(value => value.city === city.id);
                this.mapSellersClone = this.mapSellers.filter(value => value.properties.city === city.id)

                if (this.sellersClone == '' || this.mapSellersClone == '') {
                    this.sellersClone = this.sellers.filter(value => value.city === city.id);
                    this.mapSellersClone = this.mapSellers.filter(value => value.properties.city === city.id)
                }
                this.updateMapPointData(this.mapSellersClone);
            } else {
                country = this.locations.find(country => country.id == this.selectedCountry);
                city = country.cities.find(city => city.id == this.selectedCity);
                var state = city.states.find(state => state.id == this.selectedState);
                this.sellersClone = this.sellersClone.filter(value => value.state === state.id);
                this.mapSellersClone = this.mapSellersClone.filter(value => value.properties.state === state.id)

                if (this.sellersClone == '' || this.mapSellersClone == '') {
                    this.sellersClone = this.sellers.filter(value => value.state === state.id);
                    this.mapSellersClone = this.mapSellers.filter(value => value.properties.state == state.id);
                }
                this.updateMapPointData(this.mapSellersClone);
            }
        },
        updateMapPointData(dataSource) {
            this.mapContainer.getSource('points').setData({
                "type": "FeatureCollection",
                "features": dataSource
            });
            this.adjustPositionOfMap();
        },
        adjustPositionOfMap(seller = false) {
            if (seller == false) {
                let co = this.mapSellersClone[0].geometry.coordinates;

                let bounds = this.mapSellersClone.reduce((bounds, feature) => {
                    return bounds.extend(feature.geometry.coordinates);
                }, new mapboxgl.LngLatBounds(co[0].lng, co[0].lat));

                this.mapContainer.fitBounds(bounds, {
                    padding: 100,
                    maxZoom: 12,
                    duration: 2000
                });
            } else {
                let co = seller[0].geometry.coordinates;

                let bounds = seller.reduce((bounds, feature) => {
                    return bounds.extend(feature.geometry.coordinates);
                }, new mapboxgl.LngLatBounds(co[0].lng, co[0].lat));

                this.mapContainer.fitBounds(bounds, {
                    padding: 100,
                    maxZoom: 12,
                    duration: 2000
                });
            }
        }
    },
    created() {
        this.sellersClone = this.sellers;
        this.sellersClone.forEach(element => {
            this.mapSellers.push({
                'type': 'Feature',
                'geometry': {
                    'type': 'Point',
                    'coordinates': [element.map.coordinates.lng, element.map.coordinates.lat]
                },
                'properties': {
                    'title': element.map.title,
                    'description': element.map.description,
                    'id': element.id,
                    'country': element.country,
                    'city': element.city,
                    'state': element.state
                }
            });
        });
        this.mapSellersClone = this.mapSellers;
    },
    mounted() {
        
        mapboxgl.accessToken = this.mapboxToken;
        this.mapContainer = new mapboxgl.Map(this.mapOptions);

        this.mapContainer.on('load', () => {
            this.mapContainer.loadImage(require('@/assets/img/custom-marker.png'),
                (error, image) => {
                    if (error) throw error;
                    this.mapContainer.addImage('custom-marker', image);
                    this.mapContainer.addSource('points', {
                        'type': 'geojson',
                        'data': {
                            'type': 'FeatureCollection',
                            'features': this.mapSellersClone
                        }
                    });

                    this.mapContainer.addLayer({
                        'id': 'points',
                        'type': 'symbol',
                        'source': 'points',
                        'layout': {
                            'icon-image': 'custom-marker',
                            'icon-allow-overlap': true,
                            'icon-ignore-placement': true,
                            'text-allow-overlap': true,
                            'text-ignore-placement': true,
                            'text-font': [
                                'Open Sans Semibold',
                                'Arial Unicode MS Bold'
                            ],
                            'text-offset': [0, 1.25],
                            'text-anchor': 'top'
                        },
                        paint: {
                            "text-color": "black",
                            "text-halo-color": "white",
                            "text-halo-width": 1,
                            "text-halo-blur": 1
                        }
                    });


                }
            );
            if (this.mapContainer.loaded()) {
                $('.loading-spinner').fadeOut();
                $('.loading-box').remove();
                $('#mapContainer').css('opacity', 1);
            }
        });

        $('.mapboxgl-ctrl-bottom-left').css('display', 'none');

        const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });

        this.adjustPositionOfMap();

        this.mapContainer.on('mouseenter', 'points', (e) => {
            this.mapContainer.getCanvas().style.cursor = 'pointer';

            const coordinates = e.features[0].geometry.coordinates.slice();
            const description = e.features[0].properties.description;

            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            popup.setLngLat(coordinates).setHTML(description).addTo(this.mapContainer);
        });

        this.mapContainer.on('mouseleave', 'points', () => {
            this.mapContainer.getCanvas().style.cursor = '';
            popup.remove();
        });

        this.mapContainer.on('click', () => {
            const popups = $(".mapboxgl-popup");
            if (popups.length) {
                popups[0].remove();
            }
        });

        this.mapContainer.on('click', 'points', (e) => {
            this.mapContainer.getCanvas().style.cursor = 'pointer';

            const coordinates = e.features[0].geometry.coordinates.slice();
            const description = e.features[0].properties.description;

            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            popup.setLngLat(coordinates).setHTML(description).addTo(this.mapContainer);
        });

    }
};
</script>

<style scoped src="../assets/css/sellers.css"/>

<template>
  <div>
    <div class="container mb-5">
      <div class="row row-cols-1 row-cols-md-2 align-items-md-center g-5 py-5">
        <div class="col d-flex flex-column align-items-start gap-2">
          <img src="../assets/img/zerduz-2.png" class="img-fluid" :style="smokeImg"
            style="background-position: center center !important; background-size: 150% 100% !important; background-repeat: no-repeat; ">
        </div>

        <div class="col">
          <div class="row row-cols-1 row-cols-sm-2 g-4 align-items-center justify-content-center text-center">
            <div class="col d-flex flex-column gap-2" v-for="(spec, index) in $tm('specsPage.specs')" :key="index">
              <h4 class="fw-semibold mb-0 text-body-emphasis text-glow-zerduz-25">{{ spec.title }}</h4>
              <p class="text-body-secondary">{{ spec.spec }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container py-3 mt-5">
      <div class="container px-4">
        <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div class="col-lg-6">
            <img src="../assets/img/new-3.png" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700"
              height="500" loading="lazy">
          </div>
          <div class="col-lg-6">
            <h1 class="display-5 fw-bold text-body-emphasis lh-1 mb-3">{{ $t('purchaseSection.title') }}</h1>
            <p class="lead">{{ $t('purchaseSection.description') }}</p>
            <div>
              <h1 class="fs-3 fw-normal mb-3 d-flex align-items-center justify-content-between">{{ $store.state.price.currency }}{{ $store.state.price.string.withoutTax }}<small class="text-secondary text-opacity-75 mt-2 text-kdv">{{ $t('taxsArentIncluded') }}</small></h1>
              <div class="d-grid gap-2 d-md-flex justify-content-md-start">

                <router-link to="Purchase" class="btn btn-primary btn-lg px-4 me-md-2 rounded-pill">{{ $t('purchaseButtonText') }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpecsView",
  data() {
    return {
      smokeImg: {
        backgroundImage: `url(${require('@/assets/img/smoke.png')})`
      },
    };
  },
};
</script>

<style scoped src="../assets/css/specs.css"/>

<template>
<div>
    <main class="input-section w-100 m-auto">
        <form class="validation" id="order-tracking-form" novalidate>
            <div class="text-center"><i class="fa-solid fa-truck fs-1 mb-4 icon"></i></div>
            <h1 class="h3 mb-3 text-center">{{ $t('orderTracking.input.header') }}</h1>

            <small class="text-muted d-flex justify-content-center text-center">{{ $t('orderTracking.input.description') }}</small>

            <div class="form-floating my-3">
                <input @keypress="isNumber($event)" type="number" class="form-control" id="cargoNumber" :placeholder="$t('orderTracking.input.cargoInput')">
                <label for="cargoNumber">{{ $t('orderTracking.input.cargoInput') }}</label>
            </div>

            <button class="w-100 btn btn-primary" type="submit">{{ $t('orderTracking.input.btn') }}</button>
        </form>
    </main>

    <main class="cargo-section w-100 m-auto">
        <form class="validation" id="order-tracking-form" novalidate>
            <div class="d-flex justify-content-center">
                <h1 class="fs-4 fw-normal mb-5 badge bg-body-tertiary text-black p-3">Sipariş Durumu</h1>
            </div>

            <h2 class="d-flex justify-content-center text-center fw-normal text-zerduz">Siparişiniz Teslim Edildi</h2>

            <div class="row">
                <div class="col-12 mx-auto my-5">
                    <div class="position-relative">
                        <div class="progress" role="progressbar" aria-label="Progress" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="height: 3px;">
                            <div class="bg-zerduz" style="width: 50%"></div>
                        </div>
                        <div>
                            <div>
                                <span class="position-absolute top-0 start-0 translate-middle bg-primary rounded-pill progress-1 border-3 align-items-center d-flex" style="width: 2.5rem; height:2.5rem;"><i class="fa-solid fa-check mx-auto text-white"></i></span>
                                <span class="position-absolute top-0 start-0 translate-middle rounded-pill progress-1 mt-4 pt-5 text-center" style="font-size:1rem; width:25%;">Kargo Alındı</span>
                            </div>
                            <div>
                                <span class="position-absolute top-0 start-25 translate-middle bg-primary rounded-pill progress-2 border-3 align-items-center d-flex" style="width: 2.5rem; height:2.5rem;"><i class="fa-solid fa-check mx-auto text-white"></i></span>
                                <span class="position-absolute top-0 start-25 translate-middle rounded-pill progress-1 mt-4 pt-5 text-center" style="font-size:1rem; width:25%;">Kargo Yolda</span>
                            </div>
                            <div>
                                <span class="position-absolute top-0 start-50 translate-middle bg-primary rounded-pill progress-3 border-3 align-items-center d-flex box-glow-zerduz" style="width: 2.5rem; height:2.5rem;"><i class="fa-solid fa-check mx-auto text-white"></i></span>
                                <span class="position-absolute top-0 start-50 translate-middle rounded-pill progress-1 mt-4 pt-5 text-center" style="font-size:1rem; width:25%;">Dağıtım Şubesinde</span>
                            </div>
                            <div>
                                <span class="position-absolute top-0 start-75 translate-middle border bg-body rounded-pill progress-4 border-3 align-items-center d-flex" style="width: 2.5rem; height:2.5rem;"></span>
                                <span class="position-absolute top-0 start-75 translate-middle rounded-pill progress-1 mt-4 pt-5 text-center" style="font-size:1rem; width:25%;">Dağıtıma Çıkarıldı</span>
                            </div>
                            <div>
                                <span class="position-absolute top-0 start-100 translate-middle border bg-body rounded-pill progress-4 border-3 align-items-center d-flex" style="width: 2.5rem; height:2.5rem;"></span>
                                <span class="position-absolute top-0 start-100 translate-middle rounded-pill progress-1 mt-4 pt-5 text-center" style="font-size:1rem; width:25%;">Teslim Edildi</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-5 border-0 shadow-sm">
                <div class="card-body">
                    <div class="text-center mb-4">
                        <h6 class="text-secondary fw-normal">Gönderi Numarası</h6>
                        <h3 class="text-zerduz">123456789</h3>
                    </div>

                    <div class="container">
                        <div class="row p-2 mb-4">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 g-2">
                                <h6 class="fw-normal text-secondary text-opacity-75">Teslim Edilecek Şube</h6>
                                <h6>Haznedar Şube</h6>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 g-2">
                                <h6 class="fw-normal text-secondary text-opacity-75">Çıkış Şubesi</h6>
                                <h6>Haznedar Şube</h6>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 g-2">
                                <h6 class="fw-normal text-secondary text-opacity-75">Tahmini Teslim Tarihi</h6>
                                <h6>27.02.2024</h6>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 g-2">
                                <h6 class="fw-normal text-secondary text-opacity-75">Çıkış Tarihi</h6>
                                <h6>24.02.2024 10.30</h6>
                            </div>
                        </div>

                        <h5><span class="badge bg-zerduz rounded-0 shadow-sm">Alıcı Bilgileri</span></h5>
                        <div class="row p-2 mb-4">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 g-2">
                                <h6 class="fw-normal text-secondary text-opacity-75">Ad Soyad</h6>
                                <h6>N****** K****</h6>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 g-2">
                                <h6 class="fw-normal text-secondary text-opacity-75">Adres</h6>
                                <h6>M****** Ç***** M** M***** S**** S* A******** N* 2**** G******* İ*******</h6>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 g-2">
                                <h6 class="fw-normal text-secondary text-opacity-75">İl</h6>
                                <h6>İSTANBUL(AVR)</h6>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 g-2">
                                <h6 class="fw-normal text-secondary text-opacity-75">İlçe</h6>
                                <h6>GÜNGÖREN</h6>
                            </div>
                        </div>

                        <h5><span class="badge bg-zerduz rounded-0 shadow-sm">Gönderici Bilgileri</span></h5>
                        <div class="row p-2 mb-4">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 g-2">
                                <h6 class="fw-normal text-secondary text-opacity-75">Ad Soyad</h6>
                                <h6>F***** Y*****</h6>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 g-2">
                                <h6 class="fw-normal text-secondary text-opacity-75">Adres</h6>
                                <h6>1*** S** N* 4 İ* K*** N* 4 S*********** S***** İ****</h6>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 g-2">
                                <h6 class="fw-normal text-secondary text-opacity-75">İl</h6>
                                <h6>İZMİR</h6>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 g-2">
                                <h6 class="fw-normal text-secondary text-opacity-75">İlçe</h6>
                                <h6>SELÇUK</h6>
                            </div>
                        </div>

                        <h5><span class="badge bg-zerduz rounded-0 shadow-sm">Kargo Hareketleri</span></h5>
                        <div class="row p-2 mb-4 table-responsive">
                           <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col" class="col-3">Tarih</th>
                                        <th scope="col">Durum</th>
                                        <th scope="col">Şube</th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    <tr>
                                        <th scope="row">25.02.2024 14.55</th>
                                        <td>Kargo Firmasına Ulaştı</td>
                                        <td>Haznedar Şube</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">26.02.2024 19.15</th>
                                        <td>Teslimat Belgesi Düzenlendi</td>
                                        <td>Haznedar Şube</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </main>
</div>
</template>

<script>
export default {
    name: "OrderTracking",
    methods: {
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    }
};
</script>

<style scoped src="../assets/css/order-tracking.css"/>

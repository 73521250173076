<template>
<div>
    <main class="form-signin w-100 m-auto">
        <div>
            <section class="success-section">
                <i class="fa-solid fa-circle-check fs-1 d-flex justify-content-center mb-4 icon"></i>
                <h1 class="h3 mb-3 text-center success-header">{{ $t('verifySMSPage.success') }}</h1>

                <small v-if="$t('verifySMSPage.successDescription.first') == 'text'" class="fs-5 d-flex justify-content-center text-center success-text">{{ $t('verifySMSPage.successDescription.text') }}&nbsp;<router-link to="/login" class="d-inline-flex fst-italic text-decoration-none">{{ $t('verifySMSPage.successDescription.link') }}</router-link></small>
                <small v-if="$t('verifySMSPage.successDescription.first') == 'link'" class="fs-5 d-flex justify-content-center text-center success-text"><router-link to="/login" class="d-inline-flex fst-italic text-decoration-none">{{ $t('verifySMSPage.successDescription.link') }}</router-link>&nbsp;{{ $t('verifySMSPage.successDescription.text') }}</small>
            </section>
            <section class="error-section">
                <i class="fa-solid fa-circle-exclamation fs-1 d-flex justify-content-center mb-4 icon error-icon"></i>
                <h1 class="h3 mb-3 text-center">{{ $t('verifySMSPage.error.title') }}</h1>

                <small class="fs-5 d-block justify-content-center text-center">
                    <span v-if="$t('verifySMSPage.error.description.first') == 'text'" class="error-text">{{ $t('verifySMSPage.error.description.text') }}&nbsp;<router-link to="/Contact" class="fst-italic text-decoration-none">{{ $t('verifySMSPage.error.description.link') }}</router-link>&nbsp;{{ $t('verifySMSPage.error.description.text2') }}</span>
                    <span v-if="$t('verifySMSPage.error.description.first') == 'link'" class="error-text">{{ $t('verifySMSPage.error.description.text') }}&nbsp;<router-link to="/Contact" class="fst-italic text-decoration-none">{{ $t('verifySMSPage.error.description.link') }}</router-link></span>
                    
                    <form class="my-3 resend-form mt-3" @submit.prevent="resend">
                        <label class="justify-content-start d-flex px-3 fs-6" for="phone">{{ $t('verifySMSPage.form.phone') }}</label>
                        <div class="form-floating my-2 fs-6">
                            <input type="tel" class="form-control px-5" style="height: 56px;" ref="phoneInput" @input="validatePhone" @keypress="isNumber($event)" v-model="phone" name="phoneNumber" id="phoneInput">
                            <span v-if="isPhoneValid == false" class="mt-3 fw-normal d-flex text-start text-danger px-3"><span v-if="phoneValidText && phoneValidText != ''">{{ phoneValidText }}</span></span>
                            <div id="error-alert" class="px-3 text-start d-flex"></div>
                            <button type="submit" class="btn btn-primary px-3 mt-2">{{ $t('verifySMSPage.form.btn') }}</button>
                        </div>
                    </form>
                </small>
            </section>
        </div>
        <div class="text-center loading-section">
            <div class="spinner-border" style="width: 4rem; height: 4rem;" role="status">
                <span class="visually-hidden">{{ $t('loadingText') }}</span>
            </div>
        </div>
    </main>
</div>
</template>

<script>
import $ from "jquery";
import {
    alert
} from "@/helpers/funcs";
import {
    verifySms,
    resendSmsVerification,
    updateCart,
    addToCart
} from "@/helpers/api";

export default {
    name: "VerifySms",
    data() {
        return {
            token: window.localStorage.getItem('token'),
            phone: '',
            iti: null,
            formattedPhoneNumber: '',
            isPhoneValid: null,
            phoneValidText: this.$t('errors.validation.phoneValidText[0]')
        };
    },
    methods: {
        addErrorMessage() {
            const errorMessage = $('<p>')
                .addClass('mt-2 fw-normal text-danger')
                .append($('<span>').text(this.phoneValidText));

            errorMessage.appendTo('#app');
        },
        removeErrorMessage() {
            $('#app p').remove();
        },
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        validatePhone() {
            if (this.phone != null && this.phone != '') {
                if (this.iti.isValidNumber()) {
                    this.isPhoneValid = true;
                    this.phoneValidText = "";
                } else {
                    const errorCode = this.iti.getValidationError();
                    if (errorCode == 1) {
                        this.phoneValidText = this.$t('errors.validation.phoneValidText[1]');
                    } else if (errorCode == 2) {
                        this.phoneValidText = this.$t('errors.validation.phoneValidText[2]');
                    } else if (errorCode == 3) {
                        this.phoneValidText = this.$t('errors.validation.phoneValidText[3]');
                    } else {
                        this.phoneValidText = this.$t('errors.validation.phoneValidText[0]');
                    }
                    this.isPhoneValid = false;
                }
            } else {
                this.isPhoneValid = null;
                this.phoneValidText = "";
            }
        },
        async resend() {
            if (this.phone && this.isPhoneValid) {
                if (this.phone.length > 0) {
                    if (this.phone.charAt(0) === '0') {
                        this.phone = this.phone.slice(1);
                    }
                    var selectedCountryData = this.iti.getSelectedCountryData();
                    var countryDialCode = selectedCountryData.dialCode;
                    this.formattedPhoneNumber = `${countryDialCode}${this.phone}`;
                    this.formattedPhoneNumber = this.formattedPhoneNumber.replace(/\s/g, '');
                }

                const {
                    success,
                    response
                } = await resendSmsVerification(this.formattedPhoneNumber);
                if (success == false) {
                    if (response.data.code == 3 && response.data.error == "User already verfied and activated") {
                        $('.error-section').fadeOut(() => {
                            $('.error-text').html(this.$t('verifySMSPage.error.accAlreadyActivated'));
                            $('.resend-form').hide();

                            $('.error-section').fadeIn();
                        });
                    } else {
                        $('.error-section').fadeOut(() => {
                            $('.error-text').html(this.$t('verifySMSPage.error.description.text') + "&nbsp;");
                            $('.resend-form').hide();
                            const loginLink = $('<br><a>')
                                .attr('href', '/Contact')
                                .attr('class', 'fst-italic text-decoration-none')
                                .text(this.$t('verifySMSPage.error.description.link'));

                            $('.error-text').append(loginLink);
                            $('.error-text').append("&nbsp;" + this.$t('verifySMSPage.error.description.text2'));
                            $('.error-section').fadeIn();
                        });
                    }
                } else if (success == true) {
                    $('.success-header').html(this.$t('verifySMSPage.verificationResend[0]'));
                    $('.success-text').html(this.$t('verifySMSPage.verificationResend[1]'));
                    $('.error-section').fadeOut(function () {
                        $('.success-section').fadeIn();
                    });
                }
            } else {
                if (!this.phone) {
                    alert("#error-alert", this.$t('errors.login.phoneCantBeEmpty'), "danger", "mt-4");
                } else if (this.isPhoneValid) {
                    this.$refs.phoneInput.focus();
                    alert("#error-alert", this.$t('errors.smsError'), "danger", "mt-4");
                }
            }
        },
    },
    mounted() {
        var input = document.querySelector('#phoneInput');
        this.iti = window.intlTelInput(input, {
            autoPlaceholder: "polite",
            customPlaceholder: function (placeholder) {
                if (placeholder.charAt(0) === '0') {
                    return placeholder.slice(1);
                } else {
                    return placeholder;
                }
            },
            preferredCountries: ["tr"],
            initialCountry: "auto",
            geoIpLookup: callback => {
                fetch("https://ipapi.co/json")
                    .then(res => res.json())
                    .then(data => callback(data.country_code))
                    .catch(() => callback("tr"));
            },
            utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@19.2.19/build/js/utils.js",
        });
    },
    async created() {
        $('.error-section').show();
        const code = this.$route.query.code;
        if (code) {
            const {
                success,
                response,
                message
            } = await verifySms(code);
            if (success == false) {
                if (response.data.error == "otp expired or Phone number not registered, or already activated, if activated please ask for sms resend" || response.data.error == "SMS verification failed, invalid or expired, please ask for resend") {
                    $('.error-text').text(this.$t('verifySMSPage.error.errorText[0]'));


                    $('.loading-section').fadeOut(function () {
                        $('.error-section').fadeIn();
                    });
                } else if (response.data.code == 3 && response.data.error == "User already verfied and activated") {
                    $('.error-section').fadeOut(() => {
                        $('.error-text').text(this.$t('verifySMSPage.error.accAlreadyActivated'));
                        $('.resend-form').hide();
                        $('.error-section').fadeIn();
                    });
                } else {
                    $('.error-text').text(message);
                    $('.loading-section').fadeOut(function () {
                        $('.resend-form').hide();
                        $('.error-section').fadeIn();
                    });
                }
            } else if (success == true) {
                var data = response.data.data;
                var cart = data.cart;
                var token = data.token;
                window.localStorage.setItem('token', token);
                window.localStorage.setItem('userId', data.user.id);
                var expirationDate = new Date();
                expirationDate.setDate(expirationDate.getDate() + 2);
                const cartData = JSON.parse(localStorage.getItem('cart') || '[]');
                if (cartData.length === 0 && cart.length > 0) {
                    $('.btn-loading').fadeOut(function () {
                        $('.login-button-text').fadeIn();
                    });
                    if (cart[0].quantity > 0 && cart[0].quantity != null && cart[0].quantity != "") {
                        var newCartItem = {
                            productId: 1,
                            cartQuantity: cart[0].quantity,
                            payorder_id: cart[0].payorder_id
                        };
                        cartData.push(newCartItem);
                        this.$store.dispatch('changeCartItem', {
                            cart: cartData
                        });
                        window.localStorage.setItem('cart', JSON.stringify(cartData));
                    }
                } else if (cartData.length > 0 && (cartData[0].cartQuantity == 0 || cartData[0].cartQuantity == null || cartData[0].cartQuantity == "")) {
                    $('.btn-loading').fadeOut(function () {
                        $('.login-button-text').fadeIn();
                    });
                    if (cart.length > 0) {
                        if (cart[0].quantity > 0 && cart[0].quantity != null && cart[0].quantity != "") {
                            cartData[0].cartQuantity = cart[0].quantity;
                            cartData[0].payorder_id = cart[0].payorder_id;
                            this.$store.dispatch('changeCartItem', {
                                cart: cartData
                            });
                            window.localStorage.setItem('cart', JSON.stringify(cartData));
                        }
                    }
                } else if (cartData.length > 0 && cartData[0].cartQuantity > 0) {
                    if (cart) {
                        if (cart.length > 0) {
                            if (cart[0].quantity > 0 && cart[0].quantity != null && cart[0].quantity != "") {
                                if (cartData[0].cartQuantity > cart[0].quantity) {
                                    var operation = true;
                                    if ((parseInt(cartData[0].cartQuantity) - parseInt(cart[0].quantity) > 0)) {
                                        operation = true;
                                    } else if ((parseInt(cartData[0].cartQuantity) - parseInt(cart[0].quantity) < 0)) {
                                        operation = false;
                                    }
                                    var updateCartQuantity = parseInt(cartData[0].cartQuantity) - parseInt(cart[0].quantity);
                                    const {
                                        success: cartSuccess
                                    } = await updateCart(token, 1, updateCartQuantity, cart[0].payorder_id, operation);
                                    if (cartSuccess) {
                                        cartData[0].payorder_id = cart[0].payorder_id;
                                        window.localStorage.setItem('cart', JSON.stringify(cartData));
                                    }
                                } else {
                                    cartData[0].cartQuantity = cart[0].quantity;
                                    cartData[0].payorder_id = cart[0].payorder_id;
                                    this.$store.dispatch('changeCartItem', {
                                        cart: cartData
                                    });
                                    window.localStorage.setItem('cart', JSON.stringify(cartData));
                                }
                            }
                        } else {
                            await addToCart(token, 1, parseInt(cartData[0].cartQuantity), cartData[0].payorder_id, true);
                        }
                    }
                }

                window.localStorage.setItem('expirationDate', expirationDate.getTime());
                var userName = success ? (data.user.first_name && data.user.last_name ? `${data.user.first_name} ${data.user.last_name}` : data.user.first_name) : '';
                this.$store.dispatch('login', {
                    token: token,
                    userId: data.user.id,
                    username: userName,
                    expirationdate: expirationDate.getTime()
                });

                $('.loading-section').fadeOut(function () {
                    $('.success-section').fadeIn();
                });

                setTimeout(function (scope) {
                    if (String(scope.$route.path).toLowerCase() === '/verify-email') {
                        if (scope.redirectUrl != null) {
                            scope.$router.push(scope.redirectUrl);
                        } else {
                            scope.$router.push('/Account/Orders');
                        }
                    }
                }, 5000, this);
            }
        } else {
           this.$router.push('/login');
        }
    }
};
</script>

<style scoped src="../assets/css/verify-sms.css" />

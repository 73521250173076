<template>
<div>
    <div class="container">
        <div class="row flex-lg-row-reverse align-items-center g-5 pt-5">
            <div class="col-8 col-sm-8 col-lg-5 mx-sm-auto mx-md-auto mx-auto mx-lg-0">
                <img src="../assets/img/new-1.png" class="d-block mx-lg-auto img-fluid" width="700" height="500" loading="lazy">
            </div>
            <div class="col-lg-6">
                <h1 class="display-5 fw-bold text-body-emphasis lh-1 mb-3">{{ $t('aboutUsPage.header') }}</h1>
                <p class="lead">{{ $t('aboutUsPage.content') }}</p>
            </div>
        </div>
    </div>

    <section>
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="text-center py-lg-5 p-4">
                        <div class="mb-4">
                            <i class="fa-solid fa-users fa-xl bi bi-trophy-fill text-zerduz"></i>
                        </div>
                        <div>
                            <h4>{{ $t('aboutUsPage.icons[0]') }}</h4>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="text-center py-lg-5 p-4">
                        <div class="mb-4">
                            <i class="fa-solid fa-lightbulb fa-xl bi bi-trophy-fill text-zerduz"></i>
                        </div>
                        <div>
                            <h4>{{ $t('aboutUsPage.icons[1]') }}</h4>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="text-center py-lg-5 p-4">
                        <div class="mb-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#EA73FB" class="bi bi-stars text-primary" viewBox="0 0 16 16">
                                <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828l.645-1.937zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.734 1.734 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.734 1.734 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.734 1.734 0 0 0 3.407 2.31l.387-1.162zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L10.863.1z" />
                            </svg>
                        </div>
                        <div>
                            <h4>{{ $t('aboutUsPage.icons[2]') }}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>
</template>

<script>
export default {
    name: "AboutPage",
};
</script>

<style scoped src="../assets/css/contact.css"/>

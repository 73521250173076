<template>
  <div>
    <div class="container g-4 px-md-10">
      <div class="px-4 my-5 text-center">
        <h1 class="text-center mt-5 p-1">Mesafeli Satış Sözleşmesi</h1>
      </div>
      <h3>1. TARAFLAR</h3>
      <p>İşbu Mesafeli Satış Sözleşmesi ("<strong>Sözleşme</strong>"), Alıcı ve Satıcı arasında aşağıda belirtilen hüküm
        ve şartlar
        çerçevesinde elektronik ortamda kurulmuştur. Alıcı ve Satıcı, Sözleşme kapsamında birlikte
        <strong>“Taraflar”</strong>, ayrı ayrı
        <strong>“Taraf”</strong> olarak anılacaktır.
      </p>
      <h3>2. TANIMLAR</h3>
      <p>Sözleşme’nin uygulanmasında ve yorumlanmasında aşağıda yazılı terimler karşılarındaki yazılı açıklamaları ifade
        edeceklerdir.</p>
      <table class="table table-light table-striped">
        <tbody>
          <tr>
            <td scope="row">ALICI:</td>
            <td>Bir Mal veya Hizmet’i ticari veya mesleki olmayan amaçlarla edinen, kullanan veya yararlanan gerçek
              kişiyi,</td>
          </tr>
          <tr>
            <td scope="row">Bakanlık:</td>
            <td>Türkiye Cumhuriyeti Ticaret Bakanlığı’nı,</td>
          </tr>
          <tr>
            <td scope="row">Banka:</td>
            <td>5411 sayılı Bankacılık Kanunu uyarınca kurulan lisanslı kuruluşları,</td>
          </tr>
          <tr>
            <td scope="row">ZERDUZ veya Elektronik Ticaret Aracı Hizmet Sağlayıcı:</td>
            <td>Oluşturduğu sistem ile Satıcı’nın Ürün/Hizmet’i satışa sunduğu Platform’u işleten ve Satıcı adına mesafeli
              sözleşme kurulmasına aracılık eden OBİM TEKNOLOJİ VE DIŞ TİCARET LİMİTED ŞİRKETİ’ni,</td>
          </tr>
          <tr>
            <td scope="row">Hizmet:</td>
            <td>Bir ücret veya menfaat karşılığında yapılan ya da yapılması taahhüt edilen Ürün sağlama dışındaki her
              türlü tüketici işleminin konusunu,</td>
          </tr>
          <tr>
            <td scope="row">Kanun:</td>
            <td>6502 sayılı Tüketicinin Korunması Hakkında Kanun’u,</td>
          </tr>
          <tr>
            <td scope="row">Kargo Şirketi:</td>
            <td>Ürün’ün Alıcı’ya ulaştırılmasını, iade süreçlerinde Alıcı’dan alınarak Satıcı veya ZERDUZ’a
              ulaştırılmasını sağlayan anlaşmalı kargo veya lojistik şirketini,</td>
          </tr>
          <tr>
            <td scope="row">Ön Bilgilendirme Formu:</td>
            <td>Sözleşme kurulmadan ya da buna karşılık herhangi bir teklif Alıcı tarafından kabul edilmeden önce Alıcı’yı
              Yönetmelik’te belirtilen asgari hususlar konusunda bilgilendirmek için hazırlanan formu,</td>
          </tr>
          <tr>
            <td scope="row">Platform:</td>
            <td>ZERDUZ’a ait www.zerduz.com adlı internet sitesini ve mobil uygulamasını,</td>
          </tr>
          <tr>
            <td scope="row">Satıcı:</td>
            <td>Kamu tüzel kişileri de dahil olmak üzere ticari veya mesleki amaçlarla tüketiciye Ürün/Hizmet sunan ya da
              Ürün/Hizmet sunanın adına ya da hesabına hareket eden ve Sözleşme’nin 5. maddesinde bilgileri bulunan gerçek
              ve/veya tüzel kişiyi,</td>
          </tr>
          <tr>
            <td scope="row">Sözleşme:</td>
            <td>Satıcı ve Alıcı arasında akdedilen Sözleşme’yi,</td>
          </tr>
          <tr>
            <td scope="row">ZERDUZ Teslimat Noktası:</td>
            <td>Alıcı’nın satın aldığı Ürünler’i kolayca teslim alabildiği anlaşmalı esnaf noktalarını, kargo şubelerini
              ve zincir mağazalarını,</td>
          </tr>
          <tr>
            <td scope="row">Ürün:</td>
            <td>Alışverişe konu olan taşınır eşya, konut veya tatil amaçlı taşınmaz mallar ile elektronik ortamda
              kullanılmak üzere hazırlanan yazılım, ses, görüntü ve benzeri her türlü gayri maddi malı,</td>
          </tr>
          <tr>
            <td scope="row">Yönetmelik:</td>
            <td>Mesafeli Sözleşmeler Yönetmeliği’ni ifade eder.</td>
          </tr>
        </tbody>
      </table>
      <h3>3. SÖZLEŞMENİN KONUSU ve KAPSAMI</h3>
      <p><strong>3.1.</strong> Sözleşme’nin konusu Alıcı'nın, Platform’da, Ürün/Hizmet’in satın alınmasına yönelik
        elektronik olarak
        sipariş verdiği, Sözleşme’de belirtilen niteliklere sahip Ürün/Hizmet’in satışı ve teslimi ile ilgili olarak Kanun
        ve Yönetmelik hükümleri gereğince Taraflar’ın hak ve yükümlülüklerinin belirlenmesi olup Taraflar, Sözleşme
        tahtında Kanun ve Yönetmelik’ten kaynaklanan yükümlülük ve sorumluluklarını bildiklerini ve anladıklarını kabul,
        beyan ve taahhüt ederler.</p>
      <p><strong>3.2.</strong> Sözleşme’nin akdedilmesi Taraflar’ın ayrı ayrı ZERDUZ ile akdetmiş oldukları sözleşmelerin
        hükümlerinin
        ifasını engellemeyecek olup, Taraflar, ZERDUZ’un Ürün/Hizmet’in satışına ve Sözleşme’ye herhangi bir şekilde taraf
        olmadığını ve Sözleşme kapsamında Taraflar’ın yükümlülüklerini yerine getirmeleri ile ilgili Kanun ve Yönetmelik
        çerçevesinde kendisine yüklenmiş olanlar hariç olmak üzere herhangi bir sorumluluğu ve taahhüdü bulunmadığını
        kabul, beyan ve taahhüt ederler.</p>
      <p><strong>3.3.</strong> Mevzuat uyarınca aşağıdaki Ürün/Hizmet satışları Sözleşme’nin kapsamında değildir.</p>
      <ul class="list-unstyled">
        <li class="ms-4">
          <strong>a) </strong> Finansal hizmetler,
        </li>
        <li class="ms-4">
          <strong>b) </strong> Otomatik makineler aracılığıyla yapılan satışlar,
        </li>
        <li class="ms-4">
          <strong>c) </strong> Halka açık telefon vasıtasıyla telekomünikasyon operatörleriyle bu telefonun
          kullanımı,
        </li>
        <li class="ms-4">
          <strong>d) </strong> Bahis, çekiliş, piyango ve benzeri şans oyunlarına ilişkin hizmetler,
        </li>
        <li class="ms-4">
          <strong>e) </strong> Taşınmaz malların veya bu mallara ilişkin hakların oluşumu, devri veya kazanımı,
        </li>
        <li class="ms-4">
          <strong>f) </strong> Konut kiralama,
        </li>
        <li class="ms-4">
          <strong>g) </strong> Paket turlar,
        </li>
        <li class="ms-4">
          <strong>h) </strong> Devre mülk, devre tatil, uzun süreli tatil hizmeti ve bunların yeniden satımı veya
          değişimi,
        </li>
        <li class="ms-4">
          <strong>i) </strong> Yiyecek ve içecekler gibi günlük tüketim maddelerinin, satıcının düzenli teslimatları
          çerçevesinde tüketicinin meskenine veya işyerine götürülmesi,
        </li>
        <li class="ms-4">
          <strong>j) </strong> Yolcu taşıma hizmetleri,
        </li>
        <li class="ms-4">
          <strong>k) </strong> Ürünlerin montaj, bakım ve onarımı,
        </li>
        <li class="ms-4">
          <strong>l) </strong> Bakımevi hizmetleri, çocuk, yaşlı ya da hasta bakımı gibi ailelerin ve kişilerin
          desteklenmesine yönelik sosyal hizmetler.
        </li>
        <li class="ms-4">
          <strong>m) </strong> Kısa mesaj aracılığıyla kurulan ve eş zamanlı olarak tamamen ifa edilen abonelik
          içermeyen
          katma değerli elektronik haberleşme Hizmetleri ile 23/6/1983 tarihli ve 2860 sayılı Yardım Toplama Kanunu
          kapsamındaki bağışlar ve kamu kurumlarınca sunulan katma değerli elektronik haberleşme hizmetleri.
        </li>
      </ul>
      <h3>4. ALICI’NIN ÖNCEDEN BİLGİLENDİRİLDİĞİ HUSUSLAR</h3>
      <p>Alıcı, siparişin oluşturması ve Sözleşme’nin kurulmasından önce, gerek Platform’un ilgili sayfaları ve
        kısımlarındaki tüm genel-özel açıklamalar, gerek Sözleşme, gerek Ön Bilgilendirme Formu gerekse de sair
        şekillerde, aşağıda yer verilenler de dahil olmak üzere Sözleşme’nin akdi ve uygulaması ile ilgili tüm hususlar
        hakkında doğru ve eksiksiz olarak bilgilendirildiğini ve bunları okuyup anladığını kabul, beyan ve taahhüt eder.
      </p>
      <ul class="list-unstyled">
        <li class="ms-4">
          <strong>a) </strong> Ürün/Hizmet’in temel nitelikleri,
        </li>
        <li class="ms-4">
          <strong>b) </strong> Satıcı'nın adı veya unvanı, MERSİS numarası veya vergi kimlik numarası ve iletişim
          bilgileri ile diğer tanıtıcı bilgileri,
        </li>
        <li class="ms-4">
          <strong>c) </strong> Ürün/Hizmet'in Platform’dan alınması sırasındaki satış işlemi aşamaları ile yanlış
          girilen
          bilgilerin düzeltilmesine ilişkin amaca uygun araçlar-yöntemler,
        </li>
        <li class="ms-4">
          <strong>d) </strong> Satıcı'nın mensubu olduğu Meslek Odası (İTO-İstanbul Ticaret Odası) ve İTO'nun meslek
          ile
          ilgili öngördüğü davranış kuralları bilgisinin edinebileceği elektronik iletişim bilgileri (Telefon: 444 0 486,
          www.ito.org.tr)
        </li>
        <li class="ms-4">
          <strong>e) </strong> ZERDUZ tarafından uygulanan Alıcı bilgileri için geçerli gizlilik, veri
          kullanımı-işleme ve
          elektronik iletişim kuralları ile Alıcı’nın bu hususlarda ZERDUZ’a verdiği izinlerin kapsamı, Alıcı’nın kanuni
          hakları, Satıcı’nın hakları ve Taraflar’ın haklarını kullanım usulleri,
        </li>
        <li class="ms-4">
          <strong>f) </strong> Ürün/Hizmet için Satıcı tarafından öngörülen gönderim kısıtlamaları,
        </li>
        <li class="ms-4">
          <strong>g) </strong> Ürün/Hizmet için kabul edilen ödeme yöntem-araçları, Ürün/Hizmet’in vergiler dahil
          toplam
          satış fiyatı,
        </li>
        <li class="ms-4">
          <strong>h) </strong> Ürün/Hizmet'in Alıcı’ya teslimine ilişkin usuller ile nakliye-teslimat-kargo
          masrafları
          gibi ek masraflar hakkında bilgiler,
        </li>
        <li class="ms-4">
          <strong>i) </strong> Ürün/Hizmet ile ilgili diğer ödeme/tahsilat ve teslimat bilgileri ve süresi ile
          Sözleşme'nin ifasına ilişkin diğer bilgiler ve Taraflar’ın bu hususlardaki sorumlulukları,
        </li>
        <li class="ms-4">
          <strong>j) </strong> Alıcı’nın cayma hakkını kullanamadığı durumlarda bu haktan faydalanamayacağına veya
          hakkın
          süresinde kullanılmaması da dahil olmak üzere hangi koşullarda bu hakkı kaybedeceğine ilişkin bilgi,
        </li>
        <li class="ms-4">
          <strong>k) </strong> Alıcı’nın cayma hakkının olduğu durumlarda bu hakkını kullanma şartları, süresi ve
          usulü,
          Satıcı’nın iade için öngördüğü Kargo Şirketi’ne ilişkin bilgi ve tüm mali hususlar (iade yöntemi ile masrafı,
          Sözleşme konusu bedelin iadesi ve iade sırasında Alıcı tarafından kazanılmış/kullanılmış ödül puanları sebebiyle
          yapılabilecek indirim ve mahsuplar dahil),
        </li>
        <li class="ms-4">
          <strong>l) </strong> Cayma bildiriminin yapılacağı açık adres, faks numarası veya elektronik posta
          bilgileri,
        </li>
        <li class="ms-4">
          <strong>m) </strong> Satıcı’nın talebi üzerine, varsa tüketici tarafından ödenmesi veya sağlanması gereken
          depozitolar ya da diğer mali teminatlar ve bunlara ilişkin şartlar,
        </li>
        <li class="ms-4">
          <strong>n) </strong> Varsa dijital içeriklerin işlevselliğini etkileyebilecek teknik koruma önlemleri,
        </li>
        <li class="ms-4">
          <strong>o) </strong> Alıcı’nın Platform’da dönem dönem uygulanabilecek çeşitli fırsatlara ilişkin
          yararlanma
          koşullarının (özel şartlar) detayları,
        </li>
        <li class="ms-4">
          <strong>p) </strong> Satıcı’nın bildiği ya da makul olarak bilmesinin beklendiği, dijital içeriğin hangi
          donanım
          ya da yazılımla birlikte çalışabileceğine ilişkin bilgi,
        </li>
        <li class="ms-4">
          <strong>q) </strong> Mahiyetine göre Sözleşme'de yer alan diğer tüm satış şartları ile Sözleşme Alıcı
          tarafından
          Platform’da onaylanarak kurulduktan sonra Sözleşme’nin Alıcı’nın talebi halinde Alıcı’ya elektronik posta ile
          gönderileceği ve Alıcı tarafından Sözleşme’ye üyelik hesabından erişilebileceğine ilişkin bilgi,
        </li>
        <li class="ms-4">
          <strong>r) </strong> Uyuşmazlık hallerinde Alıcı’nın, başvurularını Tüketici Mahkemesine veya Tüketici
          Hakem
          Heyetine yapabileceğine ilişkin bilgi.
        </li>
      </ul>
      <h3>5. ALICI, SATICI, ELEKTRONİK TİCARET ARACI HİZMET SAĞLAYICI VE FATURA BİLGİLERİ</h3>
      <h5>ALICI BİLGİLERİ</h5>
      <table class="table table-light table-striped">
        <tbody>
          <tr>
            <td scope="row">Teslim Edilecek Kişi:</td>
            <td>İSİM SÖYİSİM</td>
          </tr>
          <tr>
            <td scope="row">Teslimat Adresi:</td>
            <td>ADRES</td>
          </tr>
          <tr>
            <td scope="row">Telefon:</td>
            <td>TELEFON</td>
          </tr>
          <tr>
            <td scope="row">Faks:</td>
            <td>TELEFON</td>
          </tr>
          <tr>
            <td scope="row">E-posta/Kullanıcı Adı:</td>
            <td>E-POSTA</td>
          </tr>
        </tbody>
      </table>
      <h5>ELEKTRONİK TİCARET SATICI BİLGİLERİ</h5>
      <table class="table table-light table-striped">
        <tbody>
          <tr>
            <td scope="row">Elektronik Ticaret Satıcı Unvanı</td>
            <td>OBİM TEKNOLOJİ VE DIŞ TİCARET LİMİTED ŞİRKETİ</td>
          </tr>
          <tr>
            <td scope="row">Elektronik Ticaret Satıcı Adresi</td>
            <td>Yenibosna Merkez Mahallesi Ladin Sokak No:36/11 Bahçelievler İstanbul</td>
          </tr>
          <tr>
            <td scope="row">Elektronik Ticaret Satıcı Mersis Numarası</td>
            <td>xxxx</td>
          </tr>
          <tr>
            <td scope="row">Elektronik Ticaret Satıcı Vergi Kimlik Numarası</td>
            <td>Yenibosna VD- 6321356656</td>
          </tr>
          <tr>
            <td scope="row">Elektronik Ticaret Satıcı Telefonu</td>
            <td>0850 340 6666</td>
          </tr>
          <tr>
            <td scope="row">Elektronik Ticaret Satıcı Faks Numarası</td>
            <td>0850 340 6666</td>
          </tr>
          <tr>
            <td scope="row">Elektronik Ticaret Satıcı Şikâyet/Öneri Kanalları</td>
            <td>0850 340 6666 telefon numarası üzerinden ve Platform’da yer alan “ZERDUZ Asistan’a Sor” başlıklı alandan
              şikayet ve öneriler iletilebilecektir.</td>
          </tr>
        </tbody>
      </table>
      <h5>FATURA BİLGİLERİ</h5>
      <table class="table table-light table-striped">
        <tbody>
          <tr>
            <td scope="row">Ticari Unvanı / Adı ve Soyadı</td>
            <td></td>
          </tr>
          <tr>
            <td scope="row">Vergi Dairesi ve Vergi Kimlik Numarası</td>
            <td></td>
          </tr>
          <tr>
            <td scope="row">Adres</td>
            <td></td>
          </tr>
          <tr>
            <td scope="row">Telefon</td>
            <td></td>
          </tr>
          <tr>
            <td scope="row">Faks</td>
            <td></td>
          </tr>
          <tr>
            <td scope="row">E-posta/Kullanıcı Adı</td>
            <td></td>
          </tr>
          <tr>
            <td scope="row">Fatura Teslim</td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <h3>6. ÜRÜN/HİZMET BİLGİLERİ</h3>
      <p><strong>6.1.</strong> Ürün/Hizmet’in temel özellikleri (türü, miktarı, marka/modeli, rengi, adedi, fiyatı)
        Platform’da yer almakta
        olup Platform üzerinden detaylı şekilde incelenebilecektir.
      </p>
      <p>
        <strong>6.2.</strong>Ürün/Hizmet karşılığında ödenecek tüm tutarlar (tüm vergiler dahil satış fiyatı, kargo bedeli, taksit
        farkı tutarı, açık pazar ve/veya diğer butiklerinden eş zamanlı olarak gerçekleştirilen alışverişlerde hak
        kazanılan toplam indirim tutarı vb.) aşağıdaki tabloda gösterilmiştir.
      </p>
      <table class="table table-light table-bordered">
        <thead>
          <tr>
            <th scope="col">Ürün/Hizmet Açıklaması</th>
            <th scope="col">Adet</th>
            <th scope="col">Peşin Fiyatı</th>
            <th scope="col">Ara Toplam (KDV Dahil)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">Zerduz Finansal Asistan</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td scope="row">Kargo Tutarı</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td scope="row"></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td scope="row">Kargo Hariç Toplam Ürün Bedeli</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td scope="row">Kargo Ücreti</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td scope="row">Taksit Farkı</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td scope="row">Toplam Sipariş Bedeli</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td scope="row">Ödeme Şekli ve Planı</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td scope="row">Teslimat Adresi</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td scope="row">Teslim Edilecek Kişi</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td scope="row">Fatura Adresi</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td scope="row">Sipariş Tarihi</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td scope="row">Teslim Şekli</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td scope="row">Teslimat Süresi*</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td scope="row">Kargo Şirketi’ne Teslim Süresi**</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <p>*Sözleşme ve ilgili mevzuat hükümlerinde yer alan istisnalar saklıdır.
        <br>**Belirtilen süre teslimatın taahhüdü değildir, satıcı tarafından kargo şirketine teslim edilme süresini ifade
        eder.
      </p>
      <p><strong>6.3.</strong>SÖZ KONUSU ÜRÜN BEDELİ, “ZERDUZ ALICI GÜVENCESİ” KAPSAMINDA SATICI ADINA, ZERDUZ TARAFINDAN ALICI’DAN TAHSİL
        EDİLMEKTEDİR. ALICI MALIN BEDELİNİ ZERDUZ'A ÖDEMEKLE, ÜRÜN BEDELİNİ SATICI’YA ÖDEMİŞ SAYILACAK VE BİR DAHA ÖDEME
        YÜKÜMLÜLÜĞÜ ALTINA GİRMEYECEKTİR. ALICI’NIN İLGİLİ MEVZUAT KAPSAMINDA İADE HAKLARI SAKLIDIR.
      </p>
      <h3>7. GENEL HÜKÜMLER</h3>
      <p><strong>7.1.</strong> Satıcı, Ürün/Hizmet’i eksiksiz, siparişte belirtilen niteliklere uygun ve varsa garanti belgeleri, kullanım
        kılavuzları ile mevzuat gereği Ürün/Hizmet’le birlikte teslim etmesi gereken sair bilgi ve belgeler ile teslim
        etmeyi kabul, beyan ve taahhüt eder.</p>
      <p><strong>7.2.</strong> Ürün, Alıcı veya Alıcı tarafından belirlenen üçüncü kişiye, taahhüt edilen teslim süresi içerisinde ve her
        halükârda 30 (otuz) günlük yasal süreyi aşmamak koşulu ile, Alıcı’nın Platform’da belirtmiş olduğu teslimat
        adresine Kargo Şirketi tarafından teslim edilir. Bu süre içerisinde Satıcı’nın edimini yerine getirmemesi
        durumunda Alıcı Sözleşme'yi feshedebilecektir. Ancak Alıcı’nın isteği veya kişisel ihtiyaçları doğrultusunda
        hazırlanan Ürün/Hizmet satışlarında teslim süresi ilgili 30 (otuz) günü aşabilecektir. Ayrıca, sipariş durumu “Ön
        Sipariş” veya “Sipariş Üzerine Üretim” olarak belirtilen Ürün/Hizmet için teslimat süresi de 30 (otuz) günü
        aşabilecek olup Alıcı, Alıcı’nın isteği veya kişisel ihtiyaçları doğrultusunda hazırlanan “Sipariş Üzerine Üretim”
        ya da “Ön Sipariş” statüsünde olan bir Ürün/Hizmet satın aldığında teslimatın 30 (otuz) gün içerisinde yapılmaması
        dolayısıyla Sözleşme’yi feshedemeyecektir.</p>
      <p><strong>7.3.</strong> Satıcı Ürün’ü Kargo Şirketi aracılığı ile Alıcı’ya göndermekte ve teslim ettirmektedir. Kargo Şirketi’nin
        Alıcı’nın bulunduğu yerde şubesi olmaması halinde Alıcı’nın Ürün’ü Kargo Şirketi’nin Satıcı tarafından bildirilen
        yakın bir diğer şubesinden teslim alması gerekmektedir.</p>
      <p><strong>7.4.</strong> Alıcı’nın sipariş esnasında Ürün’ü ZERDUZ Teslimat Noktası’na teslim edilmesini seçmesi halinde, Ürün, Kargo
        Şirketi tarafından Alıcı’nın seçmiş olduğu teslimat noktasına taahhüt edilen süre içerisinde ve her halükârda en
        geç 30 (otuz) günlük yasal süre içerisinde teslim edilecektir. Ürün’ün ZERDUZ Teslimat Noktası’na bırakılmasının
        ardından Alıcı, seçmiş olduğu teslimat adresi bilgilerinde kayıtlı olan telefon numarasına gelen kod ile en geç 3
        (üç) gün içerisinde Ürün’ü teslim alacaktır.</p>
      <p><strong>7.5.</strong> Alıcı’nın herhangi bir nedenle Ürün/Hizmet’i teslim almaması halinde, Alıcı’nın Ürün/Hizmet’i iade ettiği
        kabul edilecek olup bu halde, varsa teslimat masrafları da dâhil olmak üzere Alıcı’dan tahsil edilen tüm ödemeler
        yasal süresi içerisinde Alıcı’ya iade edilecektir.</p>
      <p><strong>7.6.</strong> Alıcı veya Alıcı tarafından belirlenen üçüncü kişinin teslim anında adreste bulunmaması durumunda Alıcı'nın
        Ürün/Hizmet’i geç teslim almasından ve/veya hiç teslim almamasından kaynaklanan zararlardan ve giderlerden Satıcı
        sorumlu değildir.</p>
      <p><strong>7.7.</strong> Ürün/Hizmet’in teslimat masrafları aksine bir hüküm yoksa Alıcı’ya aittir. Satıcı, Platform’da teslimat
        ücretinin kendisince karşılanacağını beyan etmişse teslimat masrafları Satıcı'ya ait olacaktır.</p>
      <p><strong>7.8.</strong> Satıcı, Sözleşme'den doğan ifa yükümlülüğünün süresi dolmadan Alıcı’yı Platform üzerinden bilgilendirmek ve
        açıkça onayını almak suretiyle muadil bir Ürün/Hizmet tedarik edebilecektir.</p>
      <p><strong>7.9.</strong> Ürün/Hizmet ediminin yerine getirilmesinin imkansızlaştığı hallerde Satıcı’nın bu durumu öğrendiği tarihten
        itibaren 3 (üç) gün içinde Alıcı’ya yazılı olarak veya veri saklayıcısı ile bildirmesi ve varsa teslimat
        masrafları da dâhil olmak üzere tahsil edilen tüm ödemeleri bildirim tarihinden itibaren en geç 14 (ondört) gün
        içinde iade etmesi zorunludur. Ürün/Hizmet’in stokta bulunmaması durumu, Ürün/Hizmet ediminin yerine
        getirilmesinin imkânsızlaşması olarak kabul edilmez.</p>
      <p><strong>7.10.</strong> Alıcı, Ürün’ü teslim almadan önce muayene edecek; ezik, kırık, ambalajı yırtılmış vb. hasarlı, ayıplı veya
        eksik Ürün/Hizmet’i teslim almayacaktır. Teslim alınan Ürün/Hizmet’in hasarsız ve sağlam olduğu kabul edilecektir.
        Teslimden sonra Ürün’ün özenle korunması borcu, Alıcı’ya aittir. Cayma hakkı kullanılacaksa Ürün/Hizmet
        kullanılmamalı ve Ürün/Hizmet faturası ve teslim sırasında Alıcı’ya iletilen diğer tüm belgeler (örneğin garanti
        belgesi, kullanım kılavuzu vb.) ile birlikte iade edilmesi gerekmektedir.</p>
      <p><strong>7.11.</strong> Alıcı, Sözleşme konusu bedeli ödemekle yükümlü olup, herhangi bir nedenle Sözleşme konusu bedelin
        ödenmemesinin ve/veya Banka kayıtlarında iptal edilmesinin Satıcı’nın Ürün/Hizmet’i teslim yükümlülüğü ile
        Sözleşme’den kaynaklanan sair yükümlülüklerinin sona ereceğini kabul, beyan ve taahhüt eder. Alıcı, herhangi bir
        sebeple Banka tarafından başarısız kodu gönderilen ancak buna rağmen Banka tarafından Satıcı’ya yapılan ödemelere
        ilişkin olarak, Satıcı’nın herhangi bir sorumluluğunun bulunmadığını kabul, beyan ve taahhüt eder.</p>
      <p><strong>7.12.</strong> Alıcı, Ürün’ün teslim edilmesinden sonra Alıcı’ya ait kredi kartının yetkisiz kişilerce haksız kullanılması
        sonucunda Sözleşme konusu bedelin ilgili Banka tarafından Satıcı’ya ödenmemesi halinde, Alıcı Ürün’ü 3 (üç) gün
        içerisinde iade masrafları Alıcı’ya ait olacak şekilde Satıcı’ya iade edeceğini kabul, beyan ve taahhüt eder.</p>
      <p><strong>7.13.</strong> Sözleşme kapsamında herhangi bir nedenle Alıcı’ya bedel iadesi yapılması gereken durumlarda, Alıcı, ödemeyi
        kredi kartı ile yapmış olması halinde, Satıcı tarafından kredi kartına iade edilen tutarın banka tarafından Alıcı
        hesabına yansıtılmasına ilişkin ortalama sürecin 2 (iki) ile 3 (üç) haftayı bulabileceğini, bu tutarın Satıcı
        tarafından Banka’ya iadesinden sonra Alıcı’nın hesaplarına yansıması halinin tamamen Banka işlem süreci ile ilgili
        olduğunu ve olası gecikmelerde Banka’nın sorumlu olduğunu ve bunlar için ZERDUZ’u ve Satıcı’yı sorumlu
        tutamayacağını kabul, beyan ve taahhüt eder.</p>
      <p><strong>7.14.</strong> Alıcı ile sipariş esnasında kullanılan kredi kartı hamilinin aynı kişi olmaması veya Ürün/Hizmet’in
        Alıcı’ya tesliminden evvel, siparişte kullanılan kredi kartına ilişkin güvenlik açığı tespit edilmesi halinde,
        kredi kartı hamiline ilişkin kimlik ve iletişim bilgilerini, siparişte kullanılan kredi kartının bir önceki aya
        ait ekstresini yahut kart hamilinin Banka’dan kredi kartının kendisine ait olduğuna ilişkin yazıyı ibraz etmesini
        Alıcı’dan talep edilebilecektir. Alıcı’nın talebe konu bilgi/belgeleri temin etmesine kadar geçecek sürede sipariş
        dondurulacak olup, söz konusu taleplerin 24 (yirmidört) saat içerisinde karşılanmaması halinde ise Satıcı,
        siparişi iptal etme hakkını haizdir.</p>
      <p><strong>7.15.</strong> Alıcı Sözleşme konusu bedeli ZERDUZ Cüzdan aracılığıyla ödemesi ve bu siparişe ilişkin cayma hakkını
        kullanması halinde cayma hakkından kaynaklı bedel iadesi ZERDUZ Cüzdan’a tek seferde yapılabilecektir.</p>
      <p><strong>7.16.</strong> Sipariş edilmeyen Ürün/Hizmet’in gönderilmesi durumunda, Alıcı’ya karşı herhangi bir hak ileri sürülemez.
        Bu hallerde, Alıcı’nın sessiz kalması ya da söz konusu Ürün/Hizmet’i kullanmış olması, sözleşmenin kurulmasına
        yönelik kabul beyanı olarak yorumlanamaz.</p>
      <p><strong>7.17.</strong> Alıcı’nın sipariş edebileceği Ürün/Hizmet adetlerine Platform’dan yapılacak duyurularla kısıt
        getirilebilir. Alıcı’nın Platform’dan yapılan duyurularda belirtilen adetin üzerinde Ürün/Hizmet sipariş etmek
        istemesi halinde sipariş vermesi engellenebilecek, siparişi verdikten sonra belirtilen adedin üstünde sipariş
        verdiğinin tespit edebilmesi halinde ise belirtilen adedin üstündeki siparişleri iptal edilebilecek ve bu halde
        varsa iptal edilen siparişlere ilişkin teslimat masrafları da dâhil olmak üzere tahsil edilen tüm ödemeler yasal
        süresi içerisinde Alıcı’ya iade edilecektir. Alıcı işbu hususları kabul ederek siparişini oluşturduğunu, adet
        sınırlamasını geçen siparişlerinin engellenebileceği ve iptal edilebileceğini kabul, beyan ve taahhüt eder.</p>
      <p><strong>7.18.</strong> Satıcı’nın herhangi bir sebeple tedarik edemediği siparişler, Alıcı’nın onayının alınması halinde,
        mevzuattaki yasal teslim süresini aşmamak ve Alıcı’nın Ürün/Hizmet ile aynı özellikleri haiz olmak kaydıyla, bir
        başka satıcıya aktarılabilecektir. Böyle bir durumda Ürün/Hizmet yeni satıcı tarafından Alıcı’ya gönderilecek olup
        Sözleşme yeni satıcı ile Alıcı arasında kurulmuş olacaktır. Bu halde, Alıcı’ya herhangi bir ek bedel, ücret
        ve/veya masraf yansıtılmayacaktır.</p>
      <p><strong>7.19.</strong> Alıcı tüketici sıfatıyla talep, şikayet ve önerilerini yukarıda yer alan Satıcı iletişim bilgilerini
        kullanmak suretiyle ve/veya Platform’un sağladığı kanallarla (0 212 331 0 200 telefon numarası üzerinden ve
        “ZERDUZ Asistan’a Sor” başlıklı alandan) ulaştırabilecektir.</p>
      <h3>8. ÖZEL ŞARTLAR</h3>
      <p><strong>8.1.</strong> Alıcı, aksi belirtilmedikçe, Platform’da birden fazla butikten tek bir sepette alışveriş yapabilecektir.
        Aynı sepet içerisinde farklı butikten alınan her bir Ürün/Hizmet için Satıcı tarafından birden fazla fatura
        kesilebilecektir. Şüpheye mahal bırakmamak bakımından belirtilmelidir ki, Satıcı, Alıcı’nın farklı butiklerden
        aldığı Ürün/Hizmet’in teslimatını mevzuattaki yasal süre içerisinde kalmak koşuluyla farklı zamanlarda
        gerçekleştirebilecektir.</p>
      <p><strong>8.2.</strong> Alıcı’nın vereceği siparişlerde kurumsal fatura seçeneğini seçmesi durumunda Satıcı, Alıcı tarafından
        Platform üzerinden bildirilecek vergi kimlik numarası ve vergi dairesi bilgilerini kullanarak kurumsal fatura
        düzenleyecektir. Faturada yer alması gereken bilgilerin doğru, güncel ve eksiksiz girilmesi tamamen Alıcı’nın
        sorumluluğunda olup, bu sebeple doğabilecek tüm zararlardan bizzat Alıcı sorumludur.</p>
      <p><strong>8.3.</strong> Platform üzerinden kredi kart ile ödeme yapılması halinde, Banka tarafından kampanyalar düzenlenerek Alıcı
        tarafından seçilen taksit adedinin daha üstünde bir taksit adedi uygulanabilecek veya taksit erteleme gibi ek
        hizmetler sunulabilecektir. Bu tür kampanyalar Banka’nın inisiyatifindedir. Alıcı’nın kredi kartının hesap kesim
        tarihinden itibaren sipariş toplamı taksit adedine bölünerek kredi kartı özetine Banka tarafından yansıtılacaktır.
        Banka taksit tutarlarını küsurat farklarını dikkate alarak aylara eşit olarak dağıtmayabilir. Detaylı ödeme
        planlarının oluşturulması Banka’nın inisiyatifindedir.</p>
      <p><strong>8.4.</strong> Dijital ürünler fiziki gönderime uygun olmayıp, teslimat ürün niteliğine göre şartlarında belirtilen şekilde
        gerçekleştirilecektir. Sözleşme’nin yer alıp da teslimat şekilleri vb. gibi fiziki ürünler için geçerli olan
        düzenlemeler dijital ürünlere uygulanmayacak olup bu maddelerdeki düzenlemeler uygulanabilir olduğu ölçüde ürün
        şartlarında belirtilen koşul ve açıklamalara uygun olacak şeklinde yorumlanmalıdır.</p>
      <p><strong>8.5</strong>. Sipariş verilen Ürün’ün elektrikli motosiklet olması halinde kurulumu gerçekleştikten veya tescil işlemi
        yapılıp ruhsatlandıktan sonra Platform üzerinden iadesi mümkün olmamaktadır.</p>
      <p><strong>8.6.</strong> Platformda satışa sunulan Ürün/Hizmetler yalnızca Satıcı tarafından belirlenen sınırlı lokasyonlara
        (il/ilçe/bölge) teslim edilmek üzere satışa sunulabilecek olup, sipariş sürecinde Alıcı'nın bu ürün/hizmetler için
        teslimat adresini Satıcı tarafından belirlenmiş olan lokasyonlardan biri dışında seçmesi halinde ilgili sipariş
        verilemeyecek/satın alım gerçekleşmeyecektir.</p>
      <p><strong>8.7.</strong> Türkiye Cumhuriyeti resmi kamu kurum ve kuruluşları ile koordineli yürütülen "Depreme Yardım Seferberliği"
        ve benzeri seferberlik ve yardım işlemleriyle bağlantılı siparişlerde (ö: koli yardımı, vb.), Mesafeli Sözleşmeler
        Yönetmeliği’nin 15/1-h maddesi gereği cayma hakkı kullanılamayacaktır.</p>
      <h3>9. KİŞİSEL VERİLERİN KORUNMASI VE FİKRİ-SINAİ HAKLAR</h3>
      <p><strong>9.1.</strong> Satıcı, işbu sözleşme kapsamındaki kişisel verileri sadece Ürün/Hizmet’in sunulması amacıyla sınırlı olarak
        ve 6698 sayılı Kişisel Verilerin Korunması Kanunu’na, (“KVKK”) ikincil mevzuata ve Kişisel Verileri Koruma Kurulu
        kararlarına uygun olarak işleyecektir. Satıcı, Platform üzerinden eriştiği kişisel veriler haricinde Alıcı’nın
        kişisel verilerini işlemeyeceğini ve Platform üzerinden sağlanan yöntemler dışında Alıcı ile harici olarak
        iletişime geçmeyeceğini kabul, beyan ve taahhüt eder.</p>
      <p><strong>9.2.</strong> Alıcı, işbu Sözleşme kapsamında sağladığı kişisel verilerin doğru, eksiksiz ve güncel olduğunu kontrol
        etmekle, bu bilgileri üçüncü kişilerle paylaşmamak, ilgisiz kişilerce erişilememesi için virüs ve benzeri zararlı
        uygulamalara ilişkin olanlar dahil gerekli tedbirleri almak ve söz konusu kişisel verilerin güvenliğini sağlamakla
        yükümlü olduğunu, aksi halde doğacak zararlardan ve üçüncü kişilerden gelen taleplerden bizzat sorumlu olduğunu
        kabul, beyan ve taahhüt eder.</p>
      <p><strong>9.3.</strong> Platform’a ait her türlü bilgi ve içerik ile bunların düzenlenmesi, revizyonu ve kısmen/tamamen kullanımı
        konusunda; Satıcı'nın anlaşmasına göre diğer üçüncü şahıslara ait olanlar hariç; tüm fikri-sınai haklar ve
        mülkiyet hakları ZERDUZ’a aittir.</p>
      <h3>10. CAYMA HAKKI</h3>
      <p><strong>10.1.</strong> Alıcı, 15 (onbeş) gün içinde herhangi bir gerekçe göstermeksizin ve cezai şart ödemeksizin Sözleşme’den
        cayma hakkına sahiptir.</p>
      <p><strong>10.2.</strong> Cayma hakkı süresi, Hizmet için Sözleşme’nin kurulduğu gün; Ürün için Alıcı’nın veya Alıcı tarafından
        belirlenen üçüncü kişinin Ürün’ü teslim aldığı gün başlar. Ancak Alıcı, Sözleşme’nin kurulmasından Ürün teslimine
        kadar olan süre içinde de cayma hakkını kullanabilir.</p>
      <p><strong>10.3.</strong> Cayma hakkı süresinin belirlenmesinde;</p>
      <ul class="list-unstyled">
        <li class="ms-4">
          <strong>a) </strong> Tek sipariş konusu olup ayrı ayrı teslim edilen Ürün’de, Alıcı veya Alıcı tarafından
          belirlenen üçüncü kişinin son Ürün’ü teslim aldığı gün,
        </li>
        <li class="ms-4">
          <strong>b) </strong> Birden fazla parçadan oluşan Ürün’de, Alıcı veya Alıcı tarafından belirlenen üçüncü
          kişinin son parçayı teslim aldığı gün,
        </li>
        <li class="ms-4">
          <strong>c) </strong> Belirli bir süre boyunca Ürün’ün düzenli tesliminin yapıldığı durumlarda, Alıcı veya
          Alıcı tarafından belirlenen üçüncü kişinin ilk Ürün’ü teslim aldığı gün
          esas alınır.
        </li>
      </ul>
      <p><strong>10.4.</strong> Ürün teslimi ile Hizmet ifasının birlikte olduğu durumlarda, Ürün teslimine ilişkin cayma hakkı hükümleri
        uygulanır.</p>
      <p><strong>10.5.</strong> Satıcı;</p>
      <ul class="list-unstyled">
        <li class="ms-4">
          <strong>a) </strong> Ürün'ün teslimi veya Hizmet’in ifasından önce Alıcı’nın cayma hakkını kullanması
          durumunda cayma hakkının kullanıldığına ilişkin bildirimin kendisine ulaştığı tarihten itibaren,
        </li>
        <li class="ms-4">
          <strong>b) </strong> Ürün’ün tesliminden sonra Alıcı’nın cayma hakkını kullanması durumunda, cayma
          bildiriminin kendisine ulaştığı tarih itibarıyla bedel Satıcı’ya aktarılmamışsa cayma hakkına konu Ürün’ün, iade
          için öngörülen Kargo Şirketi’ne teslim edildiği tarihten veya iade için öngörülenin haricinde bir Kargo Şirketi
          ile iade edilmesi durumunda da Satıcı’ya ulaştığı tarihten itibaren,
        </li>
        <li class="ms-4">
          <strong>c) </strong> Alıcı’nın siparişinin yasal süre içerisinde teslim edilememesi nedeniyle Sözleşme’yi
          fesih hakkını kullanması durumunda fesih bildiriminin kendisine ulaştığı tarihten itibaren
          15 (onbeş) gün içinde, tahsil ettiği Sözleşme konusu bedeli ile teslimat masraflarının Alıcı’ya iadesinden
          sorumludur.
        </li>
      </ul>
      <p><strong>10.6.</strong> Cayma hakkı bildiriminin ve Sözleşme’ye ilişkin diğer bildirimlerin mevzuata uygun ve süresi içerisinde
        Platform’da belirtilen ZERDUZ’a ve/veya Satıcı’ya ait iletişim kanallarından yapılması şarttır. Cayma bildiriminin
        yapılacağı iletişim kanallarına https://www.zerduz.com/contact linkinden ulaşılabilmektedir.</p>
      <p><strong>10.7.</strong> Cayma hakkının kullanılması halinde:</p>
      <ul class="list-unstyled">
        <li class="ms-4">
          <strong>a) </strong> Alıcı cayma hakkını kullanmasından itibaren 14 (ondört) gün içerisinde Ürün’ü
          Satıcı'ya Kargo Şirketi’yle geri gönderir.
        </li>
        <li class="ms-4">
          <strong>b) </strong> Cayma hakkı kapsamında iade edilecek Ürün kutusu, ambalajı, varsa standart
          aksesuarları ve varsa Ürün ile birlikte hediye edilen diğer Ürünler’in de eksiksiz ve hasarsız olarak iade
          edilmesi gerekmektedir.
        </li>
      </ul>
      <p><strong>10.8.</strong> Alıcı cayma süresi içinde Ürün’ü, işleyişine, teknik özelliklerine ve kullanım talimatlarına uygun bir
        şekilde kullandığı takdirde meydana gelen değişiklik ve bozulmalardan sorumlu değildir.</p>
      <p><strong>10.9.</strong> Cayma hakkının kullanılmasını takip eden 14 (ondört) gün içerisinde Sözleşme konusu bedeller Alıcı’ya
        Alıcı’nın ödeme yöntemi ile iade edilmektedir. Ürün/Hizmet, Satıcı'ya iade edilirken, Ürün/Hizmet’in teslimi
        sırasında Alıcı’ya ibraz edilmiş olan orijinal faturanın da Alıcı tarafından iade edilmesi gerekmektedir.
        Alıcı’nın kurumsal fatura istemesi halinde ilgili Ürün/Hizmet iadesi için iade faturası kesmesi veya mümkünse
        süresi içerisinde ticari faturayı kendi sistemlerinden reddetmesi gerekmektedir.</p>
      <p><strong>10.10.</strong> Alıcı iade edeceği Ürün/Hizmet’i Ön Bilgilendirme Formu’nda belirtilen Satıcı'nın Kargo Şirketi ile
        Satıcı'ya gönderdiği sürece iade kargo bedeli Satıcı'ya aittir. İade için Alıcı’nın bulunduğu yerde Satıcı’nın
        Kargo Şirketi şubesi bulunmaması halinde, Alıcı Ürün’ü herhangi bir Kargo Şirketi’yle gönderebilecektir. Bu halde
        iade kargo bedeli ve Ürün’ün kargo sürecinde uğrayacağı hasardan Satıcı sorumludur.</p>
      <p><strong>10.11.</strong> Alıcı cayma hakkını işbu maddede belirtilen süre ve usuller dahilinde kullanacak olup aksi halde cayma
        hakkını kaybedecektir.</p>
      <h3>11. CAYMA HAKKININ KULLANILAMAYACAĞI HALLER</h3>
      <p><strong>11.1.</strong> Alıcı aşağıdaki sözleşmelerde cayma hakkını kullanamaz:</p>
      <ul class="list-unstyled">
        <li class="ms-4">
          <strong>a) </strong>Fiyatı finansal piyasalardaki dalgalanmalara bağlı olarak değişen ve Satıcı veya ZERDUZ’un
          kontrolünde olmayan mal veya hizmetlere ilişkin sözleşmeler,
        </li>
        <li class="ms-4">
          <strong>b) </strong> Tüketicinin istekleri veya kişisel ihtiyaçları doğrultusunda hazırlanan mallara ilişkin
          sözleşmeler,
        </li>
        <li class="ms-4">
          <strong>c) </strong> Çabuk bozulabilen veya son kullanma tarihi geçebilecek malların teslimine ilişkin
          sözleşmeler,
        </li>
        <li class="ms-4">
          <strong>d) </strong> Tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olan
          mallardan; iadesi
          sağlık ve hijyen açısından uygun olmayanların teslimine ilişkin sözleşmeler,
        </li>
        <li class="ms-4">
          <strong>e) </strong> Tesliminden sonra başka ürünlerle karışan ve doğası gereği ayrıştırılması mümkün olmayan
          mallara
          ilişkin sözleşmeler,
        </li>
        <li class="ms-4">
          <strong>f) </strong> Ürünün tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olması
          halinde
          maddi ortamda sunulan kitap, dijital içerik ve bilgisayar sarf malzemelerine ilişkin sözleşmeler,
        </li>
        <li class="ms-4">
          <strong>g) </strong> Abonelik sözleşmesi kapsamında sağlananlar dışında gazete ve dergi gibi süreli yayınların
          teslimine
          ilişkin sözleşmeler,
        </li>
        <li class="ms-4">
          <strong>h) </strong> Belirli bir tarihte veya dönemde yapılması gereken, konaklama, eşya taşıma, araba kiralama,
          yiyecek
          içecek tedariki ve eğlence veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin
          sözleşmeler,
        </li>
        <li class="ms-4">
          <strong>i) </strong>Elektronik ortamda anında ifa edilen hizmetler ile Alıcı’ya anında teslim edilen gayri maddi
          mallara
          ilişkin sözleşmeler,
        </li>
        <li class="ms-4">
          <strong>j) </strong> Cayma hakkı süresi sona ermeden önce, Alıcı’nın onayı ile ifasına başlanan hizmetlere
          ilişkin
          sözleşmeler,
          cayma hakkı kullanılamayacak; bu siparişler bakımından Platform üzerinden iade kodu da oluşturulamayacaktır.
        </li>
      </ul>
      <p><strong>11.2.</strong> Ürün/Hizmet’in Yönetmelik'in uygulama alanı dışında bırakılmış olan (Sözleşme’nin 3.3. maddesinde
        listelenmiştir) Ürün/Hizmet türlerinden müteşekkil olması halinde Alıcı ve Satıcı arasındaki hukuki ilişkiye
        Yönetmelik hükümleri uygulanamaması sebebiyle cayma hakkı kullanılamayacak; bu siparişler bakımından Platform
        üzerinden iade kodu da oluşturulamayacaktır.</p>
      <p><strong>11.3.</strong> Platform üzerinden elektronik kod satın alındığı durumlarda bahse konu siparişlerde Yönetmelik gereği cayma
        hakkı söz konusu olmayacaktır. Bu siparişler bakımından Platform üzerinden iade kodu da oluşturulamayacaktır.</p>
      <h3>12. UYUŞMAZLIKLARIN ÇÖZÜMÜ</h3>
      <p><strong>12.1.</strong> Sözleşme’nin uygulanmasında, Bakanlık’ça ilan edilen değerlere uygun olarak Alıcı’nın Ürün/Hizmet’i satın
        aldığı ve ikametğahının bulunduğu yerdeki Tüketici Hakem Heyetleri ile Tüketici Mahkemeleri yetkilidir.</p>
      <h3>13. BİLDİRİMLER ve DELİL SÖZLEŞMESİ</h3>
      <p><strong>13.1.</strong> Sözleşme tahtında Taraflar arasında yapılacak her türlü yazışma, mevzuatta sayılan zorunlu haller dışında,
        yazılı olarak yapılacaktır.</p>
      <p><strong>13.2.</strong> Alıcı, Sözleşme'den doğabilecek ihtilaflarda ZERDUZ’un ve Satıcı’nın ticari defter, bilgisayar, kayıt ve
        diğer belgelerinin bağlayıcı, kesin ve münhasır delil teşkil edeceğini, bu maddenin Hukuk Muhakemeleri Kanunu'nun
        193. maddesi anlamında delil sözleşmesi niteliğinde olduğunu kabul, beyan ve taahhüt eder.</p>
      <h3>14. YÜRÜRLÜK</h3>
      <p><strong>14.1.</strong> 14 (on dört) maddeden ibaret Sözleşme, Taraflar’ca okunarak, işlem tarihinde, Alıcı tarafından elektronik
        ortamda onaylanmak suretiyle akdedilmiş ve yürürlüğe girmiştir. Sözleşme’nin bir nüshası Alıcı’nın üyelik
        hesabında mevcuttur ve talep edilmesi halinde ayrıca elektronik posta ile de gönderilebilecektir.</p>
    </div>
  </div>
</template>

<script>

export default {
  name: "MesafeliSatisSozlesmesi",
};
</script>

  <style scoped src="../assets/css/pages.css"/>

<template>
<div class="overflow-x-hidden" id="Purchase">
    <h1 class="text-center mt-5" id="page-header-text">{{ $t('purchasePage.header') }}</h1>

    <div class="container">
        <div class="row align-items-center">
            <div class="col-auto col-sm-12 col-md-6 col-lg-6 mx-auto" id="col-1">
                <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <vue-image-zoomer :regular="getImgUrl('new-1')" img-class="d-block mx-auto w-100" />
                        </div>
                        <div class="carousel-item">
                            <vue-image-zoomer :regular="getImgUrl('new-2')" img-class="d-block mx-auto w-100" />
                        </div>
                        <div class="carousel-item">
                            <vue-image-zoomer :regular="getImgUrl('new-3')" img-class="d-block mx-auto w-100" />
                        </div>
                        <div class="carousel-item">
                            <vue-image-zoomer :regular="getImgUrl('zerduz-box-c')" img-class="d-block mx-auto w-100" />
                        </div>
                    </div>
                    <button class="carousel-control-prev pe-3" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next ps-3" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            <div class="col-md-auto mx-auto" id="col-2">
                <div class="d-flex flex-column mt-0 flex-md-row gap-4 pt-5 align-items-center justify-content-center">
                    <div class="list-group list-group-radio d-grid gap-2 border-0">
                        <div class="position-relative">
                            <input class="form-check-input d-none top-50 end-0 me-3 fs-5" type="radio" name="listGroupRadioGrid" id="listGroupRadioGrid1" value="" checked>
                            <label class="list-group-item py-3 pe-5" for="listGroupRadioGrid1">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <strong class="fw-semibold">{{ $t('productName') }}</strong>
                                        <span class="d-block small opacity-75">{{ $t('purchasePage.productSpecsDescription') }}</span>
                                    </div>
                                    <div class="col">
                                        <h6 class="d-block text-body-emphasis text-end" id="page-header-text">{{ $t('purchasePage.price') }}: {{ $store.state.price.currency }}{{ formatPrice($store.state.price.string.withoutTax) }} <small v-if="quantityInput > 1" class="text-secondary fw-light text-nowrap" style="font-size:13px;">({{ quantityInput }}x {{ $store.state.price.currency }}{{ formatPrice(String((quantityInput * $store.state.price.price.withoutTax).toFixed(2))) }})</small></h6>
                                    </div>
                                </div>
                            </label>
                            <small class="d-flex justify-content-end text-secondary text-opacity-75 mt-2 text-kdv">{{ $t('taxsArentIncluded') }}</small>
                        </div>
                        <div class="form-floating mb-2">
                            <input type="number" class="form-control" id="floatingQuantity" v-model="quantityInput" min="1" placeholder="Adet">
                            <label for="floatingQuantity">{{ $t('purchasePage.piece') }}</label>
                        </div>
                    </div>
                </div>

                <ul class="list-group mb-3">
                    <form @submit.prevent="tryAddToCart" id="addToCart">
                        <div id="alertbox"></div>
                        <div class="d-grid gap-2">
                            <button class="btn btn-primary" type="submit">{{ $t('purchasePage.addToCart') }}</button>
                        </div>
                    </form>
                </ul>

            </div>
        </div>
    </div>

    <h1 class="text-center mt-5 mb-4" id="box-content-header-text">{{ $t('purchasePage.boxContent[0]') }}</h1>

    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-sm-12 col-md-auto col-lg-6 col-12">
                <div>
                    <img src="../assets/img/zerduz-box.png" class="d-block mx-auto w-100" alt="...">
                </div>
            </div>
            <div class="col-sm-12 col-md-auto col-lg-6 col-12">
                <div class="row d-flex align-items-center">
                    <div class="col mb-4">
                        <div class="card border-0 shadow">
                            <div class="card-body text-center p-5">
                                <h5 class="card-title mb-0" id="content-img-caption">Zerduz</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-4">
                        <div class="card border-0 shadow">
                            <div class="card-body text-center p-5">
                                <h5 class="card-title mb-0" id="content-img-caption">{{ $t('purchasePage.boxContent[1]') }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row d-flex align-items-center">
                    <div class="col mb-4">
                        <div class="card border-0 shadow">
                            <div class="card-body text-center p-5">
                                <h5 class="card-title mb-0" id="content-img-caption">{{ $t('purchasePage.boxContent[2]') }}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-4">
                        <div class="card border-0 shadow">
                            <div class="card-body text-center p-5">
                                <h5 class="card-title mb-0" id="content-img-caption">{{ $t('purchasePage.boxContent[3]') }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <h1 class="text-center mt-5" id="box-content-header-text">{{ $t('purchasePage.productSpecsTitle') }}</h1>
    <div class="container px-4 pt-5 feature-color">
        <div class="row">
            <div class="col feature-shadow">
                <div class="row row-cols-1 row-cols-sm-2 g-4">
                    <div class="col d-flex flex-column gap-2" v-for="(spec, index) in $tm('purchasePage.productSpecs')" :key="index">
                        <div class="feature-icon-small d-inline-flex align-items-center justify-content-center fs-4 rounded-3">
                            <i :class="spec.icon"></i>
                        </div>
                        <h4 class="fw-semibold mb-0">{{ spec.title }}</h4>
                        <p class="text-white-75" v-html="spec.description"></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="px-4 my-5 text-center pt-1">
            <h2 class="text-center mt-5 p-1" id="page-header-text">{{ $t('purchasePage.appsTitle') }}</h2>
            <div class="col-lg-6 mx-auto">
                <p class="lead mb-5">{{ $t('purchasePage.appsDesc') }}</p>
            </div>
        </div>
    </div>

    <div class="row row-cols-1 row-cols-md-12 pb-4 mb-3">
        <div class="app-slider" style="transform: rotate(7deg); scale: 1.09;">
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/kuyumhaberapp.png" class="rounded-5 mx-auto d-block" id="appImg" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/ziynetfiyatapp.png" class="rounded-5 mx-auto d-block" id="appImg" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/zerduzapp.png" class="rounded-5 mx-auto d-block" id="appImg" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/zerchatapp.png" class="rounded-5 mx-auto d-block" id="appImg" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/obimapp.png" class="rounded-5 mx-auto d-block" id="appImg" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/ajandaapp.png" class="rounded-5 mx-auto d-block" id="appImg" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/bibliotecoroapp.png" class="rounded-5 mx-auto d-block" id="appImg" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/borsaapp.png" class="rounded-5 mx-auto d-block" id="appImg" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/coinapp.png" class="rounded-5 mx-auto d-block" id="appImg" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/kariyerapp.png" class="rounded-5 mx-auto d-block" id="appImg" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/korgezmeapp.png" class="rounded-5 mx-auto d-block" id="appImg" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/kuyummarketapp.png" class="rounded-5 mx-auto d-block" id="appImg" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/notlarapp.png" class="rounded-5 mx-auto d-block" id="appImg" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/odaapp.png" class="rounded-5 mx-auto d-block" id="appImg" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/sarrafapp.png" class="rounded-5 mx-auto d-block" id="appImg" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/tasapp.png" class="rounded-5 mx-auto d-block" id="appImg" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/zepostapp.png" class="rounded-5 mx-auto d-block" id="appImg" alt="...">
                </div>
            </div>
        </div>
    </div>
    <div class="row row-cols-1 row-cols-md-12 pb-4 mb-3">
        <div class="app-slider2" style="transform: rotate(7deg); scale: 1.09;">
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/kuyumhaberapp.png" class="rounded-5 mx-auto d-block" id="appImg2" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/ziynetfiyatapp.png" class="rounded-5 mx-auto d-block" id="appImg2" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/zerduzapp.png" class="rounded-5 mx-auto d-block" id="appImg2" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/zerchatapp.png" class="rounded-5 mx-auto d-block" id="appImg2" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/obimapp.png" class="rounded-5 mx-auto d-block" id="appImg2" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/ajandaapp.png" class="rounded-5 mx-auto d-block" id="appImg2" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/bibliotecoroapp.png" class="rounded-5 mx-auto d-block" id="appImg2" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/borsaapp.png" class="rounded-5 mx-auto d-block" id="appImg2" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/coinapp.png" class="rounded-5 mx-auto d-block" id="appImg2" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/kariyerapp.png" class="rounded-5 mx-auto d-block" id="appImg2" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/korgezmeapp.png" class="rounded-5 mx-auto d-block" id="appImg2" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/kuyummarketapp.png" class="rounded-5 mx-auto d-block" id="appImg2" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/notlarapp.png" class="rounded-5 mx-auto d-block" id="appImg2" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/odaapp.png" class="rounded-5 mx-auto d-block" id="appImg2" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/sarrafapp.png" class="rounded-5 mx-auto d-block" id="appImg2" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/tasapp.png" class="rounded-5 mx-auto d-block" id="appImg2" alt="...">
                </div>
            </div>
            <div>
                <div class="col-md-4 w-50 me-4">
                    <img src="../assets/img/zepostapp.png" class="rounded-5 mx-auto d-block" id="appImg2" alt="...">
                </div>
            </div>
        </div>
    </div>

    <div class="container text-center mt-5">
        <a href="Apps" class="btn btn-outline-primary rounded-pill">{{ $t('purchasePage.appsShowAll') }}</a>
    </div>

    <div class="container py-3 mt-5">
        <div class="container px-4">
            <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
                <div class="col-lg-6">
                    <img src="../assets/img/new-3.png" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy">
                </div>
                <div class="col-lg-6">
                    <h1 class="display-5 fw-bold text-body-emphasis lh-1 mb-3">{{ $t('purchaseSection.title') }}</h1>
                    <p class="lead">{{ $t('purchaseSection.description') }}</p>
                    <div>
                        <h1 class="fs-3 fw-normal mb-3 d-flex align-items-center justify-content-between">{{ $store.state.price.currency }}{{ formatPrice($store.state.price.string.withoutTax) }}<small class="text-secondary text-opacity-75 mt-2 text-kdv">{{ $t('taxsArentIncluded') }}</small></h1>
                        <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                            <a href="#Purchase" class="btn btn-primary btn-lg px-4 me-md-2 rounded-pill">{{ $t('purchaseButtonText') }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import $ from "jquery";
import "../assets/js/bootstrap.bundle";
import {
    addToCart,
} from '@/helpers/api';
import {
    alert
} from "@/helpers/funcs";
import '../assets/js/slick.min';

import { VueImageZoomer } from 'vue-image-zoomer'
import 'vue-image-zoomer/dist/style.css';

export default {
    name: "PurchaseIT",
    components: {
        VueImageZoomer
    },
    data() {
        return {
            token: window.localStorage.getItem('token'),
            userId: window.localStorage.getItem('userId'),
            cartData: window.localStorage.getItem('cart'),
            quantityInput: 1,
        }
    },
    methods: {
        getImgUrl(img) {
            var images = require.context('../assets/img/', false, /\.png$/)
            return images('./' + img + ".png")
        },
        formatPrice(price) {
            if (price) {
                const cleanedPrice = price.replace(/[^\d,.-]/g, '');
                const floatValue = parseFloat(cleanedPrice.replace(',', '.'));

                var decimalPart = (floatValue % 1 === 0) ? '00' : (floatValue % 1).toFixed(2).split('.')[1];

                const integerPart = Math.floor(floatValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                if (decimalPart == '00') {
                    decimalPart = null;
                }
                const result = `${integerPart}${decimalPart ? ',' + decimalPart : ''}`;

                return result;
            } else {
                return 0;
            }
        },
        shuffleApps() {
            var firstSliderApps = $('.app-slider > div');
            var secondSliderApps = $('.app-slider2 > div');

            firstSliderApps.sort(function () {
                return 0.5 - Math.random();
            });
            secondSliderApps.sort(function () {
                return 0.5 - Math.random();
            });

            $('.app-slider').html(firstSliderApps);
            $('.app-slider2').html(secondSliderApps);
        },
        generatePayorderID() {
            const newPayorderID = "Z" + Math.floor(Math.random() * 1000000000000).toString();
            return newPayorderID;
        },
        async tryAddToCart() {
            if (this.token != null && this.userId != null) {

                var cartData = window.localStorage.getItem('cart');
                cartData = this.cartData ? JSON.parse(this.cartData) : [];
                var payorderPayload = null;
                if (cartData.length > 0) {
                    if (cartData[0].payorder_id == null || cartData[0].payorder_id == "") {
                        payorderPayload = this.generatePayorderID();
                    } else {
                        payorderPayload = cartData[0].payorder_id;
                    }
                } else {
                    payorderPayload = this.generatePayorderID();
                }
                var {
                    success
                } = await addToCart(this.token, 1, parseInt(this.quantityInput), payorderPayload, true);

                if (success) {
                    var currProductId = 1;
                    var currQuantity = this.quantityInput;
                    var match = false;
                    cartData.forEach(element => {
                        if (element.productId == currProductId) {
                            element.cartQuantity = String(parseInt(element.cartQuantity) + parseInt(currQuantity));
                            match = true;
                        }
                    });
                    if (match == false) {
                        var newCartItem = {
                            productId: currProductId,
                            cartQuantity: currQuantity,
                            payorder_id: payorderPayload
                        };
                        cartData.push(newCartItem);
                    }
                    this.$store.dispatch('changeCartItem', {
                        cart: cartData
                    });
                    window.localStorage.setItem('cart', JSON.stringify(cartData));

                    this.$router.push('/cart');
                } else {
                    alert("#alertbox", "Ürün sepete eklenirken hata oluştu.", "danger", "mt-2");
                }

            } else if (this.token == null && this.userId == null) {
                cartData = window.localStorage.getItem('cart');
                cartData = this.cartData ? JSON.parse(this.cartData) : [];
                currProductId = 1;
                currQuantity = this.quantityInput;
                match = false;
                cartData.forEach(element => {
                    if (element.productId == currProductId) {
                        element.cartQuantity = String(parseInt(element.cartQuantity) + parseInt(currQuantity));
                        match = true;
                    }
                });
                if (match == false) {
                    newCartItem = {
                        productId: currProductId,
                        cartQuantity: currQuantity,
                        payorder_id: this.generatePayorderID()
                    };
                    cartData.push(newCartItem);
                }
                this.$store.dispatch('changeCartItem', {
                    cart: cartData
                });
                window.localStorage.setItem('cart', JSON.stringify(cartData));

                this.$router.push('/cart');
            }
        }
    },
    mounted() {
        this.shuffleApps();
        $(".app-slider").not('.slick-initialized').slick({
            infinite: true,
            autoplay: true,
            autoplaySpeed: 0,
            speed: 5000,
            cssEase: 'linear',
            slidesToShow: 7,
            slidesToScroll: 1,
            pauseOnFocus: false,
            pauseOnHover: true,
            arrows: false,
            draggable: false,
            variableWidth: true,
            responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 2,
                    }
                }
            ]
        });
        $(".app-slider2").not('.slick-initialized').slick({
            infinite: true,
            autoplay: true,
            autoplaySpeed: 0,
            speed: 6000,
            cssEase: 'linear',
            slidesToShow: 7,
            slidesToScroll: 3,
            pauseOnFocus: false,
            pauseOnHover: false,
            arrows: false,
            draggable: false,
            variableWidth: true,
            responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 3,
                    }
                }
            ]
        });

        $("#floatingQuantity").keydown(function () {
            $(this).data("old", $(this).val());
        });

        $("#floatingQuantity").keyup(function () {
            if (!$(this).val() || (parseInt($(this).val()) >= 1))
            ;
            else
            if ($(this).data("old") <= 0)
                $(this).val(1);
            else
                $(this).val($(this).data("old"));
        });
    },
};
</script>


<style scoped src="../assets/css/purchase.css"/>
<style scoped>
@import '../assets/css/slick.css';
</style>
<style scoped>
@import '../assets/css/slick-theme.css';
</style>

<template>
    <div class="container g-0">
        <div class="toast-container position-fixed top-0 end-0 p-lg-5 p-md-5 p-sm-3 p-3">
            <div class="toast border-0 fade bg-body" :id="toast.id" :class="{ 'show': toast.show }" @hidden="this.$closeToast(toast.id)" role="alert" aria-live="assertive" aria-atomic="true" v-for="(toast, index) in toasts[0]" :key="index">
                <div class="toast-header">
                    <img v-if="toast.showLogo" src="../assets/img/logobim.png" class="rounded me-2" width="32" alt="">
                    <span class="me-auto fs-6">{{ toast.title }}</span>
                    <button v-if="toast.dismissable" type="button" class="btn-close" @click="this.$closeToast(toast.id)" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    {{ toast.description }}
                </div>
            </div>
        </div>
        <nav class="navbar navbar-expand-lg bg-body-tertiary d-flex flex-wrap mb-4 px-md-10">
            <div class="container-fluid">
                <div class="col-md-3 mb-4 mb-md-0 d-none d-lg-block">
                    <router-link to="/" active-class="" exact-active-class=""
                        class="navbar-brand d-inline-flex link-body-emphasis text-decoration-none">
                        <img src="../assets/img/logobim.png" alt="" style="height: 74px; width: auto;">
                    </router-link>
                </div>

                <button class="navbar-toggler collapsed border-0" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <router-link to="/" active-class="" exact-active-class=""
                    class="navbar-brand d-none d-lg-inline-flex link-body-emphasis text-decoration-none zerduz-logo mx-auto">
                    <img src="../assets/img/text-logo.png" alt="" style="height: 54px; width: auto;">
                </router-link>

                <router-link to="/" active-class="" exact-active-class=""
                    class="navbar-brand d-lg-none d-inline-flex link-body-emphasis text-decoration-none mx-auto">
                    <img src="../assets/img/text-logo.png" alt="" style="height: 40px; width: auto;">
                </router-link>

                <div v-if="$store.getters.cartItemCount == 0" class="me-2 d-lg-none align-items-center mt-1">
                    <router-link to="/Purchase" active-class="" exact-active-class="" class="btn btn-primary btn-sm">{{ $t('purchaseButtonText') }}</router-link>
                </div>

                <div v-else class="me-2 d-lg-none align-items-center mt-1">
                    <div class="row align-items-center">
                        <div class="col dropdown dropdown-center">
                            <router-link class="nav-link position-relative" aria-expanded="false" to="/Cart" active-class=""
                                exact-active-class=""><i class="fa-solid fa-cart-shopping fa-xl"></i><span
                                    class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger badge-color">{{
                                        $store.getters.cartItemCount }}</span></router-link>
                        </div>
                    </div>
                </div>

                <div class="col-md-3 mb-4 text-end mb-md-0 d-none d-lg-block">
                    <div class="row align-items-center g-4">
                        <div class="col dropdown dropdown-center">
                            <router-link class="nav-link position-relative" aria-expanded="false" to="/Cart" active-class=""
                                exact-active-class=""><i class="fa-solid fa-cart-shopping fa-xl"></i><span
                                    class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger badge-color">{{
                                        $store.getters.cartItemCount }}</span></router-link>
                            <ul v-if="$store.getters.cartItemCount > 0" class="dropdown-menu mt-3 animate slideIn" style="width: 25rem;">
                                <li class="m-2">
                                    <h6 class="dropdown-header">{{ $t('headerPage.cart.amountText[0]') }} ({{ $store.getters.cartItemCount }} {{ $t('headerPage.cart.amountText[1]') }}) {{ $store.getters.cartItemPayorderID }}</h6>
                                </li>
                                <li class="m-1">
                                    <hr class="dropdown-divider opacity-50">
                                </li>
                                <div :class="{ 'cart-scrollable': $store.getters.cartItemCount > 3 }">
                                    <li v-for="(cartItem, index) in $store.state.cartItem" :key="index">
                                        <a to="/Purchase" class="text-decoration-none link-dark">
                                            <div class="row ms-1 my-3 align-items-center">
                                                <div class="col-md-3">
                                                    <img class="p-2" src="../assets/img/zerduz-2.png" width="100"
                                                        height="100" />
                                                </div>
                                                <div class="col-md-8 ms-3">
                                                    <div class="col-md-6 mt-1">
                                                        <h6>Zerduz</h6>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="col mt-2">
                                                            <small class="text-muted text-nowrap">{{ $store.getters.cartQuantity }} {{ $t('headerPage.cart.quantity') }}</small>
                                                        </div>
                                                        <div class="col mt-2">
                                                            <h5 class="text-zerduz">{{ $store.state.price.currency }}{{ formatPrice(String((parseInt($store.getters.cartQuantity) *
                                                                $store.state.price.price.withoutTax).toFixed(2))) }}<small class="text-end d-flex text-secondary text-opacity-75 mt-2 text-kdv text-nowrap">{{ $t('taxsArentIncluded') }}</small></h5>
                                                                
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </div>
                                <li class="m-1">
                                    <hr class="dropdown-divider opacity-50">
                                </li>

                                <li class="m-3">
                                    <div class="d-flex gap-2 justify-content-center">
                                        <router-link to="/Cart" active-class="" exact-active-class=""
                                            class="btn btn-outline-dark d-inline-flex rounded-0 align-items-center" type="button">
                                            {{ $t('headerPage.cart.goCartBtn') }}
                                        </router-link>
                                        <router-link to="/Checkout" active-class="" exact-active-class=""
                                            class="btn btn-primary align-items-center rounded-0 btn-full-width" type="button">
                                            {{ $t('headerPage.cart.completeOrderBtn') }}
                                        </router-link>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="col-auto">
                            <select class="py-2 px-3 rounded bg-zerduz text-light border-0 cursor-pointer fw-bold language-selector" v-model="this.$i18n.locale" @change="saveLanguage" aria-label="Zerduz language selector.">
                                <option value="tr">TR</option>
                                <option value="en">EN</option>
                                <option value="ar">AR</option>
                                <option value="fr">FR</option>
                            </select>

                            <!-- <div class="dropdown-center justify-content-center d-flex">
                                <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="fa-solid fa-globe fa-lg pe-1"></i>
                                </button>
                                <ul class="dropdown-menu mt-5 text-center fw-bold">
                                    <li><a class="dropdown-item" href="#">TR</a></li>
                                    <li><a class="dropdown-item" href="#">EN</a></li>
                                    <li><a class="dropdown-item" href="#">FR</a></li>
                                    <li><a class="dropdown-item" href="#">AR</a></li>
                                </ul>
                            </div> -->
                        </div>
                        <div class="col col-md-3 me-lg-3 ps-0">
                            <router-link to="/Purchase" active-class="" exact-active-class=""
                                class="btn btn-primary px-3 me-md-2 text-nowrap">{{ $t('purchaseButtonText') }}</router-link>
                        </div>
                    </div>
                </div>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav flex-fill d-flex justify-content-between border-bottom">
                        <li class="nav-item">
                            <router-link class="nav-link" to="/Guide">{{ $t('headerPage.menu[0].title') }}</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/Specs">{{ $t('headerPage.menu[1].title') }}</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/Sellers">{{ $t('headerPage.menu[2].title') }}</router-link>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" target="_blank" href="https://kuyumhaber.com/">{{ $t('headerPage.menu[3].title') }}</a>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/SSS">{{ $t('headerPage.menu[4].title') }}</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/Contact">{{ $t('headerPage.menu[5].title') }}</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/Apps">{{ $t('headerPage.menu[6].title') }}</router-link>
                        </li>
                        <li class="nav-item dropdown-center">
                            <router-link class="nav-link align-items-center " data-bs-toggle="dropdown"
                                aria-expanded="false" to="/Account">{{ $t('headerPage.menu[7].title') }}</router-link>
                            <ul class="dropdown-menu p-1 mt-2 animate slideIn shadow-sm">
                                <li v-if="!isLoggedIn">
                                    <div class="vstack gap-2 col-md-9 my-4 mx-auto">
                                        <router-link type="button" class="btn btn-primary" active-class="" to="/Login">{{ $t('headerPage.menu[7].sub[0].logIn') }}</router-link>
                                        <router-link type="button" class="btn btn-outline-dark" active-class=""
                                            to="/Register">{{ $t('headerPage.menu[7].sub[0].register') }}</router-link>
                                    </div>
                                </li>
                                <div v-else>
                                    <li class="m-1">
                                        <h6 class="dropdown-header text-zerduz">{{ $store.state.userName }}</h6>
                                    </li>
                                    <li class="m-1"><router-link class="dropdown-item" to="/Account/Orders"><small><i
                                                    class="fa-solid fa-boxes-stacked"></i>&nbsp;
                                                {{ $t('headerPage.menu[7].sub[1].orders') }}</small></router-link></li>
                                    <li class="m-1"><router-link class="dropdown-item" to="/Account/Settings"><small><i
                                                    class="fa-solid fa-user-gear"></i>&nbsp;
                                                {{ $t('headerPage.menu[7].sub[1].settings') }}</small></router-link></li>
                                    <li class="m-1"><router-link class="dropdown-item" to="/Account/Help"><small><i
                                                    class="fa-solid fa-circle-question"></i>&nbsp;
                                                {{ $t('headerPage.menu[7].sub[1].help') }}</small></router-link></li>

                                    <li class="m-1">
                                        <hr class="dropdown-divider">
                                    </li>
                                    <li class="m-1"><router-link active-class="" to="/Logout"
                                            class="dropdown-item"><small><i
                                                    class="fa-solid fa-right-from-bracket"></i>&nbsp;
                                                {{ $t('headerPage.menu[7].sub[1].logout') }}</small></router-link></li>
                                </div>

                            </ul>
                        </li>
                        <li class="nav-item d-block d-sm-block d-md-none d-lg-none mb-3">
                            <div class="col col-md-2">
                                <select class="form-select bg-zerduz text-light w-25 border-0 cursor-pointer fw-bold language-selector" v-model="this.$i18n.locale" @change="saveLanguage" aria-label="Zerduz language selector.">
                                    <option value="tr">TR</option>
                                    <option value="en">EN</option>
                                    <option value="ar">AR</option>
                                    <option value="fr">FR</option>
                                </select>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import $ from 'jquery';
import { getCartData } from '@/helpers/api';
import { showToasts, getCurrency } from '@/helpers/funcs.js';
export default {
    name: "HeaderPage",
    data() {
        return {
            token: window.localStorage.getItem('token'),
            userId: window.localStorage.getItem('userId'),
            cartData: [],
            cartItems: [],
            cartItemCount: 0,
            toasts: []
        };
    },
    methods: {
        saveLanguage() {
            localStorage.setItem('lang', String(this.$i18n.locale));
        },
        formatPrice(price) {
            if (price) {
                const cleanedPrice = price.replace(/[^\d,.-]/g, '');
                const floatValue = parseFloat(cleanedPrice.replace(',', '.'));

                var decimalPart = (floatValue % 1 === 0) ? '00' : (floatValue % 1).toFixed(2).split('.')[1];

                const integerPart = Math.floor(floatValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                if (decimalPart == '00') {
                    decimalPart = null;
                }
                const result = `${integerPart}${decimalPart ? ',' + decimalPart : ''}`;

                return result;
            } else {
                return 0;
            }
        },
    },
    async created() {
        var price = await this.$getAllPrices();
        this.$store.dispatch('changePrice', { price: price });
        this.$store.dispatch('changeCurrency', { currency: getCurrency(price.currency) });
        this.$store.dispatch('changeCurrencyType', { currencyType: price.currency });
        
        if (this.token != null && this.userId != null) {
            const { success, data } = await getCartData(this.token);
            if (success) {
                if (data.data != null && data.data != []) {
                    if (data.data.carts != [] && data.data.carts.length > 0) {
                        if (!(data.data.carts[0].payorder_id == "" || data.data.carts[0].payorder_id == null)) {
                            var sessionCartData = localStorage.getItem('cart');
                            if (typeof sessionCartData !== 'undefined') {
                                if (sessionCartData != null && ((JSON.parse(sessionCartData)[0]) && (JSON.parse(sessionCartData)[0].cartQuantity))) {
                                    var parsed = JSON.parse(sessionCartData)[0];
                                    if (parsed.cartQuantity !== data.data.cartQuantity) {
                                        var newSessionCartData = [];
                                        newCartItem = { productId: 1, cartQuantity: data.data.carts[0].quantity, payorder_id: data.data.carts[0].payorder_id };
                                        newSessionCartData.push(newCartItem);
                                        this.$store.dispatch('changeCartItem', { cart: newSessionCartData });
                                        if (window.localStorage.getItem('token') != null && window.localStorage.getItem('userId') != null) {
                                            window.localStorage.setItem('cart', JSON.stringify(newSessionCartData));
                                        }
                                    }
                                } else {
                                    newSessionCartData = [];
                                    newCartItem = { productId: 1, cartQuantity: data.data.carts[0].quantity, payorder_id: data.data.carts[0].payorder_id };
                                    newSessionCartData.push(newCartItem);
                                    this.$store.dispatch('changeCartItem', { cart: newSessionCartData });
                                    if (window.localStorage.getItem('token') != null && window.localStorage.getItem('userId') != null) {
                                        window.localStorage.setItem('cart', JSON.stringify(newSessionCartData));
                                    }
                                }
                            }
                            var newCartItem = { productId: 1, cartQuantity: data.data.carts[0].quantity, payorder_id: data.data.carts[0].payorder_id };
                            this.cartData.push(newCartItem);
                            this.cartItems = this.cartData;
                            this.$store.dispatch('changeCartItem', { cart: this.cartItems });
                            this.cartItemCount = this.cartData.length;
                        }
                    } else {
                        localStorage.removeItem('cart');
                    }
                    this.$store.dispatch('changeUsername', { username: success ? (data.data.first_name && data.data.last_name ? `${data.data.first_name} ${data.data.last_name}` : data.data.first_name || data.data.user_name) : '' });
                }
            } else {
                this.cartData = localStorage.getItem('cart');
                if (this.cartData) {
                    parsedCartData = JSON.parse(this.cartData);
                    this.cartItems = parsedCartData;
                    this.cartItemCount = parsedCartData.length;
                }
                // const authTimedOutErrors = ["Invalid token", "User not authorized or auth time out", "customer not activated"];
                // if (authTimedOutErrors.includes(response.error)) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('userId');
                    localStorage.removeItem('expirationDate');
                    localStorage.removeItem('cart');
                    this.$store.dispatch('changeCartItem', { cart: undefined });
                    this.$store.dispatch('logout');
                    if (this.$route.meta.requiresLogin) {
                        this.$router.push('/login');
                    }
                // }
            }
        } else {
            this.cartData = localStorage.getItem('cart');
            if (this.cartData) {
                var parsedCartData = JSON.parse(this.cartData);
                this.cartItems = parsedCartData;
                this.$store.dispatch('changeCartItem', { cart: this.cartItems });
                this.cartItemCount = parsedCartData.length;
            }
        }
    },
    mounted() {
        setInterval(() => {
            this.toasts = [showToasts()];
        }, 100);
        $('.dropdown-center').hover(function () {
            $(this).find('.dropdown-menu')
                .stop(true, true).delay(100).fadeIn(100);
        }, function () {
            $(this).find('.dropdown-menu')
                .stop(true, true).delay(100).fadeOut(100);
        });
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters.isAuthenticated;
        }
    }
};
</script>

<style scoped>
.language-selector {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
</style>

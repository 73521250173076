import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import store from './store'

import { fetchUserData, sendFunnel, sendViewEvent } from '@/helpers/tracker';

import './assets/css/bootstrap.min.css';
import './assets/css/general.css';
import { createI18n } from 'vue-i18n';

import trLocale from './locales/tr.json';
import enLocale from './locales/en.json';
import frLocale from './locales/fr.json';
import arLocale from './locales/ar.json';

import { uuid } from "vue-uuid";
import { getPrice, getAllPrices, showToast, hideToast, closeToast, toasts } from './helpers/funcs.js';

var eventUUID = uuid.v1();

// document.body.addEventListener("click", sendClickEvent, false);

fetchUserData(eventUUID);

const app = createApp(App);

const getUserCountry = async () => {
    try {
        const response = await fetch('https://ipapi.co/json');
        const data = await response.json();
        var country = '';
        var language = '';
        switch (data.country_code) {
            case 'TR':
                language = 'tr';
                country = 'tr';
            break;
            case 'FR':
                language = 'fr';
                country = 'fr';
            break;
            case 'AR':
                language = 'ar';
                country = 'ar';
            break;
            case 'SA':
                language = 'ar';
                country = 'sa';
            break;
            case 'AE':
                language = 'ar';
                country = 'ae';
            break;
            case 'GB':
                language = 'en';
                country = 'gb';
            break;
            default: 
                language = 'en';
                country = 'en';
            break;
        }
        return { country, language };
    } catch (error) {
        return { country: 'en', language: 'en', error };
    }
};

export const i18n = createI18n({
    locale: 'tr',
    fallbackLocale: 'en',
    messages: {
        tr: trLocale,
        en: enLocale,
        fr: frLocale,
        ar: arLocale,
    },
});

if (localStorage.getItem('lang') && localStorage.getItem('lang') != "") {
    i18n.global.locale = String(localStorage.getItem('lang'));
} else {
    getUserCountry().then((userCountry) => {
        i18n.global.locale = userCountry.language;
        localStorage.setItem('lang', userCountry.language);
    });   
}

var globalCountry = 'select';

if (localStorage.getItem('country') && localStorage.getItem('country') != "" && localStorage.getItem('country') != "select") {
    globalCountry = String(localStorage.getItem('country'));
} else {
    if (localStorage.getItem('country') == "select") {
        globalCountry = 'select';
        localStorage.removeItem('country');
    } else {
        getUserCountry().then((userCountry) => {
            if (i18n.global.tm('footerPage.countries').map(item => item.value).includes(userCountry.country)) {
                globalCountry = userCountry.country;
                localStorage.setItem('country', userCountry.country);
            } else {
                globalCountry = 'select';
                localStorage.removeItem('country');
            }
        }); 
    }
}

app.config.globalProperties.$country = globalCountry;

app.config.globalProperties.$toastsData = toasts;
app.config.globalProperties.$showToast = showToast;
app.config.globalProperties.$hideToast = hideToast;
app.config.globalProperties.$closeToast = closeToast;

app.config.globalProperties.$getPrice = getPrice;
app.config.globalProperties.$getAllPrices = getAllPrices;
app.config.globalProperties.$sendFunnel = sendFunnel;
app.config.globalProperties.$sendViewEvent = sendViewEvent;

app.use(i18n);

app.config.globalProperties.$i18n = i18n.global;

app.use(store).use(router).mount('#app')

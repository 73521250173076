<template>
  <div>
    <div class="container g-4 px-md-10">
      <div class="px-4 my-5 text-center">
        <h1 class="mt-5 p-1">Kişisel Verilerin Korunması</h1>
        <h3 class="mt-5 p-1">OBİM TEKNOLOJİ VE DIŞ TİCARET LİMİTED ŞİRKETİ
          İnternet Sitesi Gizlilik Çerez Politikası
        </h3>
        <p>Versiyon 1.0</p>
        <p>01/05/2023</p>
      </div>
      <h3><strong>İçindekiler</strong></h3>
      <p><a href="#1" class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">1. GİRİŞ</a></p>
      <p><a href="#1-1" class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">1.1 Politikanın Amacı ve Kapsamı</a></p>
      <p><a href="#1-2" class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">1.2 Yürürlük ve Değişiklik</a></p>
      <p><a href="#2" class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">2 .ŞİRKETİMİZİN YÜRÜTTÜĞÜ KİŞİSEL VERİ İŞLEME FAALİYETLERİNE YÖNELİK VERİ SAHİPLERİ, VERİ İŞLEME AMAÇLARI VE VERİ
        KATEGORİLERİ</a></p>
      <p><a href="#2-1" class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">2.1 Veri Sahipleri</a></p>
      <p><a href="#2-2" class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">2.2 Kişisel Veri İşleme Amaçları</a></p>
      <p><a href="#2-3" class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">2.3 Kişisel Veri Kategorileri</a></p>
      <p><a href="#3" class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">3. KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİN İLKELER VE ŞARTLAR</a></p>
      <p><a href="#3-1" class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">3.1 Kişisel Verilerin İşlenmesine İlişkin İlkeler</a></p>
      <p><a href="#3-2" class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">3.2 Kişisel Verilerin İşlenmesine İlişkin Şartlar</a></p>
      <p><a href="#3-3" class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">3.3 Özel Nitelikli Kişisel Verilerin İşlenmesine İlişkin Şartlar</a></p>
      <p><a href="#4" class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">4. KİŞİSEL VERİLERİN AKTARILMASI</a></p>
      <p><a href="#5" class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">5. VERİ SAHİPLERİNİN AYDINLATILMASI VE VERİ SAHİPLERİNİN HAKLARI</a></p>
      <p><a href="#6" class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">6. KİŞİSEL VERİLERİN SİLİNMESİ, YOK EDİLMESİ, ANONİM HALE GETİRİLMESİ</a></p>
      <p><a href="#7" class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">7. KANUN KAPSAMI VE UYGULANMASINA İLİŞKİN KISITLAMALAR</a></p>
      <br>
      <h3 id="1">1. Giriş</h3>
      <h4 id="1-1">1.1. Politikanın Amacı ve Kapsamı</h4>
      <p>6698 sayılı Kişisel Verilerin Korunması Kanunu (“<strong> Kanun</strong>”) 7 Nisan 2016 tarihinde yürürlüğe
        girmiş olup; işbu OBİM
        TEKNOLOJİ VE DIŞ TİCARET LİMİTED ŞİRKETİ Kişisel Verilerin İşlenmesi ve Korunması Politikası (“<strong>
          Politika</strong>”), OBİM
        TEKNOLOJİ VE DIŞ TİCARET LİMİTED ŞİRKETİ’nin (“<strong> ZERDUZ</strong>” veya<strong> Şirket</strong>”) Kanuna
        uyumluluğunun sağlanmasını ve <strong>ZERDUZ</strong>
        tarafından kişisel verilerin korunması ve işlenmesine ilişkin yükümlülüklerin yerine getirilmesinde uyulacak
        prensiplerin belirlenmesini amaçlamaktadır.</p>
      <p>Politika, kişisel verilerin işleme şartlarını belirlemekte ve kişisel verilerin işlenmesinde ZERDUZ tarafından
        benimsenen ana ilkeleri ortaya koymaktadır. Bu çerçevede Politika, ZERDUZ tarafından Kanun kapsamındaki tüm
        kişisel veri işleme faaliyetlerini, ZERDUZ’un işlediği tüm kişisel verilerin sahiplerini ve işlediği tüm kişisel
        verileri kapsamaktadır.</p>
      <p>ZERDUZ çalışanlarının kişisel verilerinin işlenmesine ilişkin hususlar, işbu Politika’nın kapsamında olmayıp,
        OBİM TEKNOLOJİ VE DIŞ TİCARET LİMİTED ŞİRKETİ Çalışan Kişisel Verilerinin İşlenmesi ve Korunması Politikası’nda
        ayrıca düzenlenmektedir.</p>
      <p>Politika’da kullanılan terimlere ilişkin tanımlar EK-1’de bulunmaktadır.</p>
      <h4 id="1-2">1.2. Yürürlük ve Değişiklik</h4>
      <p>Politika, ZERDUZ tarafından internet sitesinde yayımlanarak kamuoyuna sunulmuştur. Başta Kanun olmak üzere
        yürürlükteki mevzuat ile bu Politika’da yer verilen düzenlemelerin çelişmesi halinde mevzuat hükümleri uygulanır.
      </p>
      <p><strong>ZERDUZ</strong>, yasal düzenlemelere paralel olarak Politika’da değişiklik yapma hakkını saklı tutar.
        Politika’nın güncel
        versiyonuna ZERDUZ web sitesinden erişilebilir.</p>
      <h3 id="2">2. Şirketimizin Yürüttüğü Kişisel Veri İşleme Faaliyetlerine Yönelik Veri Sahipleri, Veri İşleme Amaçları Ve
        Veri Kategorileri|</h3>
      <h4 id="2-1">2.1. Veri Sahipleri</h4>
      <p>Politika kapsamındaki veri sahipleri, <strong>ZERDUZ</strong> tarafından kişisel verileri işlenmekte olan
        <strong>ZERDUZ</strong>
        çalışanları
        dışındaki tüm gerçek kişilerdir. Bu çerçevede genel olarak veri sahibi kategorileri aşağıdaki şekildedir:
      </p>
      <table class="table table-light table-bordered">
        <thead>
          <tr class="text-center align-middle">
            <th scope="col"></th>
            <th scope="col">VERİ SAHİBİ KATEGORİLERİ</th>
            <th scope="col">AÇIKLAMA</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" class="align-middle text-center">1</td>
            <td class="fw-bold align-middle text-center">Müşteri</td>
            <td>ZERDUZ’un sunduğu ürün ve hizmetlerden yararlanan gerçek kişileri ifade etmektedir.</td>
          </tr>
          <tr>
            <td scope="row" class="align-middle text-center">2</td>
            <td class="fw-bold align-middle text-center">Potansiyel Müşteri</td>
            <td>ZERDUZ’un sunduğu ürün ve hizmetleri kullanma ilgisini gösteren, müşteriye dönüşme potansiyeli olan gerçek
              kişileri ifade etmektedir.</td>
          </tr>
          <tr>
            <td scope="row" class="align-middle text-center">3</td>
            <td class="fw-bold align-middle text-center">Ziyaretçi</td>
            <td>ZERDUZ şirket, mağaza, yerleşkele ve internet sitesini ziyaret eden gerçek kişileri ifade etmektedir.</td>
          </tr>
          <tr>
            <td scope="row" class="align-middle text-center">4</td>
            <td class="fw-bold align-middle text-center">Çalışan Adayı</td>
            <td>ZERDUZ’a CV göndererek veya başka yöntemlerle iş başvurusu yapan gerçek kişileri ifade etmektedir.</td>
          </tr>
          <tr>
            <td scope="row" class="align-middle text-center">5</td>
            <td class="fw-bold align-middle text-center">Üçüncü Kişiler</td>
            <td>Yukarıda yer verilen veri sahibi kategorileri ile ZERDUZ çalışanları hariç gerçek kişileri ifade
              etmektedir.</td>
          </tr>
        </tbody>
      </table>
      <br>
      <p>
        Veri sahibi kategorileri genel bilgi paylaşımı amacıyla belirtilmiştir. Veri sahibinin, bu kategorilerden herhangi
        birinin kapsamına girmemesi, Kanun’da belirtildiği şekilde veri sahibi niteliğini ortadan kaldırmamaktadır.
      </p>
      <h4 id="2-2">2.2. Kişisel Veri İşleme Amaçları</h4>
      <p>ZERDUZ tarafından kişisel verileriniz ve özel nitelikli kişisel verileriniz, Kanun’da ve ilgili mevzuatta yer
        alan kişisel veri işleme şartlarına uygun olarak aşağıdaki amaçlarla işlenebilmektedir:</p>
      <table class="table table-light table-bordered">
        <thead>
          <tr class="text-center">
            <th scope="col">ANA AMAÇLAR</th>
            <th scope="col">ALT AMAÇLAR</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="fw-bold align-middle text-center">ZERDUZ tarafından sunulan ürün ve hizmetlerden ilgili kişileri
              faydalandırmak
              için gerekli çalışmaların iş birimlerimiz tarafından yapılması ve ilgili iş süreçlerinin yürütülmesi</td>
            <td>
              <ul class="list-unstyled">
                <li class="ms-4 mb-1">
                  1. Ürün ve/veya Hizmetlerin Satış Süreçlerinin Planlanması ve İcrası
                </li>
                <li class="ms-4 mb-1">
                  2. Satış Sonrası Destek Hizmetleri Aktivitelerinin Planlanması ve/veya İcrası
                </li>
                <li class="ms-4 mb-1">
                  3. Müşteri İlişkileri Yönetimi Süreçlerinin Planlanması ve İcrası
                </li>
                <li class="ms-4 mb-1">
                  4. Sözleşme Süreçlerinin ve/veya Hukuki Taleplerin Takibi
                </li>
                <li class="ms-4 mb-1">
                  5. Müşterinin Sigortalanma Sürecinin Oluşturulması ve Takibi
                </li>
                <li class="ms-4 mt-4 mb-1 text-center">
                  Müşteri Talp ve/veya Şikayetlerinin Takibi
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">ZERDUZ İnsan Kaynakları Politikaları ve Süreçlerinin
              Planlanmasının ve İcra
              Edilmesi</td>
            <td>
              <ul class="list-unstyled">
                <li class="ms-4 mb-1">
                  1. Yetenek- Kariyer Gelişimi Faaliyetlerinin Planlanması ve İcrası
                </li>
                <li class="ms-4 mb-1">
                  2. Şirket Çalışanları İçin İş Akdi ve/veya Mevzuattan Kaynaklı Yükümlülüklerin Yerine Getirilmesi
                </li>
                <li class="ms-4 mb-1">
                  3. Çalışanlar İçin Yan Haklar ve Menfaatlerin Planlanması ve İcrası
                </li>
                <li class="ms-4 mb-1">
                  4. Şirket İçi Oryantasyon Aktivitelerinin Planlanması ve İcrası
                </li>
                <li class="ms-4 mb-1">
                  5. Personel Çıkış İşlemlerinin Planlanması ve İcrası
                </li>
                <li class="ms-4 mb-1">
                  6. Ücret Yönetimi
                </li>
                <li class="ms-4 mb-1">
                  7. İnsan Kaynakları Süreçlerinin Planlanması
                </li>
                <li class="ms-4 mb-1">
                  8. Personel Temin Süreçlerinin Yürütülmesi
                </li>
                <li class="ms-4 mb-1">
                  9. Şirket İçi Atama-Terfi ve İşten Ayrılma Süreçlerinin Planlanması ve İcrası
                </li>
                <li class="ms-4 mb-1">
                  10. Çalışanların Performans Değerlendirme Süreçlerinin Planlanması ve Takibi
                </li>
                <li class="ms-4 mb-1">
                  11. Çalışanların İş Faaliyetlerinin Takibi ve/veya Denetimi
                </li>
                <li class="ms-4 mb-1">
                  12. Şirket İçi Eğitim Faaliyetlerinin Planlanması ve/veya İcrası
                </li>
                <li class="ms-4 mb-1">
                  13. Çalışan Memnuniyetinin ve/veya Bağlılığı Süreçlerinin Planlanması ve İcrası
                </li>
                <li class="ms-4 mb-1">
                  14. Çalışanların İş ve/veya Üretim Süreçlerinin İyileştirilmesine Yönelik Önerilerin Alınması ve
                  Değerlendirilmesi Süreçlerinin Planlanması ve İcrası
                </li>
                <li class="ms-4 mb-1">
                  15. Stajyer ve/veya Öğrenci Temin, Yerleştirilmesi ve Operasyon Süreçlerinin Planlanması ve/veya İcrası
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">ZERDUZ tarafından yürütülen ticari faaliyetlerin
              gerçekleştirilmesi için ilgili iş birimlerimiz tarafından gerekli çalışmaların yapılması ve buna bağlı iş
              süreçlerinin yürütülmesi</td>
            <td>
              <ul class="list-unstyled">
                <li class="ms-4 mb-1">
                  1. Etkinlik Yönetimi
                </li>
                <li class="ms-4 mb-1">
                  2. İş Faaliyetlerinin Planlanması ve İcrası
                </li>
                <li class="ms-4 mb-1">
                  3. Kurumsal İletişim Faaliyetlerinin Planlanması ve İcrası
                </li>
                <li class="ms-4 mb-1">
                  4. Tedarik Zinciri Yönetimi Süreçlerinin Planlanması ve İcrası
                </li>
                <li class="ms-4 mb-1">
                  5. Üretim ve/veya Operasyon Süreçlerinin Planlanması ve İcrası
                </li>
                <li class="ms-4 mb-1">
                  6. Bilgi Güvenliği Süreçlerinin Planlanması, Denetimi ve İcrası
                </li>
                <li class="ms-4 mb-1">
                  7. Bilgi Teknolojileri Alt Yapısının Oluşturulması ve Yönetilmesi
                </li>
                <li class="ms-4 mb-1">
                  8. İş Ortakları ve/veya Tedarikçilerin Bilgiye Erişim Yetkilerinin Planlanması ve İcrası
                </li>
                <li class="ms-4 mb-1">
                  9. Finans ve/veya Muhasebe İşlerinin Takibi
                </li>
                <li class="ms-4 mb-1">
                  10. Kurumsal Sürdürülebilirlik Faaliyetlerin Planlanması ve İcrası
                </li>
                <li class="ms-4 mb-1">
                  11. Kurumsal Yönetim Faaliyetlerin Planlanması ve İcrası
                </li>
                <li class="ms-4 mb-1">
                  12. İş Sürekliğinin Sağlanması Faaliyetlerinin Planlanması ve/veya İcrası
                </li>
                <li class="ms-4 mb-1">
                  13. Lojistik Faaliyetlerinin Planlanması ve İcrası
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">ZERDUZ tarafından sunulan ürün ve hizmetlerin ilgili kişilerin
              beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek ilgili kişilere önerilmesi ve
              tanıtılması için gerekli olan aktivitelerin planlanması ve icrası</td>
            <td>
              <ul class="list-unstyled">
                <li class="ms-4 mb-1">
                  1. Pazarlama faaliyetlerine konu yapılacak kişilerin tüketici davranışı kriterleri doğrultusunda tespiti
                  ve/veya değerlendirilmesi
                </li>
                <li class="ms-4 mb-1">
                  2. Kişiye özel pazarlama ve/veya tanıtım aktivitelerinin tasarlanması ve/veya icrası
                </li>
                <li class="ms-4 mb-1">
                  3. Dijital ve/veya diğer mecralarda reklam ve/veya tanıtım ve/veya pazarlama aktivitelerinin
                  tasarlanması ve/veya icrası
                </li>
                <li class="ms-4 mb-1">
                  4. Dijital ve/veya diğer mecralarda müşteri kazanım ve/veya mevcut müşterilerde değer yaratımı üzerine
                  geliştirilecek aktivitelerin tasarlanması ve/veya icrası
                </li>
                <li class="ms-4 mb-1">
                  5. Pazarlama amacıyla veri analitiği çalışmalarının planlanması ve/veya icrası
                </li>
                <li class="ms-4 mb-1">
                  6. Ürün ve/veya Hizmetlerin Pazarlama Süreçlerinin Planlanması ve İcrası
                </li>
                <li class="ms-4 mb-1">
                  7. Şirketin Sunduğu Ürün ve/veya Hizmetlere Bağlılık Oluşturulması ve/veya Arttırılması Süreçlerinin
                  Planlanması ve/veya İcrası
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">ZERDUZ’un ticari ve/veya iş stratejilerinin planlanması ve icrası
            </td>
            <td>
              <ul class="list-unstyled">
                <li class="ms-4 mb-1">
                  1. İş Ortakları ve/veya Tedarikçilerle Olan İlişkilerin Yönetimi
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">ZERDUZ’un ve ZERDUZ ile iş ilişkisi içerisinde olan ilgili
              kişilerin hukuki, teknik ve ticari-iş güvenliğinin temin</td>
            <td>
              <ul class="list-unstyled">
                <li class="ms-4 mb-1">
                  1. Hukuk İşlerinin Takibi
                </li>
                <li class="ms-4 mb-1">
                  2. Şirket Faaliyetlerinin Şirket Prosedürleri ve/veya İlgili Mevzuata Uygun Olarak Yürütülmesinin Temini
                  İçin Gerekli Operasyonel Faaliyetlerinin Planlanması ve İcrası
                </li>
                <li class="ms-4 mb-1">
                  3. Yetkili Kuruluşlara Mevzuattan Kaynaklı Bilgi Verilmesi
                </li>
                <li class="ms-4 mb-1">
                  4. Ziyaretçi Kayıtlarının Oluşturulması ve Takibi
                </li>
                <li class="ms-4 mb-1">
                  5. Acil Durum Yönetimi Süreçlerinin Planlanması ve İcrası
                </li>
                <li class="ms-4 mb-1">
                  6. Şirketler ve Ortaklık Hukuku İşlemlerinin Gerçekleştirilmesi
                </li>
                <li class="ms-4 mb-1">
                  7. Şirket Denetim Faaliyetlerinin Planlanması ve İcrası
                </li>
                <li class="ms-4 mb-1">
                  8. İş Sağlığı ve/veya Güvenliği Süreçlerinin Planlanması ve/veya İcrası
                </li>
                <li class="ms-4 mb-1">
                  9. Kredi Süreçleri Risk Yönetiminin Gerçekleştirilmesi
                </li>
                <li class="ms-4 mb-1">
                  10. Şirket Yerleşkeleri ve/veya Tesislerinin Güvenliğinin Temini
                </li>
                <li class="ms-4 mb-1">
                  11. Şirket Operasyonlarının Güvenliğinin Temini
                </li>
                <li class="ms-4 mb-1">
                  12. Şirketin Finansal Risk Süreçlerinin Planlanması ve/veya İcrası
                </li>
                <li class="ms-4 mb-1">
                  13. Şirket Demirbaşlarının ve/veya Kaynaklarının Güvenliğinin Temini
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">Üçüncü Kişiler</td>
            <td>Yukarıda yer verilen veri sahibi kategorileri ile ZERDUZ çalışanları hariç gerçek kişileri ifade
              etmektedir.</td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">Üçüncü Kişiler</td>
            <td>Yukarıda yer verilen veri sahibi kategorileri ile ZERDUZ çalışanları hariç gerçek kişileri ifade
              etmektedir.</td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">Üçüncü Kişiler</td>
            <td>Yukarıda yer verilen veri sahibi kategorileri ile ZERDUZ çalışanları hariç gerçek kişileri ifade
              etmektedir.</td>
          </tr>
        </tbody>
      </table>
      <h4 id="2-3">2.3. Kişisel Veri Kategorileri</h4>
      <p><strong>ZERDUZ</strong> tarafından aşağıda kategorize edilmiş kişisel verileriniz, Kanun’da ve ilgili mevzuatta
        yer alan kişisel veri işleme şartlarına uygun olarak işlenmektedir:</p>
      <table class="table table-light table-bordered">
        <thead>
          <tr class="text-center align-middle">
            <th scope="col">KİŞİSEL VERİ KATEGORİZASYONU</th>
            <th scope="col">AÇIKLAMA</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="fw-bold align-middle text-center">Kimlik Bilgisi</td>
            <td>Ehliyet, nüfus cüzdanı, ikametgâh, pasaport, avukatlık kimliği, evlilik cüzdanı gibi dokümanlarda yer alan
              kişinin kimliğine dair tüm bilgiler</td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">İletişim Bilgisi</td>
            <td>Telefon numarası, adres, e-mail gibi veri sahibiyle iletişim kurulmasına yönelik bilgiler</td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">Müşteri Bilgisi</td>
            <td>Ticari faaliyetlerimiz ve bu çerçevede iş birimlerimizin yürüttüğü operasyonlar neticesinde ilgili kişi
              hakkında elde edilen ve üretilen bilgiler</td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">Aile Bireyleri ve Yakın Bilgisi</td>
            <td>Sunduğumuz ürün ve hizmetlerle ilgili veya Şirketin ve veri sahibinin hukuki menfaatlerini korumak
              amacıyla işlenen kişisel veri sahibinin aile bireyleri ve yakınları hakkındaki bilgiler</td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">Müşteri İşlem Bilgisi</td>
            <td>Ürün ve hizmetlerimizin kullanımına yönelik kayıtlar ile müşterinin ürün ve hizmetleri kullanımı için
              gerekli olan talimatları ve talepleri gibi bilgiler</td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">Fiziksel Mekân Güvenlik Bilgisi</td>
            <td>Fiziksel mekâna girişte, fiziksel mekânın içerisinde kalış sırasında alınan kamera kayıtları, parmak izi
              kayıtları gibi kayıtlar ve belgelere ilişkin kişisel veriler</td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">İşlem Güvenliği Bilgisi</td>
            <td>Ticari faaliyetlerimizi yürütürken teknik, idari, hukuki ve ticari güvenliğimizi sağlamamız için işlenen
              kişisel verileriniz</td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">Finansal Bilgi</td>
            <td>Şirketimizin kişisel veri sahibi ile kurmuş olduğu hukuki ilişkinin tipine göre yaratılan her türlü
              finansal sonucu gösteren bilgi, belge ve kayıtlara ilişkin işlenen kişisel veriler</td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">Çalışan Adayı Bilgisi</td>
            <td>Şirketimizin çalışanı olmak için başvuruda bulunmuş veya ticari teamül ve dürüstlük kuralları gereği
              şirketimizin insan kaynakları ihtiyaçları doğrultusunda çalışan adayı olarak değerlendirilmiş veya
              Şirketimizle çalışma ilişkisi içerisinde olan bireylerle ilgili işlenen kişisel veriler</td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">Hukuki İşlem ve Uyum Bilgisi</td>
            <td>Hukuki alacak ve haklarımızın tespiti, takibi ve borçlarımızın ifası ile kanuni yükümlülüklerimiz ve
              şirketimizin politikalarına uyum kapsamında işlenen kişisel veriler</td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">Denetim ve Teftiş Bilgisi</td>
            <td>Şirketimizin kanuni yükümlülükleri ve şirket politikalarına uyumu kapsamında işlenen kişisel veriler</td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">Özel Nitelikli Veri</td>
            <td>Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık
              ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik
              tedbirleriyle ilgili verileri ile biyometrik ve genetik verileri özel nitelikli kişisel veridir.</td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">Pazarlama Bilgisi</td>
            <td>Ürün ve hizmetlerimizin kişisel veri sahibinin kullanım alışkanlıkları, beğenisi ve ihtiyaçları
              doğrultusunda özelleştirilerek pazarlamasının yapılmasına yönelik işlenen kişisel veriler ve bu işleme
              sonuçları neticesinde yaratılan rapor ve değerlendirmeler</td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">Talep/Şikâyet Yönetimi Bilgisi</td>
            <td>Şirketimize yöneltilmiş olan her türlü talep veya şikâyetin alınması ve değerlendirilmesine ilişkin
              kişisel veriler</td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">İtibar Yönetimi Bilgisi</td>
            <td>Şirketimizin ticari itibarını korumak maksatlı toplanan bilgiler ve buna ilişkin oluşturulan değerlendirme
              raporları ile alınan aksiyonlarla ilgili bilgiler</td>
          </tr>
          <tr>
            <td class="fw-bold align-middle text-center">Olay Yönetimi Bilgisi</td>
            <td>Şirketimizin ticari hak ve menfaatleri ile müşterilerimizin hak ve menfaatlerini korumak maksatlı gelişen
              olaylara karşı gerekli hukuki, teknik ve idari tedbirlerin alınmasına yönelik olarak işlenen kişisel veriler
            </td>
          </tr>
        </tbody>
      </table>
      <h3 id="3">3. Kişisel Verilerin İşlenmesine İlişkin İlkeler ve Şartlar</h3>
      <h4 id="3-1">3.1. Kişisel Verilerin İşlenmesine İlişkin İlkeler</h4>
      <p><strong>ZERDUZ</strong> tarafından kişisel verileriniz, Kanun’un 4. maddesinde yer alan kişisel veri işleme
        ilkelerine uygun
        olarak işlenmektedir. Bu ilkelere her bir kişisel veri işleme faaliyeti açısından uyulması zorunludur:</p>
      <ul>
        <li><strong>Kişisel verilerin hukuka ve dürüstlük kurallarına uygun olarak işlenmesi; ZERDUZ</strong>, kişisel
          verilerinizin
          işlenmesinde kanunlara, ikincil düzenlemelere ve hukukun genel ilkelerine uygun olarak hareket eder; kişisel
          verileri işlenme amacı ile sınırlı olarak işlemeye ve veri sahiplerinin makul beklentilerini dikkate almaya önem
          verir.</li>
        <li>
          <strong>Kişisel verilerin doğru ve güncel olması; ZERDUZ</strong> tarafından işlenen kişisel verilerinizin
          güncel olup
          olmadığına, buna ilişkin kontrollerin yapılmasına dikkat edilir. Veri sahiplerine bu kapsamda doğru ve güncel
          olmayan verilerinin düzeltilmesini veya silinmesini isteme hakkı tanınır.
        </li>
        <li><strong>Kişisel verilerin belirli, açık ve meşru amaçlar için işlenmesi; ZERDUZ</strong>, her bir kişisel veri
          işleme
          faaliyetinden önce veri işleme amaçlarını tespit eder ve bu amaçların hukuka aykırı olmamasına dikkat eder.</li>
        <li><strong>Kişisel verilerin işlendiği amaçla bağlantılı, sınırlı ve ölçülü olması; ZERDUZ</strong> tarafından
          veri işleme
          faaliyeti toplama amacını gerçekleştirme için gerekli olan kişisel verilerle sınırlandırılmakta ve bu amaçla
          ilişkili olmayan kişisel verilerin işlenmemesi için gerekli adımlar atılmaktadır.</li>
        <li><strong>Kişisel verilerin mevzuatın ya da işleme amaçlarının gerektirdiği süre kadar saklanması;
            ZERDUZ</strong> tarafından
          kişisel veri işleme amacının ortadan kalkmasından sonra ya da mevzuatta öngörülen sürenin dolması ile birlikte
          kişisel veriler silinmekte, yok edilmekte veya anonimleştirilmektedir.</li>
      </ul>
      <h4 id="3-2">3.2. Kişisel Verilerin İşlenmesine İlişkin Şartlar</h4>
      <p><strong>ZERDUZ</strong> tarafından kişisel verileriniz, Kanun’un 5. maddesinde yer alan kişisel veri işleme
        şartlarından en az
        birinin varlığı halinde işlenmektedir. Söz konusu şartlara ilişkin açıklamalar aşağıda yer almaktadır:</p>
      <ul>
        <li><strong>Kişisel veri sahibinin açık rızasının olması</strong> diğer veri işleme şartlarının var olmadığı
          durumlarda, 3.1.
          başlık altında yer verilen genel ilkelere uygun olarak, <strong>ZERDUZ</strong> tarafıdan veri sahibinin kişisel
          verileri, veri
          sahibinin özgür iradesi ile kişisel veri işleme faaliyetine ilişkin yeterli bilgi sahibi olarak, tereddüde yer
          bırakmayacak şekilde ve sadece o işlemle sınırlı olarak onay vermesi halinde işlenebilmektedir.</li>
        <li><strong>Kişisel veri işleme faaliyetinin kanunlarda açıkça öngörülmesi</strong> halinde<strong>
            ZERDUZ</strong> tarafından kişisel veriler, veri
          sahibinin açık rızası olmadan işlenebilecektir. Bu durumda <strong>ZERDUZ</strong>, ilgili hukuki düzenleme
          çerçevesinde kişisel
          verileri işleyecektir.</li>
        <li><strong>Fiili imkânsızlık nedeniyle veri sahibinin açık rızasının elde edilememesi ve kişisel veri işlemenin
            zorunlu
            olması</strong> halinde, <strong>ZERDUZ</strong> tarafından rızasını açıklayamayacak durumda olan veya
          rızasına geçerlilik tanınamayacak
          olan veri sahibine ait kişisel veriler, veri sahibinin veya üçüncü bir kişinin hayatı veya beden bütünlüğünü
          korumak adına kişisel veri işlemenin zorunlu olması durumunda işlenecektir.</li>
        <li><strong>Kişisel veri işleme faaliyetinin bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
            olması</strong>
          durumunda, veri sahibi ile <strong>ZERDUZ</strong> arasında kurulan veya halihazırda imzalanmış olan sözleşmenin
          taraflarına ait
          kişisel verilerin işlenmesi gerekli ise kişisel veri işleme faaliyeti gerçekleştirilecektir.</li>
        <li><strong>Veri sorumlusu hukuki yükümlülüğünü yerine getirme için kişisel veri işleme faaliyeti yürütülmesinin
            zorunlu
            olması</strong> durumunda <strong>ZERDUZ</strong>, yürürlükteki mevzuat kapsamında öngörülen hukuki
          yükümlülüklerini yerine getirme
          amacıyla kişisel verileri işlemektedir.</li>
        <li><strong>Veri sahibinin kişisel verilerini alenileştirmiş olması,</strong> veri sahibi tarafından herhangi bir
          şekilde kamuoyuna
          açıklanmış, alenileştirilme sonucu herkesin bilgisine açılmış olan kişisel veriler alenileştirme amacı ile
          sınırlı olarak <strong>ZERDUZ</strong> tarafından veri sahiplerinin açık rızası olmasa da işlenebilecektir.</li>
        <li><strong>Bir hakkın tesisi, kullanılması veya korunması için kişisel veri işlemenin zorunlu olması</strong>
          durumunda, <strong>ZERDUZ</strong>
          zorunluluk kapsamında veri sahiplerinin açık rızası olmaksızın veri sahibinin kişisel verilerini
          işleyebilecektir.</li>
        <li><strong>Veri sahibinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
            menfaatleri için
            veri işlemenin zorunlu olması</strong> halinde, <strong>ZERDUZ</strong> ile veri sahibinin menfaat dengesinin
          gözetilmesi şartıyla <strong>ZERDUZ</strong>
          tarafından kişisel veriler işlenebilecektir. Bu kapsamda, meşru menfaate dayanarak verilerin işlenmesinde
          <strong>ZERDUZ</strong>
          öncelikle işleme faaliyeti sonucunda elde edeceği meşru menfaati belirler. Kişisel verilerin işlenmesinin veri
          sahibinin hak ve özgürlükleri üzerindeki olası etkisini değerlendirir ve dengenin bozulmadığı kanaatindeyse
          işleme faaliyetini gerçekleştirir.
        </li>
      </ul>
      <h4 id="3-3">3.3. Özel Nitelikli Kişisel Verilerin İşlenmesine İlişkin Şartlar</h4>
      <p>Kanunun 6. maddesinde özel nitelikli kişisel veriler, sınırlı sayıda olacak şekilde belirtilmiştir. Bunlar;
        kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve
        kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle
        ilgili verileri ile biyometrik ve genetik verileridir.</p>
      <p><strong>ZERDUZ</strong>, özel nitelikli kişisel verileri Kişisel Verileri Koruma Kurulu tarafından belirlenen
        ilave tedbirlerin
        alınmasını sağlayarak aşağıdaki durumlarda işleyebilmektedir:</p>
      <ul>
        <li>
          <strong>Sağlık ve cinsel hayat dışındaki özel nitelikli kişisel verilerin işlenmesi,</strong> veri sahibinin
          açık rıza vermesi
          halinde veya kanunlarda açıkça öngörülmesi durumunda işlenebilmektedir.
        </li>
        <li>
          <strong>Sağlık ve cinsel hayata ilişkin kişisel veriler,</strong> ancak kamu sağlığının korunması, koruyucu
          hekimlik, tıbbî
          teşhis, tedavi ve bakım hizmetlerinin yürütülmesi, sağlık hizmetleri ile finansmanının planlanması ve yönetimi
          amacıyla, sır saklama yükümlülüğü altında bulunan kişiler veya yetkili kurum ve kuruluşlar tarafından veri
          sahibinin açık rızası aranmaksızın işlenebilmektedir.
        </li>
      </ul>
      <h3 id="4">4. Kişisel Verilerin Aktarılması</h3>
      <p><strong>ZERDUZ</strong>, Kanun’un 8. ve 9. maddelerinde sıralanan ve Kişisel Verileri Koruma Kurulu tarafından
        belirlenmiş olan
        ilave düzenlemelere uygun olarak; kişisel verilerin aktarılması şartlarının bulunması durumunda kişisel verileri
        yurtiçinde veya yurtdışına aktarabilmektedir.</p>
      <ul>
        <li>
          <strong>Kişisel verilerin yurtiçinde üçüncü kişilere aktarımı,</strong> Kanun’un 5. ve 6. maddesinde yer alan ve
          işbu
          Politika’nın 3. Başlığı altında açıklanmış olan veri işleme şartlarından en az birinin varlığı halinde ve veri
          işleme şartlarına ilişkin temel ilkelere uymak şartıyla kişisel verileriniz <strong>ZERDUZ</strong> tarafından
          aktarılabilmektedir.
        </li>
        <li>
          <strong>Kişisel verilerin yurtdışında üçüncü kişilere aktarımı,</strong> kişinin açık rızası olmadığı hallerde,
          Kanun’un 5. ve
          6. maddesinde yer alan ve işbu Politika’nın 3. Başlığı altında açıklanmış olan veri işleme şartlarından en az
          birinin varlığı halinde ve veri işleme şartlarına ilişkin temel ilkelere uymak şartıyla <strong>ZERDUZ</strong>
          tarafından
          kişisel verileriniz yurtdışına aktarılabilmektedir.
        </li>
      </ul>
      <p>Aktarımın yapılacağı ülkenin Kişisel Verileri Koruma Kurulu tarafından ilan edilecek güvenli ülkelerden olmaması
        halinde, <strong>ZERDUZ</strong> ve ilgili ülkedeki veri sorumlusunun yeterli korumayı yazılı olarak taahhüt
        etmesi üzerine,
        Kişisel Verileri Kurul’un bu işleme izin vermesi ile Kanun’un 5. ve 6. maddesinde yer alan veri işleme
        şartlarından (bakınız Politika 3. Başlık) en az birinin varlığı halinde kişisel veriler yurtdışında üçüncü
        taraflara aktarılabilmektedir.
      </p>
      <p>Kanunun genel ilkeleri ile 8. ve 9. maddelerinde yer alan veri işleme şartları dahilinde <strong>ZERDUZ</strong>,
        aşağıdaki
        tabloda kategorizasyonu yapılmış taraflara veri aktarımı gerçekleştirebilmektedir:</p>
      <table class="table table-light table-bordered">
        <thead>
          <tr class="text-center align-middle">
            <th scope="col">PAYLAŞILAN TARAF KATEGORİZASYONU</th>
            <th scope="col">KAPSAM</th>
            <th scope="col">AKTARIM AMACI</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" class="fw-bold align-middle text-center">İş Ortağı</td>
            <td class="align-middle text-center"><strong>ZERDUZ</strong>’un ticari faaliyetlerini yürütürken iş ortaklığı
              kurduğu taraflar</td>
            <td>İş ortaklığının kurulma amaçlarının yerine getirilmesini temin etmek amacıyla sınırlı olarak kişisel
              verilerin paylaşımı</td>
          </tr>
          <tr>
            <td scope="row" class="fw-bold align-middle text-center">Tedarikçi</td>
            <td class="align-middle text-center"><strong>ZERDUZ</strong>’dan aldığı talimatlar doğrultusunda ve
              <strong>ZERDUZ</strong> ile arasındaki
              sözleşmeye dayanarak <strong>ZERDUZ</strong>’un ticari faaliyetlerini sürdürmesine yönelik hizmet sunan
              taraflar
            </td>
            <td>Tedarikçiden dış kaynaklı olarak temin edilen hizmetlerin alınması ile sınırlı olarak aktarım</td>
          </tr>
          <tr>
            <td scope="row" class="fw-bold align-middle text-center">İştirak</td>
            <td class="align-middle text-center"><strong>ZERDUZ</strong>’un iştiraki olan şirketler</td>
            <td>İştiraklerin katılımını gerektiren ticari faaliyetlerin yürütülmesi amacıyla sınırlı olarak kişisel
              verilerin aktarılması</td>
          </tr>
          <tr>
            <td scope="row" class="fw-bold align-middle text-center">Kanunen Yetkili Kamu Kurumu</td>
            <td class="align-middle text-center">Hukuken <strong>ZERDUZ</strong>’dan bilgi ve belge almaya yetkili kamu
              kurum ve kuruluşları</td>
            <td>İlgili kamu kurum ve kuruluşlarının bilgi talep etme amacıyla sınırlı olarak kişisel veri paylaşımı</td>
          </tr>
          <tr>
            <td scope="row" class="fw-bold align-middle text-center">Kanunen Yetkili Özel Kurum</td>
            <td class="align-middle text-center">Hukuken <strong>ZERDUZ</strong>’dan bilgi ve belge almaya yetkili özel
              hukuk kişileri</td>
            <td>İlgili özel hukuk kişilerinin hukuki yetkisi dahilinde talep ettiği amaçla sınırlı olarak verilerin
              paylaşımı</td>
          </tr>
        </tbody>
      </table>
      <br>
      <h3 id="5">5. Veri Sahiplerinin Aydınlatılması ve Veri Sahiplerinin Hakları</h3>
      <p>Kanunun 10. maddesine göre kişisel verilerin işlenmesinden önce veya en geç kişisel verilerin işlenmesi anında,
        veri sahiplerinin kişisel veri işlenmesine ilişkin aydınlatılmaları gerekmektedir. İlgili madde gereğince
        gereğince veri sorumlusu sıfatıyla <strong>ZERDUZ</strong> tarafından kişisel veri işleme faaliyetinin yürütüldüğü
        her durumda veri
        sahiplerinin aydınlatılmasını sağlamak üzere şirket içi gerekli yapı oluşturulmuştur. Bu kapsamda;</p>
      <ul>
        <li>Kişisel verilerinizin işlenme amacı için lütfen Politika’nın 2.2. bölümünü inceleyiniz.
        </li>
        <li>Kişisel verilerinizin aktarıldığı taraflar ve aktarım amacı için lütfen Politika’nın 4. Bölümünü
          inceleyiniz.</li>
        <li>Fiziki veya elektronik ortamlarda farklı kanallarla toplanabilen kişisel verilerinizi işlenmesine ilişkin
          şartları incelemek için lütfen Politika’nın 3.2 ve 3.3. bölümüne bakınız.</li>
        <li>Veri sahibi olarak Kanun’un 11. maddesi uyarınca aşağıdaki haklara sahip olduğunuzu belirtmek isteriz:
          <ul>
            <li>
              Kişisel verilerinizin işlenip işlenmediğini öğrenme,
            </li>
            <li>
              Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
            </li>
            <li>
              Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
            </li>
            <li>
              Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,
            </li>
            <li>
              Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu
              kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,
            </li>
            <li>
              Kanun’a ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren
              sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda
              yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,
            </li>
            <li>
              İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir
              sonucun ortaya çıkması durumunda buna itiraz etme,
            </li>
            <li>
              Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın
              giderilmesini talep etme.
            </li>
          </ul>
        </li>
      </ul>
      <p>Yukarıda sıralanan haklarınıza yönelik başvurularınızı, <a href="https://www.zerduz.com"
          class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">OBİM TEKNOLOJİ VE
          DIŞ TİCARET LİMİTED ŞİRKETİ Veri Sahibi
          Başvuru Formu</a>’nu doldurarak Şirketimize iletebilirsiniz. Talebinizin niteliğine göre en kısa sürede ve en
        geç otuz
        gün içinde başvurularınız ücretsiz olarak sonuçlandırılacaktır; ancak işlemin ayrıca bir maliyet gerektirmesi
        halinde Kişisel Verileri Koruma Kurulu tarafından belirlenecek tarifeye göre tarafınızdan ücret talep
        edilebilecektir.
      </p>
      <p><strong>ZERDUZ</strong>, başvuruların değerlendirilmesi sırasında öncelikle talepte bulunan kişinin gerçek hak
        sahibi olup
        olmadığını tespit etmektedir.</p>
      <p>Bununla birlikte <strong>ZERDUZ</strong> gerek gördüğü durumlarda talebin daha iyi anlaşılabilmesi için detaylı
        ve/veya ek bilgi
        isteyebilmektedir.</p>
      <p><strong>ZERDUZ</strong> tarafından veri sahibi başvurularına yanıtlar, yazılı olarak veya elektronik ortamda veri
        sahiplerine
        bildirilmektedir. Başvurunun reddedilmesi halinde ret nedenleri gerekçeli olarak veri sahibine açıklanacaktır.</p>
      <p>Kişisel verilerin doğrudan veri sahibinden elde edilmemesi halinde; <strong>ZERDUZ</strong> tarafından (1)
        kişisel verilerin elde
        edilmesinden itibaren makul bir süre içerisinde, (2) kişisel verilerin veri sahipleri kişi ile iletişim amacıyla
        kullanılacak olması durumunda, ilk iletişim kurulması esnasında, (3) kişisel verilerin aktarılacak olması halinde,
        en geç kişisel verilerin ilk kez aktarımının yapılacağı esnada veri sahiplerinin aydınlatılmasına ilişkin
        faaliyetler yürütülmektedir.</p>
      <br>
      <h3 id="6">6. Kişisel Verilerin Silinmesi, Yok Edilmesi, Anonim Hale Getirilmesi</h3>
      <p>Kanun’un 7. maddesi gereğince hukuka uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin
        ortadan kalkması hâlinde kişisel veriler tarafından resen veya veri sahibinin talebi üzerine
        <strong>ZERDUZ</strong>, Kurum
        tarafından yayımlanan rehberlere uygun olarak siler, yok eder veya anonim hâle getirir.
      </p>
      <br>
      <h3 id="7">7. Kanun Kapsamı Ve Uygulanmasına İlişkin Kısıtlamalar</h3>
      <p>Aşağıda belirtilen durumlar Kanun kapsamı dışındadır:</p>
      <ul>
        <li>Kişisel verilerin, üçüncü kişilere verilmemek ve veri güvenliğine ilişkin yükümlülüklere uyulmak
          kaydıyla gerçek kişiler tarafından tamamen kendisiyle veya aynı konutta yaşayan aile fertleriyle ilgili
          faaliyetler kapsamında işlenmesi.</li>
        <li>
          Kişisel verilerin resmi istatistik ile anonim hâle getirilmek suretiyle araştırma, planlama ve istatistik
          gibi amaçlarla işlenmesi.
        </li>
        <li>
          Kişisel verilerin millî savunmayı, millî güvenliği, kamu güvenliğini, kamu düzenini, ekonomik
          güvenliği, özel hayatın gizliliğini veya kişilik haklarını ihlal etmemek ya da suç teşkil etmemek
          kaydıyla, sanat, tarih, edebiyat veya bilimsel amaçlarla ya da ifade özgürlüğü kapsamında işlenmesi.
        </li>
        <li>
          Kişisel verilerin millî savunmayı, millî güvenliği, kamu güvenliğini, kamu düzenini veya ekonomik
          güvenliği sağlamaya yönelik olarak kanunla görev ve yetki verilmiş kamu kurum ve kuruluşları tarafından
          yürütülen önleyici, koruyucu ve istihbari faaliyetler kapsamında işlenmesi.
        </li>
        <li>
          Kişisel verilerin soruşturma, kovuşturma, yargılama veya infaz işlemlerine ilişkin olarak yargı makamları
          veya infaz mercileri tarafından işlenmesi.
        </li>
      </ul>
      <p>Aşağıda sayılan durumlarda <strong>ZERDUZ</strong> tarafından veri sahiplerine aydınlatma yapılması
        gerekmemektedir ve veri
        sahipleri, zararlarının giderilmesine ilişkin hakları hariç olmak üzere, Kanun’da belirtilen haklarını
        kullanamayacaklardır:</p>
      <ul>
        <li>
          Kişisel veri islemenin suç işlenmesinin önlenmesi veya suç soruşturması için gerekli olması.
        </li>
        <li>
          İlgili kişinin kendisi tarafından alenileştirilmiş kişisel verilerin işlenmesi.
        </li>
        <li>
          Kişisel veri işlemenin kanunun verdiği yetkiye dayanılarak görevli ve yetkili kamu kurum ve kuruluşları
          ile kamu kurumu niteliğindeki meslek kuruluşlarınca, denetleme veya düzenleme görevlerinin yürütülmesi
          ile disiplin soruşturma veya kovuşturması için gerekli olması.
        </li>
        <li>
          Kişisel veri işlemenin bütçe, vergi ve mali konulara ilişkin olarak Devletin ekonomik ve mali
          çıkarlarının korunması için gerekli olması.
        </li>
      </ul>
      <h5>EK-1: TANIMLAR</h5>
      <table class="table table-light table-bordered">
        <thead>
          <tr class="text-center align-middle">
            <th scope="col">TANIM</th>
          </tr>
        </thead>
        <tbody>
          <tr class="align-middle">
            <td class="text-center">Açık Rıza</td>
            <td>Belirli bir konuya ilişkin, bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rıza.</td>
          </tr>
          <tr class="align-middle">
            <td class="text-center">Anonim Hale Getirme</td>
            <td>Kişisel verinin, başka verilerle eşleştirilerek dahi hiçbir surette kimliği belirli veya belirlenebilir
              bir gerçek kişiyle ilişkilendirilemeyecek hale getirilmesi.</td>
          </tr>
          <tr class="align-middle">
            <td class="text-center">Çalışan</td>
            <td><strong>ZERDUZ</strong> çalışanı olan gerçek kişiler.</td>
          </tr>
          <tr class="align-middle">
            <td class="text-center">Çalışan Adayı</td>
            <td><strong>ZERDUZ</strong> çalışanı olmayan ancak çeşitli yöntemlerle <strong>ZERDUZ</strong> çalışan adayı
              statüsünde olan gerçek kişiler</td>
          </tr>
          <tr class="align-middle">
            <td class="text-center">Kişisel Sağlık Verisi</td>
            <td>Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü sağlık bilgisi.</td>
          </tr>
          <tr class="align-middle">
            <td class="text-center">Kişisel Veri</td>
            <td>Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgi.</td>
          </tr>
          <tr class="align-middle">
            <td class="text-center">Veri Sahibi</td>
            <td>Kişisel verisi işlenen gerçek kişi.</td>
          </tr>
          <tr class="align-middle">
            <td class="text-center">Kişisel Verilerin İşlenmesi</td>
            <td>Kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak
              kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi,
              değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hale
              getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her
              türlü işlem.</td>
          </tr>
          <tr class="align-middle">
            <td class="text-center">Kanun</td>
            <td>7 Nisan 2016 tarihli ve 29677 sayılı Resmi Gazete’de yayımlanan 6698 sayılı Kişisel Verilerin Korunması
              Kanunu.</td>
          </tr>
          <tr class="align-middle">
            <td class="text-center">Özel Nitelikli Kişisel Veri</td>
            <td>Irk, etnik köken, siyasi düşünce, felsefi inanç, din, mezhep veya diğer inançlar, kılık kıyafet, dernek
              vakıf ya da sendika üyeliği, sağlık, cinsel hayat, ceza mahkumiyeti ve güvenlik tedbirleriyle ilgili veriler
              ile biyometrik ve genetik veriler.</td>
          </tr>
          <tr class="align-middle">
            <td class="text-center">Politika</td>
            <td>OBİM TEKNOLOJİ VE DIŞ TİCARET LİMİTED ŞİRKETİ Kişisel Verilerin İşlenmesi ve Korunması Politikası</td>
          </tr>
          <tr class="align-middle">
            <td class="text-center">Şirket / <strong>ZERDUZ</strong></td>
            <td>OBİM TEKNOLOJİ VE DIŞ TİCARET LİMİTED ŞİRKETİ</td>
          </tr>
          <tr class="align-middle">
            <td class="text-center">İş Ortakları</td>
            <td><strong>ZERDUZ</strong>’un ticari faaliyetleri çerçevesinde sözleşmesel ilişkiler kapsamında ortaklık
              kurduğu kişiler.</td>
          </tr>
          <tr class="align-middle">
            <td class="text-center">Veri Sahibi</td>
            <td>Kişisel verisi işlenen gerçek kişi</td>
          </tr>
          <tr class="align-middle">
            <td class="text-center">Veri İşleyen</td>
            <td>Veri sorumlusunun verdiği yetkiye dayanarak onun adına kişisel veri işleyen gerçek ve tüzel kişidir</td>
          </tr>
          <tr class="align-middle">
            <td class="text-center">Veri Sorumlusu</td>
            <td>Kişisel verilerin işlenme amaçlarını ve vasıtalarını belirleyen, verilerin sistematik bir şekilde
              tutulduğu yeri yöneten kişidir.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "KisiselVerilerinKorunmasi"
};
</script>

  <style scoped src="../assets/css/pages.css"/>

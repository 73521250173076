<template>
  <div class="register-page">
    <div class="container py-3">
      <h1 class="text-center mb-5 mt-5" id="page-header-text">{{ $t('registerPage.registerHeader') }}</h1>
    </div>
    <div class="container py-3">
      <div class="col-lg-8 col-md-12 col-sm-12 mb-5 mx-auto">
        <div class="card border-0 shadow-sm">
          <div class="card-body m-2">
            <form ref="registerform" id="register-form">
              <div class="signup-step signup-step-1">
                <div class="row g-2">
                  <div class="col-md-6">
                    <div class="form-group">
                      <div class="form-floating mb-3">
                        <input type="text" class="form-control" required id="name" v-model="formData.name"
                          placeholder="Ahmet">
                        <label for="name">{{ $t('registerPage.nameInput') }}<span class="text-danger">*</span></label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <div class="form-floating mb-3">
                        <input type="text" class="form-control" required id="surname" v-model="formData.surname"
                          placeholder="Yılmaz">
                        <label for="surname">{{ $t('registerPage.surnameInput') }}<span class="text-danger">*</span></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row g-2">
                  <div class="col-md-6">
                    <div class="form-group">
                      <div class="form-floating mb-3">
                        <input type="email" class="form-control" id="email" v-model="formData.email"
                          placeholder="isim@ornek.com">
                        <label for="email">{{ $t('registerPage.mailInput') }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 justify-content-center d-flex">
                    <div class="form-group">
                      <div class="form-floating mb-3">
                        <input type="tel" style="height: 56px;" class="form-control" id="phone" ref="phoneInput" @input="validatePhone" @keypress="isNumber($event)" v-model="formData.phone">
                        <p v-if="isPhoneValid == false" class="mt-2 fw-normal text-danger"><span v-if="phoneValidText && phoneValidText != ''">{{ phoneValidText }}</span></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row g-2">
                  <div class="col-md-6">
                    <div class="form-group">
                      <div class="form-floating mb-3">
                        <input :type="showPassword ? 'text' : 'password'" class="form-control" required id="password" @input="validatePassword" v-model="formData.password"
                          placeholder="123">
                          <span @click="togglePassword" toggle="#password-field" class="fa fa-fw field-icon toggle-password base-password text-zerduz" :class="{ 'fa-eye': !showPassword, 'fa-eye-slash active': showPassword }"></span>
                        <label for="password">{{ $t('registerPage.passwordInput') }}<span class="text-danger">*</span></label>
                        <p v-if="isPasswordValid == false" class="mt-2 fw-normal text-danger">{{ $t('errors.validation.passwordValidText') }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <div class="form-floating mb-3">
                        <input :type="showPasswordConfirmation ? 'text' : 'password'" class="form-control" required id="password_confirmation"
                          v-model="formData.password_confirmation" @input="validatePasswordConfirmation" placeholder="123">
                          <span @click="togglePasswordConfirmation" toggle="#password-field" class="fa fa-fw field-icon toggle-password text-zerduz confirmation" :class="{ 'fa-eye': !showPasswordConfirmation, 'fa-eye-slash active': showPasswordConfirmation }"></span>
                        <label for="password_confirmation">{{ $t('registerPage.passwordConfirmationInput') }}<span class="text-danger">*</span></label>
                        <p v-if="isConfirmationPasswordValid == false" class="mt-2 fw-normal text-danger">{{ $t('errors.validation.passwordValidText') }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="signup-step signup-step-2">
                <div class="row g-2">
                  <div class="col-md-6">
                    <div class="form-group">
                      <div class="form-floating mb-3">
                        <input type="number" @keypress="isNumber($event)" @input="tcNoControl" class="form-control" id="tckno" v-model="formData.tcno" placeholder="01234567890" maxlength="11">
                        <label for="tckno">{{ $t('registerPage.idInput') }}</label>
                        <p v-if="isTcNoValid == false" class="mt-2 fw-normal text-danger">{{ tcNoValidText }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group justify-content-center d-flex">
                      <div class="form-floating mt-4 mt-md-0 mt-lg-0 mb-3">
                        <div class="form-check">
                          <input class="form-check-input" type="radio" value="1" name="flexRadioDefault"
                            id="personalAccount" v-model="formData.accounttype">
                          <label class="form-check-label" for="personalAccount">
                            {{ $t('registerPage.personalAccount') }}
                          </label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" value="2" name="flexRadioDefault" id="corporateAccount" v-model="formData.accounttype">
                          <label class="form-check-label" for="corporateAccount">
                            {{ $t('registerPage.corporateAccount') }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row g-2">
                  <div class="col-md-6">
                    <div class="form-group">
                      <div class="form-floating mb-3" id="verginoTxtBox">
                        <input type="number" @keypress="isNumber($event)" class="form-control" id="vergino" v-model="formData.taxno"
                          placeholder="0123456789">
                        <label for="vergino">{{ $t('registerPage.taxNo') }}</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <div class="form-floating mb-3" id="vergidairesiTxtBox">
                        <input type="text" class="form-control" id="vergidairesi" v-model="formData.tax"
                          placeholder="Vergi Dairesi">
                        <label for="vergidairesi">{{ $t('registerPage.tax') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="alertbox"></div>
              <div class="hstack gap-3 justify-content-center mt-3">
                <div class="p-2"><button class="btn btn-light shadow-sm" type="button" @click="prevStep"
                    style="opacity: 0" id="prev-button"><i class="fa-solid fa-arrow-left-long"></i></button></div>
                <div class="p-2 text-zerduz ms-auto" id="dot-1" @click="displayStep(1)"><i class="fa-solid fa-circle-dot"
                    id="dot"></i></div>
                <div class="p-2 text-secondary" id="dot-2" @click="displayStep(2)"><i class="fa-solid fa-circle fa-2xs"
                    id="dot"></i>
                </div>
                <div class="p-2 ms-auto">
                <button class="btn btn-dark shadow-sm" type="button" @click="nextStep" id="next-button">
                  <span class="login-button-text">{{ nextButtonText }}&numsp;<i class="fa-solid fa-arrow-right-long"></i></span>
                  <div class="btn-loading">
                      <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                      <span class="visually-hidden" role="status">{{ $t('loadingText') }}</span>
                  </div>
                </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import { register } from '@/helpers/api';
import { alert, destroyAlerts, validateTcNo } from "@/helpers/funcs";

export default {
  name: "RegisterV",
  data() {
    return {
      token: window.localStorage.getItem('token'),
      nextButtonText: this.$t('registerPage.nextButtonText[0]'),
      formData: {
        name: '',
        surname: '',
        email: '',
        password: '',
        password_confirmation: '',
        tcno: '',
        phone: '',
        accounttype: 1,
        taxno: null,
        tax: '',
      },
      phoneInputID: "#phone",
      iti: null,
      currentStep: 1,
      stepElementHeight: null,
      file: '',
      url: 'https://backend.zerduz.com/logos/',
      imageUrl: '',
      isPasswordValid: null,
      isConfirmationPasswordValid: null,
      showPassword: false,
      showPasswordConfirmation: false,
      formattedPhoneNumber: "",
      isTcNoValid: null,
      tcNoValidText: this.$t('errors.validation.tcnoValidText'),
      validationResult: null,
      isPhoneValid: null,
      phoneValidText: this.$t('errors.validation.phoneValidText[0]'),
    }
  },
  methods: {
    validatePhone() {
      if (this.formData.phone != null && this.formData.phone != '') {
        if (this.iti.isValidNumber()) {
          this.isPhoneValid = true;
          this.phoneValidText = "";
        } else {
          const errorCode = this.iti.getValidationError();
          if (errorCode == 1) {
            this.phoneValidText = this.$t('errors.validation.phoneValidText[1]');
          } else if (errorCode == 2) {
            this.phoneValidText = this.$t('errors.validation.phoneValidText[2]');
          } else if (errorCode == 3) {
            this.phoneValidText = this.$t('errors.validation.phoneValidText[3]');
          } else {
            this.phoneValidText = this.$t('errors.validation.phoneValidText[0]');
          }
          this.isPhoneValid = false;
        }
      } else {
        this.isPhoneValid = null;
        this.phoneValidText = "";
      }
    },
    validateVKN() {
      this.validationResult = this.isVKNV("0950012581");
    },
    isInt(s) {
      for (let a = 0; a < s.length; a++) {
        if (a === 0 && s.charAt(a) === '-') continue;
        if (!Number.isInteger(parseInt(s.charAt(a)))) return false;
      }
      return true;
    },
    isVKNV(vkn) {
      let tmp;
      let sum = 0;
      if (vkn !== null && vkn.length === 10 && this.isInt(vkn)) {
        const lastDigit = parseInt(vkn.charAt(9));
        for (let i = 0; i < 9; i++) {
          const digit = parseInt(vkn.charAt(i));
          tmp = (digit + 10 - (i + 1)) % 10;
          sum = tmp === 9 ? sum + tmp : sum + ((tmp * (Math.pow(2, 10 - (i + 1)))) % 9);
        }
        return lastDigit === (10 - (sum % 10)) % 10;
      }
      return false;
    },
    tcNoControl() {
      if (this.formData.tcno != null && this.formData.tcno != "") {
        const { validate, text } = validateTcNo(String(this.formData.tcno));
        if (validate == false) {
          this.tcNoValidText = text;
          this.isTcNoValid = false;
        } else if (validate == true) {
          this.isTcNoValid = true;
        } else {
          this.isTcNoValid = true;
        }
      } else {
        this.isTcNoValid = true;
      }
    },
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    togglePasswordConfirmation() {
      this.showPasswordConfirmation = !this.showPasswordConfirmation;
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    validatePassword() {
      const regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
      this.isPasswordValid = regex.test(this.formData.password);
    },
    validatePasswordConfirmation() {
      const regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
      this.isConfirmationPasswordValid = regex.test(this.formData.password_confirmation);
    },
    displayStep(stepNumber, animation = true) {
      destroyAlerts();
      if (stepNumber === 2 || stepNumber === 1) {
        if (stepNumber === 1) {
          if ($('.prev-button').css('opacity') !== 0) {
            $("#prev-button").animate({ opacity: 0 }, 500);
          }
          if ($('.next-button').css('opacity') === 0) {
            $("#next-button").animate({ opacity: 1 }, 500);
          }
          $("#prev-button").animate({ opacity: 0 }, 500);
          $("#next-button").animate({ opacity: 1 }, 500);
          this.nextButtonText = this.$t('registerPage.nextButtonText[0]');
        } else if (stepNumber === 2) {
          if ($('.prev-button').css('opacity') === 0) {
            $("#prev-button").animate({ opacity: 1 }, 500);
          }
          if ($('.next-button').css('opacity') === 0) {
            $("#next-button").animate({ opacity: 1 }, 500);
          }
          $("#next-button").animate({ opacity: 1 }, 500);
          $("#prev-button").animate({ opacity: 1 }, 500);
          this.nextButtonText = this.$t('registerPage.nextButtonText[1]');
        }
        if (animation) {
          $('body').css('overflow-x', 'hidden');
          $(".signup-step-" + this.currentStep).addClass("animate__animated animate__fadeOutLeft");
          setTimeout(() => {
            this.changeDot(this.currentStep, stepNumber);
            $(".signup-step").removeClass("animate__animated animate__fadeOutLeft").hide();
            $(".signup-step-" + stepNumber).show().addClass("animate__animated animate__fadeInRight");
            this.currentStep = stepNumber;
          }, 500);
        } else {
          if (stepNumber >= 1 && stepNumber <= 3) {
            $(".signup-step-" + this.currentStep).hide();
            $(".signup-step-" + stepNumber).show();
            this.currentStep = stepNumber;
          }
        }
      }
    },
    changeDot(currStep, nextStep) {
      $("#dot-" + currStep).removeClass("text-zerduz");
      $("#dot-" + currStep + " i").removeClass("fa-circle-dot");
      $("#dot-" + currStep).addClass("text-secondary");
      $("#dot-" + currStep + " i").addClass("fa-circle fa-2xs");

      $("#dot-" + nextStep).addClass("text-zerduz");
      $("#dot-" + nextStep + " i").addClass("fa-circle-dot");
      $("#dot-" + nextStep).removeClass("text-secondary");
      $("#dot-" + nextStep + " i").removeClass("fa-circle fa-2xs");
    },
    nextStep() {
      destroyAlerts();
      if (this.currentStep < 2) {
        $('body').css('overflow-x', 'hidden');
        $(".signup-step-" + this.currentStep).addClass("animate__animated animate__fadeOutLeft");
        this.currentStep++;

        if (this.currentStep === 1) {
          if ($('.prev-button').css('opacity') !== 0) {
            $("#prev-button").animate({ opacity: 0 }, 500);
          }
          if ($('.next-button').css('opacity') === 0) {
            $("#next-button").animate({ opacity: 1 }, 500);
          }
          $("#prev-button").animate({ opacity: 0 }, 500);
          $("#next-button").animate({ opacity: 1 }, 500);
          this.nextButtonText = this.$t('registerPage.nextButtonText[0]');
        } else if (this.currentStep === 2) {
          if ($('.prev-button').css('opacity') === 0) {
            $("#prev-button").animate({ opacity: 1 }, 500);
          }
          if ($('.next-button').css('opacity') === 0) {
            $("#next-button").animate({ opacity: 1 }, 500);
          }
          $("#next-button").animate({ opacity: 1 }, 500);
          $("#prev-button").animate({ opacity: 1 }, 500);
          this.nextButtonText = this.$t('registerPage.nextButtonText[1]');
        }

        setTimeout(() => {
          this.changeDot(this.currentStep - 1, this.currentStep);
          $(".signup-step").removeClass("animate__animated animate__fadeOutLeft").hide();
          $(".signup-step-" + this.currentStep).show().addClass("animate__animated animate__fadeInRight");
        }, 500);
      } else if (this.currentStep == 2) {
        $('.login-button-text').fadeOut(async () => {
          $('.btn-loading').fadeIn();
          if (this.$refs.registerform.checkValidity()) {
            if (this.isPasswordValid && this.isConfirmationPasswordValid && ((this.formData.tcno != null && this.formData.tcno != '' && this.isTcNoValid) || (this.formData.tcno == null || this.formData.tcno == '')) && ((this.formData.phone != null && this.formData.phone != '' && this.isPhoneValid) ||  (this.formData.phone == null || this.formData.phone == ''))) {
              // this.tryRegister();
              var countryDialCode = 0;

              if (this.formData.phone.length > 0) {
                if (this.formData.phone.charAt(0) === '0') {
                  this.formData.phone = this.formData.phone.slice(1);
                }
                var selectedCountryData = this.iti.getSelectedCountryData();
                countryDialCode = selectedCountryData.dialCode;
              }
              
              if (countryDialCode != "90" && countryDialCode != 0 && countryDialCode != null) {
                if (this.formData.email != null && this.formData.email) {
                  this.tryRegister();
                } else {
                  this.displayStep(1, false);
                  $('.btn-loading').fadeOut(200, function () {
                    $('.login-button-text').fadeIn();
                  });
                  alert("#alertbox", this.$t("errors.login.emailCantBeEmpty"), "danger", "mt-2");
                }
              } else {
                this.tryRegister();
              }
            } else if ((!this.isPasswordValid || !this.isConfirmationPasswordValid || (this.isPhoneValid == false && (this.formData.phone != null && this.formData.phone != '')))) {
              this.displayStep(1, true);
              $('.btn-loading').fadeOut(200, function () {
                $('.login-button-text').fadeIn();
              });
            } else {
              $('.btn-loading').fadeOut(200, function () {
                  $('.login-button-text').fadeIn();
              });
            }
          } else {
            var invalidFields = Array.from(this.$refs.registerform.elements).filter(field => !field.checkValidity());
            var requiredFields = ['name', 'surname', 'password', 'password_confirmation'];
            if (this.formData.phone == '' && this.formData.email != '') {
              requiredFields.push('email');
            } else if ((this.formData.phone != '' && this.formData.email == '')) {
              requiredFields.push('phone');
            } else {
              requiredFields.push('phone');
            }
            var goToFirstStep = false;

            invalidFields.forEach(item => {
              if (requiredFields.includes(item.id)) {
                goToFirstStep = true;
              }
            });
            if (goToFirstStep == true) {
              this.displayStep(1, false);
              $('.btn-loading').fadeOut(200, function () {
                $('.login-button-text').fadeIn();
              });
            }
            this.$refs.registerform.reportValidity();
          }
        });
      }
    },
    prevStep() {
      destroyAlerts();
      if (this.currentStep > 1) {
        $('body').css('overflow-x', 'hidden');
        $(".signup-step-" + this.currentStep).addClass("animate__animated animate__fadeOutRight");
        this.currentStep--;
        if (this.currentStep === 1) {
          if ($('.prev-button').css('opacity') !== 0) {
            $("#prev-button").animate({ opacity: 0 }, 500);
          }
          if ($('.next-button').css('opacity') === 0) {
            $("#next-button").animate({ opacity: 1 }, 500);
          }
          $("#prev-button").animate({ opacity: 0 }, 500);
          $("#next-button").animate({ opacity: 1 }, 500);
          this.nextButtonText = this.$t('registerPage.nextButtonText[0]');
        } else if (this.currentStep === 2) {
          if ($('.prev-button').css('opacity') === 0) {
            $("#prev-button").animate({ opacity: 1 }, 500);
          }
          if ($('.next-button').css('opacity') === 0) {
            $("#next-button").animate({ opacity: 1 }, 500);
          }
          $("#next-button").animate({ opacity: 1 }, 500);
          $("#prev-button").animate({ opacity: 1 }, 500);
          this.nextButtonText = this.$t('registerPage.nextButtonText[1]');
        }
        setTimeout(() => {
          this.changeDot(this.currentStep + 1, this.currentStep);
          $(".signup-step").removeClass("animate__animated animate__fadeOutRight").hide();
          $(".signup-step-" + this.currentStep).show().addClass("animate__animated animate__fadeInLeft");
        }, 500);
      }
    },
    async tryRegister() {
      var pore = 0;
      if (this.formData.email && this.formData.phone) {
        pore = 1;
      } else if (this.formData.phone && !this.formData.email) {
        pore = 1;
      } else if (!this.formData.phone && this.formData.email) {
        pore = 0;
      }
      if (this.formData.phone.length > 0) {
        if (this.formData.phone.charAt(0) === '0') {
          this.formData.phone = this.formData.phone.slice(1);
        }
        var selectedCountryData = this.iti.getSelectedCountryData();
        var countryDialCode = selectedCountryData.dialCode;
        if (countryDialCode != "90" && countryDialCode != 0 && countryDialCode != null) {
          pore = 0;
        }
        this.formattedPhoneNumber = `${countryDialCode}${this.formData.phone}`;
        this.formattedPhoneNumber = this.formattedPhoneNumber.replace(/\s/g, '');
      }

      const { success, message } = await register(
        this.formData.name,
        this.formData.surname,
        this.formData.email,
        this.formData.password,
        this.formData.password_confirmation,
        String(this.formData.tcno),
        this.formattedPhoneNumber,
        parseInt(this.formData.accounttype),
        parseInt(this.formData.taxno),
        this.formData.tax,
        pore
      );

      if (success) {
        alert("#alertbox", message, "success", "mt-2");
      } else {
        alert("#alertbox", message, "danger", "mt-2");
      }
      $('.btn-loading').fadeOut(200, function () {
        $('.login-button-text').fadeIn();
      });
    },
    resetFileInput() {
      this.formData.logo = '';
    },
    onFileSelected(event) {
      this.file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.imageUrl = reader.result;
      };
      reader.readAsDataURL(this.file);
      this.formData.logo = this.file.name;
    }
  },
  mounted() {
    this.stepElementHeight = Math.max($(".signup-step-1").outerHeight(), $(".signup-step-2").outerHeight());

    $('.signup-step-1, .signup-step-2').on('animationend', function (event) {
      var elementClasses = ['signup-step-1', 'signup-step-2'];

      if (elementClasses.some(cls => $(event.target).hasClass(cls))) {
        $('body').css('overflow-x', 'auto');
      }
    });
    var input = document.querySelector(this.phoneInputID);
    this.iti = window.intlTelInput(input, {
      autoPlaceholder: "polite",
      customPlaceholder: function(placeholder) {
        if (placeholder.charAt(0) === '0') {
          return placeholder.slice(1);
        } else {
          return placeholder;
        }
      },
      preferredCountries: ["tr"],
      initialCountry: "auto",
      geoIpLookup: callback => {
        fetch("https://ipapi.co/json")
          .then(res => res.json())
          .then(data => callback(data.country_code))
          .catch(() => callback("tr"));
      },
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@19.2.19/build/js/utils.js",
    });
  
    $('input[type=radio][name=flexRadioDefault]').change(function () {
      var verginoTxtBox = $('#verginoTxtBox');
      var vergidairesiTxtBox = $('#vergidairesiTxtBox');
      verginoTxtBox.css('animation-duration', '0.5s');
      vergidairesiTxtBox.css('animation-duration', '0.5s');
      if (this.id == 'personalAccount') {
        verginoTxtBox.removeClass('animate__animated animate__fadeIn');
        vergidairesiTxtBox.removeClass('animate__animated animate__fadeIn');
        verginoTxtBox.addClass('animate__animated animate__fadeOut');
        vergidairesiTxtBox.addClass('animate__animated animate__fadeOut');
        verginoTxtBox.css('display', 'none');
        vergidairesiTxtBox.css('display', 'none');
      } else if (this.id == 'corporateAccount') {
        verginoTxtBox.css('display', 'block');
        vergidairesiTxtBox.css('display', 'block');
        verginoTxtBox.removeClass('animate__animated animate__fadeOut');
        vergidairesiTxtBox.removeClass('animate__animated animate__fadeOut');
        verginoTxtBox.addClass('animate__animated animate__fadeIn');
        vergidairesiTxtBox.addClass('animate__animated animate__fadeIn');
      }
    });
  }
};
</script>

<style scoped src="../assets/css/register.css"/>
<style scoped>
@import '../assets/css/intlTelInput.css';
</style>

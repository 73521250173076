<template>
    <div class="home">
        <div class="container">
            <figure class="figure">
                <video src="../assets/img/smoke.mp4" autoplay playsinline muted></video>
                <div class="cnt user-select-none">
                   <img :src="getImgUrl($t('homePage.topZerduzImg'))" class="img-fluid homepage-img mx-auto d-block"> 
                    <div class="zerduz-time">{{ $t('homePage.defaultZerduzTime') }}</div>
                    <div class="zerduz-day">{{ $t('homePage.defaultZerduzDay') }}</div>
                    <div class="zerduz-date">{{ $t('homePage.defaultZerduzDate') }}</div>
                </div>
            </figure>

            <div class="container py-3 mt-1">
                <div class="container px-4">
                    <div class="row flex-lg-row-reverse align-items-center">
                        <div class="col-lg-6">
                            <img src="../assets/img/zerduz-1.png" class="d-block mx-lg-auto img-fluid scroll"
                                alt="Bootstrap Themes" width="700" height="500" loading="lazy">
                        </div>
                        <div class="col-lg-6">
                            <h1 class="display-5 fw-bold lh-1 mb-3 scroll pb-2">{{ $t('homePage.firstSection.title') }}</h1>
                            <p class="lead fw-normal scroll">{{ $t('homePage.firstSection.description') }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <main>
                <section class="d-none">
                </section>

                <section>
                    <p class="lh-1">
                        {{ $t('homePage.secondSection.title') }}
                    </p>
                </section>

                <div class="client-logo-area client-logo-one">
                    <div class="section-title-two">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="content">
                                        <span class="fs-5 gray"> {{ $t('homePage.thirdSection.title') }} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-md-3 col-6">
                                <div class="single-client text-center gray">
                                    <img src="../assets/img/robertobravo.svg" width="150" height="75" alt="Roberto Bravo" />
                                </div>
                            </div>
                            <div class="col-md-3 col-6">
                                <div class="single-client text-center gray">
                                    <img src="../assets/img/kad-sis.svg" width="150" height="75" alt="KAD-SİS" />
                                </div>
                            </div>
                            <div class="col-md-3 col-6">
                                <div class="single-client text-center gray">
                                    <img src="../assets/img/iar.svg" width="150" height="75" alt="İstanbul Altın Rafinerisi" />
                                </div>
                            </div>
                            <div class="col-md-3 col-6">
                                <div class="single-client text-center gray">
                                    <img src="../assets/img/omeroglu.svg" width="150" height="75" alt="Ömeroğlu" />
                                </div>
                            </div>
                            <div class="col-md-3 col-6">
                                <div class="single-client text-center gray">
                                    <img src="../assets/img/agakulche.svg" width="150" height="75" alt="AgaKulche" />
                                </div>
                            </div>
                            <div class="col-md-3 col-6">
                                <div class="single-client text-center gray">
                                    <img src="../assets/img/erbay.svg" width="150" height="75" alt="Erbay" />
                                </div>
                            </div>
                            <div class="col-md-3 col-6">
                                <div class="single-client text-center gray">
                                    <img src="../assets/img/simoro.svg" width="150" height="75" alt="Simoro" />
                                </div>
                            </div>
                            <div class="col-md-3 col-6">
                                <div class="single-client text-center gray">
                                    <img src="../assets/img/bars.svg" width="150" height="75" alt="Bars" />
                                </div>
                            </div>
                            <div class="col-md-3 col-6">
                                <div class="single-client text-center gray">
                                    <img src="../assets/img/mist.svg" width="150" height="75" alt="Mist" />
                                </div>
                            </div>
                            <div class="col-md-3 col-6">
                                <div class="single-client text-center gray">
                                    <img src="../assets/img/regold.svg" width="150" height="75" alt="Regold" />
                                </div>
                            </div>
                            <div class="col-md-3 col-6">
                                <div class="single-client text-center gray">
                                    <img src="../assets/img/somoro.svg" width="150" height="85" alt="Somoro" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section>
                    <h2>Zerduz</h2>
                </section>
                <section class="mt-5">
                    <div class="spacing-box"></div>
                    <div class="box">
                        <div class="box__content">
                            <p>
                                {{ $t('homePage.fourthSection.firstBox') }}
                            </p>
                        </div>
                    </div>
                    <div class="sticky">
                        <img src="../assets/img/zerduz-2.png" alt="" />
                    </div>
                    <div class="box box--two shadow">
                        <div class="box__content">
                            <p>
                                <span class="logo"></span>
                                <span>{{ $t('homePage.fourthSection.secondBox.zerduz') }}</span>
                                {{ $t('homePage.fourthSection.secondBox.text') }}
                            </p>
                            <a href="Purchase" class="btn btn-outline-primary rounded-pill">{{ $t('purchaseButtonText') }}&nbsp;<i
                                    class="fa-solid fa-arrow-right-long"></i></a>
                        </div>
                    </div>
                </section>
                <section>
                    <div class="bento--mini shadow kuyumhaber-card">
                        <div>
                            <svg class="bird" role="img" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
                                <image class="bgimage" x="-2" y="0" style="width: 90%; height: auto;"
                                    preserveAspectRatio="none" xlink:href="../assets/img/kuyumhaberlogo.png" />
                                <image class="overlay-image" x="22" y="5" style="width: 90%; height: auto;"
                                    preserveAspectRatio="none" xlink:href="../assets/img/newspage3.png" />
                            </svg>
                        </div>
                        <div>
                            <a href="" class="lh-1 left-box kuyumhaber-text">
                                <span class="logo"></span>
                                <p style="width:55%;">{{ $t('homePage.fourthSection.thirdBox') }}</p>
                            </a>
                        </div>
                    </div>
                    <div class="bento--mini shadow">
                        <div class="row">
                            <div class="">
                                <div class="app-slider">
                                    <div>
                                        <div class="col-md-4 w-50 me-4">
                                            <img src="../assets/img/kuyumhaberapp.png" class="rounded-5 mx-auto d-block"
                                                id="appImg" alt="...">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="col-md-4 w-50 me-4">
                                            <img src="../assets/img/ziynetfiyatapp.png" class="rounded-5 mx-auto d-block"
                                                id="appImg" alt="...">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="col-md-4 w-50 me-4">
                                            <img src="../assets/img/zerduzapp.png" class="rounded-5 mx-auto d-block"
                                                id="appImg" alt="...">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="col-md-4 w-50 me-4">
                                            <img src="../assets/img/zerchatapp.png" class="rounded-5 mx-auto d-block"
                                                id="appImg" alt="...">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="col-md-4 w-50 me-4">
                                            <img src="../assets/img/obimapp.png" class="rounded-5 mx-auto d-block"
                                                id="appImg" alt="...">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="col-md-4 w-50 me-4">
                                            <img src="../assets/img/ajandaapp.png" class="rounded-5 mx-auto d-block"
                                                id="appImg" alt="...">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="col-md-4 w-50 me-4">
                                            <img src="../assets/img/bibliotecoroapp.png" class="rounded-5 mx-auto d-block"
                                                id="appImg" alt="...">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="col-md-4 w-50 me-4">
                                            <img src="../assets/img/borsaapp.png" class="rounded-5 mx-auto d-block"
                                                id="appImg" alt="...">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="col-md-4 w-50 me-4">
                                            <img src="../assets/img/coinapp.png" class="rounded-5 mx-auto d-block"
                                                id="appImg" alt="...">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="col-md-4 w-50 me-4">
                                            <img src="../assets/img/kariyerapp.png" class="rounded-5 mx-auto d-block"
                                                id="appImg" alt="...">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="col-md-4 w-50 me-4">
                                            <img src="../assets/img/korgezmeapp.png" class="rounded-5 mx-auto d-block"
                                                id="appImg" alt="...">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="col-md-4 w-50 me-4">
                                            <img src="../assets/img/kuyummarketapp.png" class="rounded-5 mx-auto d-block"
                                                id="appImg" alt="...">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="col-md-4 w-50 me-4">
                                            <img src="../assets/img/notlarapp.png" class="rounded-5 mx-auto d-block"
                                                id="appImg" alt="...">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="col-md-4 w-50 me-4">
                                            <img src="../assets/img/odaapp.png" class="rounded-5 mx-auto d-block"
                                                id="appImg" alt="...">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="col-md-4 w-50 me-4">
                                            <img src="../assets/img/sarrafapp.png" class="rounded-5 mx-auto d-block"
                                                id="appImg" alt="...">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="col-md-4 w-50 me-4">
                                            <img src="../assets/img/tasapp.png" class="rounded-5 mx-auto d-block"
                                                id="appImg" alt="...">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="col-md-4 w-50 me-4">
                                            <img src="../assets/img/zepostapp.png" class="rounded-5 mx-auto d-block"
                                                id="appImg" alt="...">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row h-50 gap-lg-0 gap-2 gap-md-2 gap-sm-2">
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <router-link to="/Specs" class="text-decoration-none">
                                <div class="bento--mini shadow bg-mini-card">
                                    <div class="row text-center mx-auto gap-3 text-light card-content">
                                        <i class="fa-solid fa-battery-full text-glow-success fa-2xl text-success"></i>
                                        <h4 class="fw-normal">{{ $t('homePage.fourthSection.fourthBox.batteryText') }}</h4>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <router-link to="/Specs" class="text-decoration-none">
                                <div class="bento--mini shadow bg-mini-card">
                                    <div class="row text-center mx-auto gap-3 text-light card-content">
                                        <h2 class="fw-normal" v-html="$t('homePage.fourthSection.fourthBox.storage.text')"></h2>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 d-sm-none d-lg-block d-md-none d-none">
                            <router-link to="/Specs" class="text-decoration-none">
                                <div class="bento--mini shadow h-50 w-100 bg-mini-card" style="margin-top: 2%;">
                                    <div class="row text-center mt-2 mx-auto gap-3 text-light card-content">
                                        <h3 class="fw-normal">{{ $t('homePage.fourthSection.fourthBox.financialInstruments.title') }}</h3>
                                        <div class="row justify-content-center mx-auto gap-2 user-select-none">
                                            <span v-for="(instrument, index) in $tm('homePage.fourthSection.fourthBox.financialInstruments.instruments')" :key="index" class="badge w-auto text-bg-light fw-normal box-glow-white fs-6">{{instrument}}</span>
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div class="row h-50 gap-lg-0 gap-2 gap-md-2 gap-sm-2">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <router-link to="/Specs" class="text-decoration-none">
                                <div class="bento--mini w-100 shadow bg-mini-card">
                                    <div class="row text-center mx-auto text-light card-content">
                                        <svg width="156px" class="nfc-icon" id="mini-card-icon" height="156px"
                                            viewBox="-2.4 -2.4 28.80 28.80" fill="none" xmlns="http://www.w3.org/2000/svg"
                                            stroke="#ffffff" transform="rotate(0)">
                                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"
                                                stroke="#CCCCCC" stroke-width="0.144"></g>
                                            <g id="SVGRepo_iconCarrier">
                                                <path d="M5 8L10 16" stroke="#ffffff" stroke-width="2"
                                                    stroke-linecap="round">
                                                </path>
                                                <path
                                                    d="M18.7224 20.5C20.2145 17.9157 21 14.9841 21 12C21 9.01588 20.2145 6.08433 18.7224 3.5"
                                                    stroke="#ffffff" stroke-width="2" stroke-linecap="round"></path>
                                                <path
                                                    d="M14.3923 18C15.4455 16.1758 16 14.1064 16 12C16 9.89356 15.4455 7.82423 14.3923 6"
                                                    stroke="#ffffff" stroke-width="2" stroke-linecap="round"></path>
                                                <path
                                                    d="M9.9282 16C10.6303 14.7838 11 13.4043 11 12C11 10.5957 10.6303 9.21615 9.9282 8"
                                                    stroke="#ffffff" stroke-width="2" stroke-linecap="round"></path>
                                                <path
                                                    d="M5.0718 16C4.36965 14.7838 4 13.4043 4 12C4 10.5957 4.36965 9.21615 5.0718 8"
                                                    stroke="#ffffff" stroke-width="2" stroke-linecap="round"></path>
                                            </g>
                                        </svg>
                                        <h3 class="fw-normal opacity-100 text-glow-white">{{ $t('homePage.fourthSection.fourthBox.nfcSupport') }}</h3>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 d-sm-block d-lg-none d-md-block d-block">
                            <div class="bento--mini w-100 shadow bg-mini-card">
                                <div class="row text-center mx-auto gap-3 text-light">
                                    <h3 class="fw-normal">Bütün finansal enstrümanlar</h3>
                                    <div class="row justify-content-center mx-auto gap-2 user-select-none">
                                        <span class="badge w-auto text-bg-light fw-normal box-glow-white fs-6">Dolar</span>
                                        <span class="badge w-auto text-bg-light fw-normal box-glow-white fs-6">Euro</span>
                                        <span class="badge w-auto text-bg-light fw-normal box-glow-white fs-6">Yuan</span>
                                        <span class="badge w-auto text-bg-light fw-normal box-glow-white fs-6">Altın</span>
                                        <span class="badge w-auto text-bg-light fw-normal box-glow-white fs-6">Gümüş</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
        <div class="container-fluid faq-section mt-sm-5 mt-lg-0 mt-md-5 mt-5 shadow">
            <section class="mb-lg-7 text-white py-6">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-6 offset-lg-3">
                            <div class="text-center mb-lg-3 mb-5">
                                <h2>{{$t('homePage.fifthSection.title')}}</h2>
                                <p v-html="$t('homePage.fifthSection.description')"></p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            <div class="accordion" id="accordionExample">
                                <div class="border-bottom p-3" v-for="(question, index) in $tm('homePage.fifthSection.questions')" :key="index">
                                    <h2 class="h5 mb-0">
                                        <a href="#!"
                                            class="text-reset d-flex justify-content-between align-items-center collapsed text-decoration-none"
                                            data-bs-toggle="collapse" :data-bs-target="'#collapse' + question.id + 'Account'"
                                            aria-expanded="false" :aria-controls="'collapse' + question.id + 'Account'">
                                            {{ question.title }}
                                            <span class="chevron-arrow"><i class="bi bi-chevron-down"></i></span>
                                        </a>
                                    </h2>
                                    <div :id="'collapse' + question.id + 'Account'" class="accordion-collapse collapse"
                                        data-bs-parent="#accordionExample" style="">
                                        <div class="mt-3">{{ question.description }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="container mb-7">
            <section class="my-sm-5 my-sm-5 my-lg-5 my-md-5 my-5 buy-section">
                <router-link to="Purchase" class="container text-decoration-none">
                    <div class="row bg-pattern bg-primary-gradient rounded-5 p-7 g-0 shadow">
                        <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-12 col-12">
                            <div class="position-relative z-1 my-lg-2">
                                <div class="mb-5 text-center user-select-none">
                                    <h3 class="h2 text-white mb-1">{{ $t('homePage.sixthSection.title') }}</h3>
                                    <h6 class="mb-0 text-white">{{ $t('homePage.sixthSection.description') }}</h6>
                                </div>
                                <div class="row g-2 needs-validation d-flex" novalidate="">
                                    <div class="col-md-12 col-xl-12 col-12 text-center">
                                        <router-link to="Purchase"
                                            class="btn btn-primary bg-blur text-light btn-lg px-5 border-0">{{ $t('purchaseButtonText') }}</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </section>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import "../assets/js/bootstrap.bundle";
import { login } from '@/helpers/api';
import { alert } from "@/helpers/funcs";
import '../assets/js/slick.min'

export default {
    name: "HomeView",
    data() {
        return {
            token: window.localStorage.getItem('token'),
            formData: {
                mail: '',
                password: ''
            },
            opened: false,
            panelNumbers: [1, 2, 3],
            zImage: {
                backgroundImage: `url(${require('@/assets/img/new-1.png')})`
            },
            KuyumImg: {
                backgroundImage: `url(${require('@/assets/img/new-3.png')})`
            },
            smokeImg: {
                backgroundImage: `url(${require('@/assets/img/smoke.png')})`
            },
        };
    },
    methods: {
        shuffleApps() {
          var sliderApps = $('.app-slider > div');

          sliderApps.sort(function() {
            return 0.5 - Math.random();
          });

          $('.app-slider').html(sliderApps);
        },
        getImgUrl(img) {
            var images = require.context('../assets/img/', false, /\.png$/)
            return images('./' + img + ".png")
        },
        async tryLogin() {
            const { success, message, token } = await login(this.formData.mail, this.formData.password);
            alert("#remember-me-box", message, "success", "mt-2");

            if (success) {
                window.localStorage.setItem('token', token);
            }
        },
        btnClick(number) {
            var element1 = "#panel-wrap" + number;
            var element = $(element1);
            if (this.opened == false) {
                $('body').css('overflow', 'hidden');
                element.css('opacity', '1');
                element.css("transform", "translateX(0%)");
                this.opened = true;
            } else if (this.opened == true) {
                $('body').css('overflow', 'auto');
                element.css('opacity', '0');
                element.css("transform", "translateX(100%)");
                this.opened = false;
            }
        },
    },
    mounted() {
        this.shuffleApps();
        var velocity = 0.5;

        function update() {
            var pos = $(window).scrollTop();
            $('#smoke').each(function () {
                var $element = $(this);
                var height = $element.height() - 18;
                $(this).css('backgroundPosition', '0% ' - Math.round((height - pos) * velocity) + 'px');
            });
        }
        var oldTime = '';

        function updateTime(started) {
            var today = new Date();
            var time = (today.getHours() < 10 ? '0' + today.getHours() : today.getHours()) + ":" + (today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes());
            if (time != oldTime) {
                if (String((today.getHours() < 10 ? '0' + today.getHours() : today.getHours())) == "00" && String((today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes())) == "00") {
                    updateDate();
                }
                oldTime = time;
                $('.zerduz-time').text(time);
            }
            if (started == 0) {
                setInterval(updateTime, 1000, time, 1);
            }
        }
        
        function updateDate() {
            var today = new Date();
            var day = today.toLocaleString(localStorage.getItem('lang'), { weekday: "long" });
            var date = today.toLocaleString(localStorage.getItem('lang'), { day: "numeric", month: 'long', year: "numeric" });
            $('.zerduz-day').text(day);
            $('.zerduz-date').text(date);
        }

        updateDate();
        updateTime(0);

        $(window).bind('scroll', update);
        
        $('.app-slider').not('.slick-initialized').slick({
            infinite: true,
            autoplay: true,
            autoplaySpeed: 0,
            speed: 2500,
            cssEase: 'linear',
            slidesToShow: 1,
            slidesToScroll: 1,
            pauseOnFocus: false,
            pauseOnHover: true,
            arrows: false,
            draggable: false,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 2,
                    }
                }
            ]
        });
    },
};

</script>


<style scoped src="../assets/css/index.css"/>
<style scoped>
@import '../assets/css/slick.css';
</style>
<style scoped>
@import '../assets/css/slick-theme.css';
</style>

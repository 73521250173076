<template>
    <div>
        <div class="px-4 my-5 text-center">
            <h1 class="text-center mt-5 p-1" id="page-header-text">{{ $t('accountPages.header') }}</h1>
        </div>

        <div class="container py-3">
            <div class="row">
                <accountPageSidemenu />
                <div class="col-lg-9 col-md-8 col-sm-12" id="account-settings-tab">
                    <div class="border my-2 rounded-2 shadow-sm">
                        <div class="container">
                            <div class="row p-3">
                                <ul class="nav nav-pills nav-fill gap-2 p-1 small bg-primary rounded-5 shadow-sm" id="settingsNav" role="tablist" style="--bs-nav-link-color: var(--bs-white); --bs-nav-pills-link-active-color: var(--bs-primary); --bs-nav-pills-link-active-bg: var(--bs-white);">
                                    <div class="col">
                                        <li class="nav-item" role="presentation">
                                            <router-link to="/Account/Settings" active-class="" class="nav-link active rounded-5" id="info-tab" type="button">{{ $t('accountPages.settings.menu[0].title') }}</router-link>
                                        </li>
                                    </div>
                                    <div class="col">
                                        <li class="nav-item" role="presentation">
                                            <router-link to="/Account/Settings/Password" active-class="" class="nav-link text-white text-opacity-50 rounded-5" id="password-tab" type="button">{{ $t('accountPages.settings.menu[1].title') }}</router-link>
                                        </li>
                                    </div>
                                    <div class="col">
                                        <li class="nav-item" role="presentation">
                                            <router-link to="/Account/Settings/Company" active-class="" class="nav-link text-white text-opacity-50 rounded-5" id="company-tab" type="button">{{ $t('accountPages.settings.menu[2].title') }}</router-link>
                                        </li>
                                    </div>
                                </ul>   
                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-account-info" role="tabpanel"
                                        aria-labelledby="pills-account-info" tabindex="0">
                                        <form @submit.prevent="updateAccountInfos">
                                            <div class="row g-2 my-2">
                                                <div class="col-md-4">
                                                    <div class="form-floating">
                                                        <input type="text" class="form-control"
                                                            v-model="accountData.first_name" id="account-name"
                                                            :placeholder="$t('accountPages.settings.account.form.name')">
                                                        <label for="account-name">{{ $t('accountPages.settings.account.form.name') }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-floating">
                                                        <input type="text" class="form-control"
                                                            v-model="accountData.last_name" id="account-surname"
                                                            :placeholder="$t('accountPages.settings.account.form.surname')">
                                                        <label for="account-surname">{{ $t('accountPages.settings.account.form.surname') }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-floating">
                                                        <input type="number" class="form-control"
                                                            v-model="accountData.tcno" id="account-tcno"
                                                            :placeholder="$t('accountPages.settings.account.form.id')">
                                                        <label for="account-tcno">{{ $t('accountPages.settings.account.form.id') }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row g-2 my-2">
                                                <div class="col-md-4">
                                                    <div class="form-floating">
                                                        <input type="email" class="form-control" v-model="accountData.email"
                                                            id="account-email" :placeholder="$t('accountPages.settings.account.form.name')">
                                                        <label for="account-email">{{ $t('accountPages.settings.account.form.mail') }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-floating">
                                                        <input type="tel" ref="phone" class="form-control" @input="validatePhone" @keypress="isNumber($event)" v-model="accountData.phone" id="account-phone">
                                                        <span v-if="isPhoneValid == false" class="mt-2 fw-normal text-danger"><span v-if="phoneValidText && phoneValidText != ''">{{ phoneValidText }}</span></span>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <div class="form-floating">
                                                            <select class="form-select" id="profession" v-model="accountData.business_type">
                                                                <option v-for="(profession, index) in $tm('accountPages.settings.account.form.profession')" :key="index" :value="profession.value">{{ profession.key }}</option>
                                                            </select>
                                                            <label for="floatingSelect">{{ $t('accountPages.settings.account.form.profession[0].key') }}</label>
                                                            <transition name="fade" mode="in-out">
                                                                <div v-if="accountData.business_type === 'other'"
                                                                    class="mt-3">
                                                                    <div class="form-floating mb-3">
                                                                        <input type="text" class="form-control"
                                                                            id="profession" v-model="otherProfession" :placeholder="$t('accountPages.settings.account.form.enterProfession')">
                                                                        <label for="profession">{{ $t('accountPages.settings.account.form.enterProfession') }}</label>
                                                                    </div>
                                                                </div>
                                                            </transition>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row g-2 my-1">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <div class="form-floating mb-3">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    v-model="accountData.account_type" value="1" 
                                                                    name="flexRadioDefault" id="personalAccount">
                                                                <label class="form-check-label" for="personalAccount">
                                                                    {{ $t('accountPages.settings.account.form.personalAccount') }}
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    v-model="accountData.account_type" value="2"
                                                                    name="flexRadioDefault" id="corporateAccount">
                                                                <label class="form-check-label" for="corporateAccount">
                                                                    {{ $t('accountPages.settings.account.form.corporateAccount') }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <div class="form-floating mb-3" id="verginoTxtBox">
                                                            <input type="text" class="form-control"
                                                                v-model="accountData.tax_no" id="vergino"
                                                                placeholder="0123456789">
                                                            <label for="vergino">{{ $t('accountPages.settings.account.form.taxNo') }}</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <div class="form-floating mb-3" id="vergidairesiTxtBox">
                                                            <input type="text" class="form-control"
                                                                v-model="accountData.tax_office" id="vergidairesi"
                                                                placeholder="Bakırköy Vergi Dairesi">
                                                            <label for="vergidairesi">{{ $t('accountPages.settings.account.form.tax') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div id="alertbox"></div>
                                                    <div class="col-12 d-grid gap-2 mb-3">
                                                        <button type="submit"
                                                            class="btn btn-primary btn-block mt-3 mx-auto">{{ $t('accountPages.settings.account.form.btn') }}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import { getAccount, updateAccount } from "@/helpers/api";
import { alert } from "@/helpers/funcs";
import { parsePhoneNumber } from 'libphonenumber-js';
import accountPageSidemenu from '@/components/accountPageSidemenu';

export default {
    name: "AccountSettings",
    components: {
        accountPageSidemenu
    },
    data() {
        return {
            token: window.localStorage.getItem('token'),
            userId: window.localStorage.getItem('userId'),
            phoneInputID: "#account-phone",
            iti: null,
            otherProfession: '',
            accountData: {
                first_name: '',
                last_name: '',
                tcno: '',
                email: '',
                phone: '',
                business_type: '',
                tax_no: '',
                tax_office: '',
                profession: '',
                account_type: ''
            },
            formattedPhoneNumber: "",
            isPhoneValid: true,
            phoneValidText: this.$t('errors.validation.phoneValidText[0]'),
        };
    },
    mounted() {
        var input = document.querySelector(this.phoneInputID);
        this.iti = window.intlTelInput(input, {
            autoPlaceholder: "polite",
            customPlaceholder: function(placeholder) {
                if (placeholder.charAt(0) === '0') {
                return placeholder.slice(1);
                } else {
                return placeholder;
                }
            },
            preferredCountries: ["tr"],
            initialCountry: "auto",
            geoIpLookup: callback => {
                fetch("https://ipapi.co/json")
                    .then(res => res.json())
                    .then(data => callback(data.country_code))
                    .catch(() => callback("tr"));
            },
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.1.5/js/utils.js"
        });

        $('input[type=radio][name=flexRadioDefault]').change(function () {
            var verginoTxtBox = $('#verginoTxtBox');
            var vergidairesiTxtBox = $('#vergidairesiTxtBox');
            verginoTxtBox.css('animation-duration', '0.5s');
            vergidairesiTxtBox.css('animation-duration', '0.5s');
            if (this.id == 'personalAccount') {
                verginoTxtBox.removeClass('animate__animated animate__fadeIn');
                vergidairesiTxtBox.removeClass('animate__animated animate__fadeIn');
                verginoTxtBox.addClass('animate__animated animate__fadeOut');
                vergidairesiTxtBox.addClass('animate__animated animate__fadeOut');
            } else if (this.id == 'corporateAccount') {
                verginoTxtBox.css('display', 'block');
                vergidairesiTxtBox.css('display', 'block');
                verginoTxtBox.removeClass('animate__animated animate__fadeOut');
                vergidairesiTxtBox.removeClass('animate__animated animate__fadeOut');
                verginoTxtBox.addClass('animate__animated animate__fadeIn');
                vergidairesiTxtBox.addClass('animate__animated animate__fadeIn');
            }
        });
    },
    async created() {
        const { success, message, data } = await getAccount(this.token);
        if (success) {
            this.accountData = data;

            var professions = this.$tm('accountPages.settings.account.form.profession').map(item => item.value);
            // console.log(professions);
            if (!professions.includes(this.accountData.business_type)) {
                this.otherProfession = this.accountData.business_type;
                this.accountData.business_type = 'other';
            }

            const telInput = this.iti;
            
            if (data.phone != null && data.phone != "") {
                try {
                    const incomingPhoneNumber = "+" + data.phone;
                    const phoneNumber = parsePhoneNumber(incomingPhoneNumber);
                    telInput.setCountry(phoneNumber.country);
                    this.accountData.phone = phoneNumber.nationalNumber;
                    telInput.setNumber(phoneNumber.nationalNumber);
                } catch (e) {
                    this.accountData.phone = "+" + data.phone;
                }
                
            }

            if (this.accountData.account_type === 1 && this.accountData.tax_no === 0) {
                this.accountData.tax_no = null;
            }

            var verginoTxtBox = $('#verginoTxtBox');
            var vergidairesiTxtBox = $('#vergidairesiTxtBox');
            verginoTxtBox.css('animation-duration', '0.5s');
            vergidairesiTxtBox.css('animation-duration', '0.5s');
            if (this.accountData.account_type == 1) {
                verginoTxtBox.removeClass('animate__animated animate__fadeIn');
                vergidairesiTxtBox.removeClass('animate__animated animate__fadeIn');
                verginoTxtBox.addClass('animate__animated animate__fadeOut');
                vergidairesiTxtBox.addClass('animate__animated animate__fadeOut');
            } else if (this.accountData.account_type == 2) {
                verginoTxtBox.css('display', 'block');
                vergidairesiTxtBox.css('display', 'block');
                verginoTxtBox.removeClass('animate__animated animate__fadeOut');
                vergidairesiTxtBox.removeClass('animate__animated animate__fadeOut');
                verginoTxtBox.addClass('animate__animated animate__fadeIn');
                vergidairesiTxtBox.addClass('animate__animated animate__fadeIn');
            }

        } else {
            alert("#alertbox", message, "danger", "mt-2");
        }
    },
    methods: {
        validatePhone() {
            if (this.accountData.phone != null && this.accountData.phone != '') {
                if (this.iti.isValidNumber()) {
                this.isPhoneValid = true;
                this.phoneValidText = "";
                } else {
                const errorCode = this.iti.getValidationError();
                if (errorCode == 1) {
                    this.phoneValidText = this.$t('errors.validation.phoneValidText[1]');
                } else if (errorCode == 2) {
                    this.phoneValidText = this.$t('errors.validation.phoneValidText[2]');
                } else if (errorCode == 3) {
                    this.phoneValidText = this.$t('errors.validation.phoneValidText[3]');
                } else {
                    this.phoneValidText = this.$t('errors.validation.phoneValidText[0]');
                }
                this.isPhoneValid = false;
                }
            } else {
                this.isPhoneValid = null;
                this.phoneValidText = "";
            }
        },
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        async updateAccountInfos() {
            if (this.isPhoneValid != false) {
                var business_type = this.accountData.business_type === 'other' ? this.otherProfession : this.accountData.business_type;
                if (this.accountData.account_type == '1') {
                    this.accountData.tax_no = '';
                    this.accountData.tax_office = '';
                }

                if (this.accountData.phone.length > 0) {
                    if (this.accountData.phone.charAt(0) === '0') {
                        this.accountData.phone = this.accountData.phone.slice(1);
                    }

                    var selectedCountryData = this.iti.getSelectedCountryData();
                    var countryDialCode = selectedCountryData.dialCode;
                    this.formattedPhoneNumber = `${countryDialCode}${this.accountData.phone}`;
                    this.formattedPhoneNumber = this.formattedPhoneNumber.replace(/\s/g, '');
                }

                const { success, message } = await updateAccount(this.token, this.accountData.first_name, this.accountData.last_name, this.accountData.tcno.toString(), this.accountData.email, this.formattedPhoneNumber, business_type, parseInt(this.accountData.tax_no), this.accountData.tax_office, this.accountData.profession, parseInt(this.accountData.account_type));
                if (success) {
                    this.$store.dispatch('changeUsername', { username: success ? (this.accountData.first_name && this.accountData.last_name ? `${this.accountData.first_name} ${this.accountData.last_name}` : this.accountData.first_name || this.accountData.user_name) : '' });
                    alert("#alertbox", message, "success", "mt-2");
                } else {
                    alert("#alertbox", message, "danger", "mt-2");
                }
            } else {
                this.$refs.phone.focus();
                if (this.phoneValidText != null && this.phoneValidText != '') {
                    alert("#alertbox", this.phoneValidText, "danger", "mt-2");
                }
            }
        }
    }
};
</script>

<style scoped src="../assets/css/account.css"/>
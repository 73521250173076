import { createStore } from 'vuex'

export default new createStore({
  state: {
    userToken: window.localStorage.getItem('token'),
    userId: window.localStorage.getItem('userId'),
    zid: window.localStorage.getItem('zid'),
    expirationDate: window.localStorage.getItem('expirationDate'),
    userName: '',
    cartItem: [],
    price: {
      currency: "",
      currencyType: "USD",
      price: {
        tax: 0,
        withoutTax: 0,
      },
      string: {
        tax: "-",
        withoutTax: "-",
      }
    }
  },
  mutations: {
    setToken(state, token) {
      state.userToken = token;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setZid(state, zid) {
      state.zid = zid;
    },
    setExpirationDate(state, expirationdate) {
      state.expirationDate = expirationdate;
    },
    setUserName(state, username) {
      state.userName = username;
    },
    setCartItem(state, cart) {
      state.cartItem = cart;
    },
    setPrice(state, price) {
      state.price.price.withoutTax = price.price.withoutTax;
      state.price.price.tax = price.price.tax;
      state.price.string.withoutTax = price.string.withoutTax;
      state.price.string.tax = price.string.tax;
    },
    setCurrency(state, currency) {
      state.price.currency = currency;
    },
    setCurrencyType(state, currencyType) {
      state.price.currencyType = currencyType;
    },
    setCartItemQuantity(state, quantity) {
      state.cartItem[0].cartQuantity = quantity;
    },
    setCartItemPayorderID(state, payorder_id) {
      state.cartItem[0].payorder_id = payorder_id;
    },
  },
  actions: {
    login({ commit }, { token, userId, username, expirationdate }) {
      commit('setToken', token);
      commit('setUserId', userId);
      commit('setUserName', username);
      commit('setExpirationDate', expirationdate);
      // commit('setCartQuantity', quantity);
    },
    logout({ commit }) {
      commit('setToken', null);
      commit('setUserId', null);
      commit('setExpirationDate', null);
      commit('setUserName', null);
    },
    changeCurrency({ commit }, { currency }) {
      commit('setCurrency', currency);
    },
    changeCurrencyType({ commit }, { currencyType }) {
      commit('setCurrencyType', currencyType);
    },
    changePrice({ commit }, { price }) {
      commit('setPrice', price);
    },
    changeUsername({ commit }, { username }) {
      commit('setUserName', username);
    },
    changeZid({ commit }, { zid }) {
      commit('setZid', zid);
    },
    changeCartItem({ commit }, { cart }) {
      commit('setCartItem', cart);
    },
    changeCartItemQuantity({ commit }, { quantity }) {
      commit('setCartItemQuantity', quantity);
    },
    changeCartItemPayorderID({ commit }, { payorder_id }) {
      commit('setCartItemPayorderID', payorder_id);
    }
  },
  getters: {
    isAuthenticated: state => {
      return state.userToken !== null && state.userId !== null;
    },
    cartQuantity: state => {
      if (state.cartItem) {
        if (state.cartItem.length > 0) {
          if (state.cartItem[0].cartQuantity > 0) {
            return state.cartItem[0].cartQuantity;
          }
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    cartPayorderID: state => {
      if (state.cartItem) {
        if (state.cartItem.length > 0) {
          if (state.cartItem[0].payorder_id != null || state.cartItem[0].payorder_id != "") {
            return state.cartItem[0].payorder_id;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    cartItemCount: state => {
      if (state.cartItem) {
        if (state.cartItem.length > 0) {
          if (state.cartItem[0].cartQuantity > 0) {
            return state.cartItem.length;
          } else {
            return 0;
          }
        } else if (state.cartItem.length <= 0) {
          return 0;
        }
      } else {
        return 0;
      }
    },
  },
});

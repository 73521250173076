<template>
<div>
    <div class="container">
        <main class="notFound-section w-100 my-5 m-auto">
                <h1 class="display-1 not-found fw-bold d-flex justify-content-center py-4 mx-auto rounded-1 shadow bg-dark text-zerduz w-50" style="letter-spacing: 10px;">{{ $t("notFoundPage.404") }}</h1>
                <h2 class="text-center mt-4 fw-semibold">{{ $t("notFoundPage.title") }}</h2>
                <div class="hstack gap-3 d-flex justify-content-center mt-4">
                    <a v-if="hasPreviousPage" @click="goBack" class="btn btn-dark">{{ $t("notFoundPage.goBackBtn") }}</a>
                    <div class="vr" v-if="hasPreviousPage"></div>
                    <router-link to="/" class="btn btn-primary">{{ $t("notFoundPage.goHomePageBtn") }}</router-link>
                </div>
        </main>
    </div>
</div>
</template>

<script>
export default {
    name: "NotFound",
    data() {
        return {
            prevRoute: null
        };
    },
    computed: {
        hasPreviousPage() {
            if (window.history.length > 2) {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        goBack() {
            window.history.go(-1);
        }
    }
};
</script>

<style scoped src="../assets/css/404.css"/>

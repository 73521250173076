<template>
<div>
    <div class="px-4 my-5 text-center">
        <h1 class="text-center mt-5 p-1" id="page-header-text">Sıkça Sorulan Sorular</h1>
    </div>

    <div class="container py-3">
        <div class="row">
            <div class="d-sm-grid d-md-grid d-lg-flex align-items-start justify-content-center">
                <div class="col-lg-2 mb-2">
                    <div class="nav flex-column nav-pills me-lg-3 gap-2" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <router-link :to="section.id" :class="index == 0 ? 'active' : ''" active-class="" class="nav-link flex-sm-fill text-zerduz text-sm-center shadow-sm rounded-3 p-3" :id="section.id + '-tab'" data-bs-toggle="pill" :data-bs-target="'#' + section.id" type="button" role="tab" :aria-controls="section.id" v-for="(section, index) in $tm('faqPage.sections')" :key="index">{{ section.name }}</router-link>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show" :class="index == 0 ? 'active' : ''" v-for="(section, index) in $tm('faqPage.sections')" :key="index" :id="section.id" role="tabpanel" :aria-labelledby="section.id + '-tab'" tabindex="0">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item shadow-sm border-0 border-bottom" v-for="(item, index) in section.questions" :key="index">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#' + item.id" aria-expanded="false" :aria-controls="item.id">{{ item.q }}</button>
                                    </h2>
                                    <div :id="item.id" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p v-html="item.a"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="mb-xl-9 mt-5">
            <div class="container">
                <div class="row row">
                    <div class="col-md-4">
                        <div class="text-center p-5 px-xl-8 px-md-0">
                            <div class="icon-lg icon-shape rounded-circle box-glow-zerduz mb-4">
                                <i class="fa-solid fa-location-dot"></i>
                            </div>

                            <h5 class="fw-normal">{{ $t('faqPage.details[0]') }}</h5>
                            <p class="mb-0"><a href="https://maps.app.goo.gl/fSyKmY3N6XEkNMUm8" class="text-decoration-none text-body" target="_blank">Yenibosna Merkez Mah. Ladin Sok. Town Ofis No:36 İç Kapı No:11
                                Bahçelievler/İstanbul Türkiye</a></p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="text-center p-5 px-xl-8 px-md-0">
                            <div class="icon-lg icon-shape rounded-circle box-glow-zerduz mb-4">
                                <i class="fa-solid fa-phone"></i>
                            </div>

                            <h5 class="fw-normal">{{ $t('faqPage.details[1]') }}</h5>
                            <p class="mb-0"><a href="tel:+908503406666" class="text-decoration-none text-body">+90 850 340
                                    6666</a></p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="text-center p-5 px-xl-8 px-md-0">
                            <div class="icon-lg icon-shape rounded-circle box-glow-zerduz mb-4">
                                <i class="fa-solid fa-envelope"></i>
                            </div>

                            <h5 class="fw-normal">{{ $t('faqPage.details[2]') }}</h5>
                            <p class="mb-0"><a href="mailto:info@zerduz.com" class="text-decoration-none text-body">info@zerduz.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
</template>

<script>

export default {
    name: "SSS",
};
</script>

<style scoped src="../assets/css/sss.css"/>

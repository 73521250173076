<template>
<div>
    <div class="px-4 my-5 text-center">
        <h1 class="text-center mt-5 p-1" id="page-header-text">{{ $t('appsPage.header') }}</h1>
        <div class="col-lg-6 mx-auto">
            <p class="lead mb-5">{{ $t('appsPage.description') }}</p>
        </div>
    </div>

    <div class="container py-3">
        <div class="row row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 g-5 d-flex justify-content-center">
            <div class="col" v-for="(app, index) in $tm('appsPage.apps')" :key="index">
                <div class="card mb-3 border-0" style="max-width: 540px;">
                    <div class="row g-0 align-items-center align-items-center">
                        <div class="col-md-6">
                            <img :src="getAppImage(app.img)" class="img-fluid rounded-4 mx-auto d-block shadow-sm" :alt="app.title">
                        </div>
                        <div class="col-md-6">
                            <div class="card-body">
                                <h5 class="card-title">{{ app.title }}</h5>
                                <p class="card-text"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    getApps
} from "@/helpers/api";

export default {
    name: "AppsPage",
    data() {
        return {
            apps: [],
        };
    },
    methods: {
        getFullImagePath(image) {
            const imagePath = `https://backend.zerduz.com/apps/${image}`;
            return imagePath;
        },
        getAppImage(app) {
            var images = require.context('../assets/img/', false, /\.png$/)
            return images('./' + app)
        },
    },
    async created() {
        const {
            success,
            apps
        } = await getApps();
        if (success) {
            this.apps = apps;
        }
    }
};
</script>

<style scoped src="../assets/css/apps.css"/>

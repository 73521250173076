<template>
    <div>
        <div class="px-4 my-5 text-center">
            <h1 class="text-center mt-5 p-1" id="page-header-text">{{ $t('accountPages.header') }}</h1>
        </div>

        <div class="container py-3">
            <div class="row">
                <accountPageSidemenu />
                <div class="col-lg-9 col-md-8 col-sm-12" id="account-settings-tab">
                    <div class="border my-2 rounded-2 shadow-sm">
                        <div class="container">
                            <div class="row p-3">
                                <ul class="nav nav-pills nav-fill gap-2 p-1 small bg-primary rounded-5 shadow-sm" id="settingsNav" role="tablist" style="--bs-nav-link-color: var(--bs-white); --bs-nav-pills-link-active-color: var(--bs-primary); --bs-nav-pills-link-active-bg: var(--bs-white);">
                                    <div class="col">
                                        <li class="nav-item" role="presentation">
                                            <router-link to="/Account/Settings" active-class="" class="nav-link text-white text-opacity-50 rounded-5" id="info-tab" type="button">{{ $t('accountPages.settings.menu[0].title') }}</router-link>
                                        </li>
                                    </div>
                                    <div class="col">
                                        <li class="nav-item" role="presentation">
                                            <router-link to="/Account/Settings/Password" active-class="" class="nav-link active rounded-5" id="password-tab" type="button">{{ $t('accountPages.settings.menu[1].title') }}</router-link>
                                        </li>
                                    </div>
                                    <div class="col">
                                        <li class="nav-item" role="presentation">
                                            <router-link to="/Account/Settings/Company" active-class="" class="nav-link text-white text-opacity-50 rounded-5" id="company-tab" type="button">{{ $t('accountPages.settings.menu[2].title') }}</router-link>
                                        </li>
                                    </div>
                                </ul>     
                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active g-2 my-2 mt-4" id="pills-change-password" role="tabpanel"
                                        aria-labelledby="pills-password-tab" tabindex="0">
                                        <form class="col-sm-12 col-md-8 col-lg-6 mx-auto" @submit.prevent="changePassword">
                                            <div class="form-floating mb-3">
                                                <input type="password" class="form-control" v-model="formData.oldPassword"
                                                    id="pw-current" :placeholder="$t('accountPages.settings.changePassword.form.currentPassword')" required>
                                                <label for="pw-current">{{ $t('accountPages.settings.changePassword.form.currentPassword') }}</label>
                                            </div>
                                            <div class="form-floating mb-3">
                                                <input :type="showPassword ? 'text' : 'password'" class="form-control" v-model="formData.newPassword"
                                                    id="pw-new" :placeholder="$t('accountPages.settings.changePassword.form.currentPassword')" @input="validatePassword" required>
                                                <span @click="togglePassword" toggle="#password-field" class="fa fa-fw field-icon toggle-password base-password text-zerduz" :class="{ 'fa-eye': !showPassword, 'fa-eye-slash active': showPassword }"></span>
                                                <label for="pw-new">{{ $t('accountPages.settings.changePassword.form.newPassword') }}</label>
                                                <p v-if="isPasswordValid == false" class="mt-2 fw-normal text-danger">{{ $t('errors.validation.passwordValidText') }}</p>
                                            </div>
                                            <div class="form-floating mb-3">
                                                <input :type="showPasswordConfirmation ? 'text' : 'password'" class="form-control"
                                                    v-model="formData.confirmPassword" id="pw-confirm"
                                                    placeholder="$t('accountPages.settings.changePassword.form.newPasswordConfirmation')" @input="validatePasswordConfirmation" required>
                                                <span @click="togglePasswordConfirmation" toggle="#password-field" class="fa fa-fw field-icon toggle-password base-password text-zerduz" :class="{ 'fa-eye': !showPasswordConfirmation, 'fa-eye-slash active': showPasswordConfirmation }"></span>
                                                <label for="pw-confirm">{{ $t('accountPages.settings.changePassword.form.newPasswordConfirmation') }}</label>
                                                <p v-if="isPasswordConfirmationValid == false" class="mt-2 fw-normal text-danger">{{ $t('errors.validation.passwordValidText') }}</p>
                                            </div>
                                            <div class="row">
                                                <div id="alertbox"></div>
                                                <div class="col-12 d-grid gap-2 mb-3">
                                                    <button type="submit"
                                                        class="btn btn-primary btn-block mt-1 w-75 mx-auto">{{ $t('accountPages.settings.changePassword.form.btn') }}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { updatePassword } from "@/helpers/api";
import { alert } from "@/helpers/funcs";
import accountPageSidemenu from "@/components/accountPageSidemenu";

export default {
    name: "AccountSettingsPassword",
    components: {
        accountPageSidemenu
    },
    data() {
        return {
            token: window.localStorage.getItem('token'),
            userId: window.localStorage.getItem('userId'),
            formData: {
                oldPassword: '',
                newPassword: '',
                confirmPassword: ''
            },
            isPasswordConfirmationValid: null,
            isPasswordValid: null,
            showPassword: false,
            showPasswordConfirmation: false,
        };
    },
    methods: {
        togglePasswordConfirmation() {
        this.showPasswordConfirmation = !this.showPasswordConfirmation;
        },
        togglePassword() {
        this.showPassword = !this.showPassword;
        },
        validatePassword() {
        const regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
        this.isPasswordValid = regex.test(this.formData.newPassword);
        },
        validatePasswordConfirmation() {
        const regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
        this.isPasswordConfirmationValid = regex.test(this.formData.confirmPassword);
        },
        async changePassword() {
            if (this.formData.oldPassword == null || this.formData.oldPassword == "") {
                alert("#alertbox", this.$t('errors.validation.currentPasswordCannotBeEmpty'), "danger", "mt-2");
            } else {
                if (this.isPasswordValid && this.isPasswordConfirmationValid) {
                    if (this.formData.newPassword === this.formData.confirmPassword) {
                        const { success, message } = await updatePassword(this.token, this.formData.oldPassword, this.formData.newPassword);

                        if (success) {
                            alert("#alertbox", message, "success", "mt-3");
                        } else {
                            alert("#alertbox", message, "danger", "mt-2");
                        }
                    } else {
                        alert("#alertbox", this.$t('errors.validation.newPasswordsDoesntMatch'), "danger", "mt-2");
                    }
                }
            }
            
        }
    }
};
</script>

<style scoped src="../assets/css/account.css"/>